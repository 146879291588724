import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { useInventoryStore } from '../../store/useInventoryStore';
import { InventoryItem, PurchasePattern, InventoryCategory } from '../../types/inventory';
import toast from 'react-hot-toast';

interface InventoryItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  item?: InventoryItem | null;
  outletId: string;
  categories: InventoryCategory[];
}

export function InventoryItemModal({ isOpen, onClose, item, outletId, categories }: InventoryItemModalProps) {
  const { addItem, updateItem } = useInventoryStore();
  const [formData, setFormData] = useState({
    name: item?.name || '',
    description: item?.description || '',
    categoryId: item?.categoryId || (categories.length > 0 ? categories[0].id : ''),
    unit: item?.unit || '',
    minimumStock: item?.minimumStock || 0,
    purchasePattern: {
      frequency: item?.purchasePattern?.frequency || 'daily',
      days: item?.purchasePattern?.days || [1],
      averageQuantity: item?.purchasePattern?.averageQuantity || 0,
      lastPurchaseDate: item?.purchasePattern?.lastPurchaseDate,
      nextPurchaseDate: item?.purchasePattern?.nextPurchaseDate
    }
  });

  useEffect(() => {
    if (item) {
      setFormData({
        name: item.name,
        description: item.description || '',
        categoryId: item.categoryId,
        unit: item.unit,
        minimumStock: item.minimumStock,
        purchasePattern: {
          frequency: item.purchasePattern?.frequency || 'daily',
          days: item.purchasePattern?.days || [1],
          averageQuantity: item.purchasePattern?.averageQuantity || 0,
          lastPurchaseDate: item.purchasePattern?.lastPurchaseDate,
          nextPurchaseDate: item.purchasePattern?.nextPurchaseDate
        }
      });
    }
  }, [item, categories]);

  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const monthDays = Array.from({ length: 31 }, (_, i) => i + 1);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const itemData = {
        ...formData,
        outletId,
        minimumStock: Number(formData.minimumStock),
        isActive: true,
      };

      if (item) {
        await updateItem(item.id, itemData);
      } else {
        await addItem(itemData);
      }
      onClose();
    } catch (error) {
      console.error('Error saving inventory item:', error);
      toast.error('Failed to save inventory item');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {item ? 'Edit Item' : 'Add Item'}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Category</label>
            <select
              value={formData.categoryId}
              onChange={(e) => setFormData({ ...formData, categoryId: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Unit</label>
            <input
              type="text"
              value={formData.unit}
              onChange={(e) => setFormData({ ...formData, unit: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
              placeholder="e.g., kg, pcs, litres"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Minimum Stock</label>
            <input
              type="number"
              value={formData.minimumStock}
              onChange={(e) => setFormData({ ...formData, minimumStock: Number(e.target.value) })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              min="0"
              step="1"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Purchase Pattern</label>
            <select
              value={formData.purchasePattern.frequency}
              onChange={(e) => setFormData({
                ...formData,
                purchasePattern: {
                  ...formData.purchasePattern,
                  frequency: e.target.value as PurchasePattern['frequency'],
                  days: []
                }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="custom">Custom</option>
            </select>
          </div>

          {(formData.purchasePattern.frequency === 'weekly' || formData.purchasePattern.frequency === 'monthly') && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {formData.purchasePattern.frequency === 'weekly' ? 'Select Days' : 'Purchase Day'}
              </label>
              {formData.purchasePattern.frequency === 'weekly' ? (
                <div className="mt-2 grid grid-cols-4 gap-2">
                  {weekDays.map((day, index) => (
                    <label key={index} className="inline-flex items-center">
                      <input
                        type="checkbox"
                        checked={formData.purchasePattern.days?.includes(index)}
                        onChange={(e) => {
                          const days = formData.purchasePattern.days || [];
                          setFormData({
                            ...formData,
                            purchasePattern: {
                              ...formData.purchasePattern,
                              days: e.target.checked
                                ? [...days, index]
                                : days.filter(d => d !== index)
                            }
                          });
                        }}
                        className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                      <span className="ml-2 text-sm text-gray-600">{day}</span>
                    </label>
                  ))}
                </div>
              ) : (
                <select
                  value={formData.purchasePattern.days?.[0] || 1}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      purchasePattern: {
                        ...formData.purchasePattern,
                        days: [parseInt(e.target.value)]
                      }
                    });
                  }}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  {monthDays.map(day => (
                    <option key={day} value={day}>
                      {day}{day === 1 ? 'st' : day === 2 ? 'nd' : day === 3 ? 'rd' : 'th'} of every month
                    </option>
                  ))}
                </select>
              )}
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">Average Purchase Quantity</label>
            <input
              type="number"
              value={formData.purchasePattern.averageQuantity}
              onChange={(e) => setFormData({
                ...formData,
                purchasePattern: {
                  ...formData.purchasePattern,
                  averageQuantity: Number(e.target.value)
                }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              min="0"
              step="1"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              {item ? 'Update' : 'Add'} Item
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}