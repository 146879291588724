import { createClient } from '@supabase/supabase-js';
import { Database } from '../types/supabase';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase = createClient<Database>(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true, 
    storage: window.localStorage
  }
});

// Initialize Supabase and set up real-time subscriptions
export async function initializeSupabase() {
  try {
    // Subscribe to auth state changes
    supabase.auth.onAuthStateChange((event, session) => {
      console.log('Auth state changed:', event, session);
    });

    // Subscribe to menu items changes
    const menuSubscription = supabase
      .channel('menu_changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'menu_items' },
        (payload) => {
          console.log('Menu change received:', payload);
        }
      )
      .subscribe();

    // Subscribe to orders changes
    const orderSubscription = supabase
      .channel('order_changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'orders' },
        (payload) => {
          console.log('Order change received:', payload);
        }
      )
      .subscribe();

    return { menuSubscription, orderSubscription };
  } catch (error) {
    console.error('Error initializing Supabase:', error);
    return null;
  }
}