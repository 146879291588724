import React, { useState, useEffect } from 'react';
import { useMenuStore } from '../store/useMenuStore';
import { useOutletStore } from '../store/useOutletStore';
import { Plus, Edit2, Trash2, AlertCircle } from 'lucide-react';
import { Category } from '../lib/api/categories';
import toast from 'react-hot-toast';

export function CategoryManagement() {
  const { categories, addCategory, updateCategory, deleteCategory, fetchCategories } = useMenuStore();
  const { currentOutlet } = useOutletStore();
  const [newCategory, setNewCategory] = useState('');
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);

  useEffect(() => {
    if (currentOutlet) {
      fetchCategories(currentOutlet);
    }
  }, [currentOutlet, fetchCategories]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentOutlet) {
      toast.error('Please select an outlet first');
      return;
    }

    if (newCategory.trim()) {
      try {
        await addCategory(currentOutlet, newCategory.trim());
        setNewCategory('');
      } catch (error) {
        console.error('Error adding category:', error);
      }
    }
  };

  const handleUpdate = async (category: Category, newName: string) => {
    if (newName.trim() && newName !== category.name) {
      try {
        await updateCategory(category.id, { name: newName.trim() });
      } catch (error) {
        console.error('Error updating category:', error);
      }
    }
    setEditingCategory(null);
  };

  const handleDelete = async (category: Category) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        await deleteCategory(category.id);
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    }
  };

  if (!currentOutlet) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <AlertCircle className="h-16 w-16 text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">No Outlet Selected</h2>
        <p className="text-gray-600 mb-4">Please select an outlet to manage categories</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Category Management</h1>
      </div>

      <div className="bg-white shadow sm:rounded-lg p-6">
        <form onSubmit={handleSubmit} className="flex gap-4 mb-6">
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="New category name"
            className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-5 w-5 mr-2" />
            Add Category
          </button>
        </form>

        <div className="mt-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Categories</h2>
          <ul className="divide-y divide-gray-200">
            {categories.map((category) => (
              <li
                key={category.id}
                className="py-4 flex items-center justify-between"
              >
                {editingCategory?.id === category.id ? (
                  <input
                    type="text"
                    value={editingCategory.name}
                    onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
                    onBlur={() => handleUpdate(category, editingCategory.name)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleUpdate(category, editingCategory.name);
                      }
                    }}
                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    autoFocus
                  />
                ) : (
                  <span className="text-gray-900">{category.name}</span>
                )}
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => setEditingCategory(category)}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    <Edit2 className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleDelete(category)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}