import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';

const slides = [
  {
    image: "https://images.unsplash.com/photo-1633945274405-b6c8069047b0?auto=format&fit=crop&w=1600",
    title: "Ambur Biryani",
    description: "Fragrant rice cooked with tender meat and aromatic spices"
  },
  {
    image: "https://images.unsplash.com/photo-1689179759819-9394a91d6ffb?auto=format&fit=crop&w=1600",
    title: "Chicken 65",
    description: "Spicy and crispy deep-fried chicken, a South Indian classic"
  },
  {
    image: "https://images.unsplash.com/photo-1631452180519-c014fe946bc7?auto=format&fit=crop&w=1600",
    title: "Prawn Curry",
    description: "Succulent prawns in a rich, spicy coconut gravy"
  },
  {
    image: "https://images.unsplash.com/photo-1601050690597-df0568f70950?auto=format&fit=crop&w=1600",
    title: "Traditional Meals",
    description: "Complete South Indian thali with variety of dishes"
  }
];

export function HeroSlider() {
  return (
    <div className="relative h-[500px] rounded-3xl overflow-hidden">
      <Swiper
        modules={[Autoplay, EffectFade]}
        effect="fade"
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        className="h-full w-full"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="relative h-full">
              <img
                src={slide.image}
                alt={slide.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent" />
              <div className="absolute bottom-8 left-8 text-white">
                <h3 className="text-2xl font-bold mb-2">{slide.title}</h3>
                <p className="text-sm text-gray-200">{slide.description}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}