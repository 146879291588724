import { supabase } from '../supabase-client';
import { Restaurant } from '../../types';
import toast from 'react-hot-toast';

const DEFAULT_RESTAURANT_ID = '00000000-0000-0000-0000-000000000000';

export async function fetchRestaurant() {
  try {
    // Add retry logic
    let retries = 3;
    let lastError;

    while (retries > 0) {
      try {
        const { data: existingRestaurant, error: checkError } = await supabase
          .from('restaurants')
          .select('*')
          .single();

        if (checkError && checkError.code === 'PGRST116') {
          // Restaurant doesn't exist, create default one
          const defaultRestaurant = {
            id: DEFAULT_RESTAURANT_ID,
            name: 'KLICK POS Restaurant',
            description: 'A modern dining experience',
            address: '123 Main Street',
            phone: '+1234567890',
            email: 'contact@klickpos.com',
            gst_number: 'GST123456789',
            currency: 'INR',
            timezone: 'Asia/Kolkata',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          };

          const { data: newRestaurant, error: createError } = await supabase
            .from('restaurants')
            .insert([defaultRestaurant])
            .select()
            .single();

          if (createError) throw createError;
          return transformRestaurant(newRestaurant);
        }

        if (checkError) throw checkError;
        if (!existingRestaurant) throw new Error('No restaurant data found');
        return transformRestaurant(existingRestaurant);
      } catch (err) {
        lastError = err;
        retries--;
        if (retries > 0) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
    }
    throw lastError;
  } catch (error) {
    console.error('Error fetching restaurant:', error);
    toast.error('Failed to fetch restaurant details');
    throw error;
  }
}

export async function updateRestaurant(updates: Partial<Restaurant>) {
  try {
    const { data, error } = await supabase
      .from('restaurants')
      .update({
        name: updates.name,
        description: updates.description,
        address: updates.address,
        phone: updates.phone,
        email: updates.email,
        gst_number: updates.gstNumber,
        logo: updates.logo,
        currency: updates.currency || 'INR',
        timezone: updates.timeZone || 'Asia/Kolkata',
        updated_at: new Date().toISOString()
      })
      .eq('id', DEFAULT_RESTAURANT_ID)
      .select()
      .single();

    if (error) throw error;
    return transformRestaurant(data);
  } catch (error) {
    console.error('Error updating restaurant:', error);
    toast.error('Failed to update restaurant details');
    throw error;
  }
}

function transformRestaurant(data: any): Restaurant {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    address: data.address,
    phone: data.phone,
    email: data.email,
    gstNumber: data.gst_number,
    logo: data.logo,
    currency: data.currency,
    timeZone: data.timezone
  };
}