import React, { useState, useEffect } from 'react';
import { useMenuStore } from '../store/useMenuStore';
import { useOutletStore } from '../store/useOutletStore';
import { MenuItemModal } from '../components/management/MenuItemModal';
import { MenuList } from '../components/management/MenuList';
import { MenuItem } from '../types';
import { Plus, ListFilter, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

export function MenuManagement() {
  const { items, categories, fetchItems, fetchCategories, addItem, updateItem, deleteItem } = useMenuStore();
  const { currentOutlet } = useOutletStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState<MenuItem | undefined>();

  useEffect(() => {
    if (currentOutlet) {
      fetchItems(currentOutlet);
      fetchCategories(currentOutlet);
    }
  }, [currentOutlet]);

  // Filter items by current outlet
  const outletItems = items.filter(item => item.outletId === currentOutlet);

  const handleSubmit = async (data: Omit<MenuItem, 'id'>) => {
    if (!currentOutlet) {
      toast.error('Please select an outlet first');
      return;
    }

    try {
      if (editingItem?.id) {
        await updateItem(editingItem.id, {
          ...data,
          outletId: currentOutlet,
          availability: data.availability || { pos: true, online: true },
          allergens: data.allergens || [],
          dietary: data.dietary || [],
          modifiers: data.modifiers || []
        });
        toast.success('Menu item updated successfully');
      } else {
        await addItem({
          ...data,
          outletId: currentOutlet,
          availability: { pos: true, online: true },
          allergens: [],
          dietary: [],
          modifiers: []
        });
        toast.success('Menu item added successfully');
      }
      setIsModalOpen(false);
      setEditingItem(undefined);
    } catch (error) {
      console.error('Error saving menu item:', error);
      toast.error('Failed to save menu item');
    }
  };

  const handleEdit = (item: MenuItem) => {
    setEditingItem(item);
    setIsModalOpen(true);
  };

  const handleDelete = async (id: string) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this item? This action cannot be undone.'
    );
    
    if (confirmed) {
      try {
        await deleteItem(id);
        toast.success('Menu item deleted successfully');
      } catch (error) {
        console.error('Error deleting menu item:', error);
        toast.error('Failed to delete menu item');
      }
    }
  }

  if (!currentOutlet) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <AlertCircle className="h-16 w-16 text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">No Outlet Selected</h2>
        <p className="text-gray-600 mb-4">Please select an outlet to manage menu items</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Menu Management</h1>
        <div className="flex space-x-4">
          <Link
            to="/management/categories"
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <ListFilter className="h-5 w-5 mr-2" />
            Manage Categories
          </Link>
          <button
            onClick={() => {
              if (!currentOutlet) {
                toast.error('Please select an outlet first');
                return;
              }
              if (categories.length === 0) {
                toast.error('Please add some categories first');
                return;
              }
              setEditingItem(undefined);
              setIsModalOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-5 w-5 mr-2" />
            Add Item
          </button>
        </div>
      </div>

      <MenuList items={outletItems} onEdit={handleEdit} onDelete={handleDelete} />

      <MenuItemModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingItem(undefined);
        }}
        onSubmit={handleSubmit}
        initialData={editingItem}
        categories={categories}
      />
    </div>
  );
}