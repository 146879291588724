import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuthStore } from '../../store/useAuthStore';
import { useRestaurantStore } from '../../store/useRestaurantStore';
import { User, LogOut, UtensilsCrossed } from 'lucide-react';
import { cn } from '../../lib/utils';

export function StoreHeader() {
  const { user, logout } = useAuthStore();
  const { restaurant } = useRestaurantStore();
  const location = useLocation();

  return (
    <header className="bg-white/95 backdrop-blur-sm shadow-sm sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2">
              {restaurant?.logo ? (
                <img 
                  src={restaurant.logo} 
                  alt={restaurant.name} 
                  className="h-12 w-auto object-contain"
                />
              ) : (
                <div className="flex items-center justify-center w-12 h-12 bg-red-600 rounded-full">
                  <UtensilsCrossed className="h-6 w-6 text-white transform -rotate-45" />
                </div>
              )}
            </Link>
          </div>

          <div className="flex items-center space-x-4">
            <Link
              to="/menu"
              className={cn(
                'px-3 py-2 rounded-md text-sm font-medium',
                location.pathname === '/menu'
                  ? 'bg-indigo-100 text-indigo-700'
                  : 'text-gray-700 hover:text-gray-900'
              )}
            >
              Menu
            </Link>

            {user ? (
              <>
                <Link
                  to="/my-orders"
                  className={cn(
                    'px-3 py-2 rounded-md text-sm font-medium',
                    location.pathname === '/my-orders'
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'text-gray-700 hover:text-gray-900'
                  )}
                >
                  My Orders
                </Link>
                <Link
                  to="/profile"
                  className={cn(
                    'p-2 rounded-full',
                    location.pathname === '/profile'
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'text-gray-700 hover:text-gray-900'
                  )}
                >
                  <User className="h-5 w-5" />
                </Link>
                <button
                  onClick={() => logout()}
                  className="p-2 text-gray-700 hover:text-gray-900"
                >
                  <LogOut className="h-5 w-5" />
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900"
                >
                  Sign In
                </Link>
                <Link
                  to="/register"
                  className="px-3 py-2 rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}