import { create } from 'zustand';
import { Expense, ExpenseCategory } from '../types/expense';
import {
  fetchExpenseCategories,
  createExpenseCategory,
  updateExpenseCategory,
  fetchExpenses,
  createExpense,
  updateExpense,
  deleteExpense
} from '../lib/api/expenses';

interface ExpenseStore {
  expenses: Expense[];
  categories: ExpenseCategory[];
  isLoading: boolean;
  error: Error | null;
  fetchCategories: (outletId: string) => Promise<void>;
  addCategory: (outletId: string, name: string, description?: string) => Promise<void>;
  updateCategory: (id: string, updates: { name?: string; description?: string; isActive?: boolean }) => Promise<void>;
  fetchExpenses: (outletId: string, startDate?: Date, endDate?: Date) => Promise<void>;
  addExpense: (expense: Omit<Expense, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy'>) => Promise<void>;
  updateExpense: (id: string, updates: Partial<Omit<Expense, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy'>>) => Promise<void>;
  deleteExpense: (id: string) => Promise<void>;
}

export const useExpenseStore = create<ExpenseStore>((set) => ({
  expenses: [],
  categories: [],
  isLoading: false,
  error: null,

  fetchCategories: async (outletId: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchExpenseCategories(outletId);
      set({ categories: data, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  addCategory: async (outletId: string, name: string, description?: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await createExpenseCategory(outletId, name, description);
      set((state) => ({
        categories: [...state.categories, data],
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  updateCategory: async (id: string, updates: { name?: string; description?: string; isActive?: boolean }) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateExpenseCategory(id, updates);
      set((state) => ({
        categories: state.categories.map((cat) =>
          cat.id === id ? data : cat
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  fetchExpenses: async (outletId: string, startDate?: Date, endDate?: Date) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchExpenses(outletId, startDate, endDate);
      set({ expenses: data, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  addExpense: async (expense) => {
    try {
      set({ isLoading: true, error: null });
      const data = await createExpense(expense);
      set((state) => ({
        expenses: [data, ...state.expenses],
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  updateExpense: async (id, updates) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateExpense(id, updates);
      set((state) => ({
        expenses: state.expenses.map((exp) =>
          exp.id === id ? data : exp
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  deleteExpense: async (id) => {
    try {
      set({ isLoading: true, error: null });
      await deleteExpense(id);
      set((state) => ({
        expenses: state.expenses.filter((exp) => exp.id !== id),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },
}));