import React, { useEffect, useState } from 'react';
import { useMenuStore } from '../../store/useMenuStore';
import { useOutletStore } from '../../store/useOutletStore';
import { useRestaurantStore } from '../../store/useRestaurantStore';
import { useCartStore } from '../../store/useCartStore';
import { UtensilsCrossed, ArrowRight, ShoppingBag } from 'lucide-react';
import { Link } from 'react-router-dom';
import { HeroSlider } from '../../components/store/HeroSlider';
import { formatCurrency } from '../../lib/utils';
import { CartSlideOver } from '../../components/store/CartSlideOver';
import { useAuthStore } from '../../store/useAuthStore';
import toast from 'react-hot-toast';
import { MenuItem } from '../../types';

export function StoreLanding() {
  const { items, fetchItems } = useMenuStore();
  const { currentOutlet } = useOutletStore();
  const { restaurant, fetchRestaurant, isLoading } = useRestaurantStore();
  const { items: cartItems, addItem } = useCartStore();
  const { user } = useAuthStore();
  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isCartOpen, setIsCartOpen] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLocalLoading(true);
        await Promise.all([
          fetchRestaurant(),
          currentOutlet ? fetchItems(currentOutlet) : Promise.resolve()
        ]);
        if (currentOutlet) {
          await fetchItems(currentOutlet);
        }
      } catch (err) {
        console.error('Failed to load data:', err);
        setError(err instanceof Error ? err.message : 'Failed to load data');
      } finally {
        setIsLocalLoading(false);
      }
    };
    loadData();
  }, [currentOutlet, fetchRestaurant, fetchItems]);

  // Get popular menu items
  const popularItems = items
    .filter(item => item.availability.online)
    .sort((a, b) => b.basePrice - a.basePrice)
    .slice(0, 3);

  const handleAddToCart = (item: MenuItem) => {
    addItem(item);
    toast.success(`Added ${item.name} to cart`);
    setIsCartOpen(true);
  };

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#FFF8F0]">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Oops! Something went wrong</h2>
          <p className="text-gray-600">{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (isLoading || isLocalLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#FFF8F0]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#FFF8F0]">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <span className="text-orange-500 font-medium mb-4 block">Authentic Home Style Food</span>
            <h1 className="text-5xl md:text-6xl font-bold text-gray-900 mb-6 leading-tight">
              South Indian
              <span className="text-red-500"> Delicacies</span>
            </h1>
            <p className="text-lg text-gray-600 mb-8">
              Experience the rich flavors of traditional South Indian cuisine with our carefully crafted biryanis and authentic dishes
            </p>
            <div className="flex gap-4">
              <Link
                to="/menu" 
                className="inline-flex items-center px-8 py-3 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
              >
                View Menu
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </div>
            <div className="mt-12 flex items-center gap-4">
              <div className="flex -space-x-2">
                <img src="https://randomuser.me/api/portraits/women/1.jpg" className="w-8 h-8 rounded-full border-2 border-white" alt="Customer" />
                <img src="https://randomuser.me/api/portraits/men/1.jpg" className="w-8 h-8 rounded-full border-2 border-white" alt="Customer" />
                <img src="https://randomuser.me/api/portraits/women/2.jpg" className="w-8 h-8 rounded-full border-2 border-white" alt="Customer" />
              </div>
              <div className="text-sm">
                <p className="text-gray-500 italic">"The authentic taste of South India"</p>
              </div>
            </div>
          </div>
          <div className="relative">
            <HeroSlider />
            <div className="absolute -bottom-6 -right-6 bg-red-500 text-white p-4 rounded-full">
              <UtensilsCrossed className="h-8 w-8" />
            </div>
          </div>
        </div>
      </div>

      {/* Popular Menu Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <h2 className="text-3xl font-bold text-gray-900 mb-12">Our Specialties</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {popularItems.map((item) => (
            <div key={item.id} className="bg-white p-6 rounded-3xl shadow-sm hover:shadow-md transition-shadow">
              <div className="relative h-48 mb-4">
                {item.image ? (
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-full h-full object-cover rounded-2xl"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-100 rounded-2xl flex items-center justify-center">
                    <UtensilsCrossed className="h-12 w-12 text-gray-400" />
                  </div>
                )}
              </div>
              <h3 className="text-xl font-semibold mb-2">{item.name}</h3>
              <p className="text-gray-500 text-sm mb-4 line-clamp-2">{item.description}</p>
              <div className="flex items-center justify-between">
                <span className="text-xl font-bold">{formatCurrency(item.basePrice)}</span>
                <button 
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddToCart(item);
                  }}
                  className="px-4 py-2 bg-red-600 text-white rounded-full hover:bg-red-700 transition-colors flex items-center gap-2"
                >
                  <ShoppingBag className="h-4 w-4" />
                  Add to Cart
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Cart Slide Over */}
      <CartSlideOver
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        cartItems={cartItems}
        cartTotal={useCartStore.getState().getTotal()}
        isLoggedIn={!!user}
      />
      
      {/* Cart Toggle Button */}
      {cartItems.length > 0 && (
        <button
          onClick={() => setIsCartOpen(true)}
          className="fixed bottom-6 right-6 bg-red-600 text-white p-4 rounded-full shadow-lg hover:bg-red-700 transition-colors z-40"
        >
          <div className="relative">
            <ShoppingBag className="h-6 w-6" />
            <span className="absolute -top-2 -right-2 bg-white text-red-600 text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
              {cartItems.reduce((sum, item) => sum + item.quantity, 0)}
            </span>
          </div>
        </button>
      )}
      {/* Footer */}
      <footer className="bg-white border-t">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <div className="flex items-center mb-4">
                {restaurant?.logo ? (
                  <img 
                    src={restaurant.logo} 
                    alt={restaurant.name} 
                    className="h-12 w-auto"
                  />
                ) : (
                  <div className="flex items-center justify-center w-12 h-12 bg-red-500 rounded-full">
                    <UtensilsCrossed className="h-6 w-6 text-white transform -rotate-45" />
                  </div>
                )}
              </div>
              <p className="text-gray-500 text-sm">
                Experience authentic South Indian cuisine with our carefully curated menu of biryanis, kebabs, and traditional delicacies.
              </p>
            </div>

            <div>
              <h3 className="font-semibold mb-4">Main Menu</h3>
              <ul className="space-y-2">
                <li><Link to="/menu" className="text-gray-500 hover:text-gray-900">Menu</Link></li>
                <li><Link to="/register" className="text-gray-500 hover:text-gray-900">Sign Up</Link></li>
                <li><Link to="/login" className="text-gray-500 hover:text-gray-900">Sign In</Link></li>
              </ul>
            </div>

            <div>
              <h3 className="font-semibold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li><Link to="/about" className="text-gray-500 hover:text-gray-900">About Us</Link></li>
                <li><Link to="/contact" className="text-gray-500 hover:text-gray-900">Contact Us</Link></li>
                <li><Link to="/privacy" className="text-gray-500 hover:text-gray-900">Privacy Policy</Link></li>
              </ul>
            </div>

            <div>
              <h3 className="font-semibold mb-4">Contact</h3>
              <address className="text-gray-500 not-italic">
                {restaurant?.address}<br />
                Phone: {restaurant?.phone}<br />
                Email: {restaurant?.email}
              </address>
            </div>
          </div>

          <div className="border-t mt-12 pt-8 text-center text-gray-500">
            <p>© {new Date().getFullYear()} {restaurant?.name || 'South Indian Restaurant'}. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}