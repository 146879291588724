import React from 'react';
import { Order } from '../../types';
import { X, Printer } from 'lucide-react';
import { formatCurrency } from '../../lib/utils';
import { printReceipt } from '../../lib/print';
import { useRestaurantStore } from '../../store/useRestaurantStore';
import { useOutletStore } from '../../store/useOutletStore';
import toast from 'react-hot-toast';

interface OrderCompleteModalProps {
  order: Order;
  onClose: () => void;
}

export function OrderCompleteModal({ order, onClose }: OrderCompleteModalProps) {
  const { restaurant } = useRestaurantStore();
  const { outlets } = useOutletStore();
  const outlet = outlets.find(o => o.id === order.outletId);

  const handlePrint = async () => {
    try {
      await printReceipt(order, restaurant, outlet);
      toast.success('Receipt printed successfully');
    } catch (error) {
      toast.error('Failed to print receipt. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Order Complete</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="text-center mb-6">
          <div className="text-green-500 text-5xl mb-4">✓</div>
          <p className="text-lg text-gray-600">Order #{order.id} has been completed</p>
        </div>

        <div className="space-y-4">
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-medium mb-2">Order Summary</h3>
            <div className="space-y-2 text-sm">
              <div className="flex justify-between">
                <span>Subtotal</span>
                <span>{formatCurrency(order.subtotal)}</span>
              </div>
              <div className="flex justify-between">
                <span>GST</span>
                <span>{formatCurrency(order.gstAmount)}</span>
              </div>
              <div className="flex justify-between">
                <span>Package Charge</span>
                <span>{formatCurrency(order.packageCharge)}</span>
              </div>
              {order.discount && (
                <div className="flex justify-between text-red-600">
                  <span>Discount</span>
                  <span>-{formatCurrency(order.discount.amount)}</span>
                </div>
              )}
              <div className="flex justify-between font-bold pt-2 border-t">
                <span>Total</span>
                <span>{formatCurrency(order.total)}</span>
              </div>
            </div>
          </div>

          <button
            onClick={handlePrint}
            className="w-full flex items-center justify-center gap-2 bg-primary-600 text-white py-3 rounded-lg font-medium hover:bg-primary-700 transition-colors"
          >
            <Printer className="h-5 w-5" />
            Print Receipt
          </button>
        </div>
      </div>
    </div>
  );
}