import { create } from 'zustand';
import { getAuthStore } from './useAuthStore';
import { InventoryItem, InventoryTransaction, InventoryAlert, InventoryCategory } from '../types/inventory';
import toast from 'react-hot-toast';
import {
  fetchInventoryItems,
  createInventoryItem,
  updateInventoryItem,
  createInventoryTransaction,
  fetchInventoryTransactions,
  fetchInventoryAlerts,
  acknowledgeAlert,
  deleteInventoryItem,
  revokeTransaction,
  updateTransaction
} from '../lib/api/inventory';
import {
  fetchInventoryCategories,
  createInventoryCategory,
  updateInventoryCategory,
  deleteInventoryCategory
} from '../lib/api/inventory-categories';

interface InventoryStore {
  items: InventoryItem[];
  transactions: InventoryTransaction[];
  categories: InventoryCategory[];
  alerts: InventoryAlert[];
  isLoading: boolean;
  error: Error | null;
  updateTransaction: (id: string, updates: Partial<InventoryTransaction>) => Promise<void>;
  fetchItems: (outletId: string) => Promise<void>;
  addItem: (item: Omit<InventoryItem, 'id' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  updateItem: (id: string, updates: Partial<InventoryItem>) => Promise<void>;
  deleteItem: (id: string) => Promise<void>;
  revokeTransaction: (transactionId: string) => Promise<void>;
  fetchTransactions: (outletId: string, itemId?: string, startDate?: Date, endDate?: Date) => Promise<void>;
  addTransaction: (transaction: Omit<InventoryTransaction, 'id' | 'createdAt' | 'createdBy'>) => Promise<void>;
  fetchAlerts: (outletId: string) => Promise<void>;
  acknowledgeAlert: (alertId: string) => Promise<void>;
  fetchCategories: (outletId: string) => Promise<void>;
  addCategory: (outletId: string, name: string, description?: string) => Promise<void>;
  updateCategory: (id: string, updates: { name?: string; description?: string; isActive?: boolean }) => Promise<void>;
  deleteCategory: (id: string) => Promise<void>;
}

export const useInventoryStore = create<InventoryStore>((set) => ({
  items: [],
  transactions: [],
  categories: [],
  alerts: [],
  isLoading: false,
  error: null,

  updateTransaction: async (id, updates) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateTransaction(id, updates);
      set((state) => ({
        transactions: state.transactions.map((t) =>
          t.id === id ? { ...t, ...updates } : t
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },
  fetchItems: async (outletId: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchInventoryItems(outletId);
      set({ items: data, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  addItem: async (item) => {
    try {
      set({ isLoading: true, error: null });
      const data = await createInventoryItem(item);
      set((state) => ({
        items: [...state.items, data],
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  updateItem: async (id, updates) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateInventoryItem(id, updates);
      set((state) => ({
        items: state.items.map((item) =>
          item.id === id ? data : item
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  deleteItem: async (id: string) => {
    try {
      set({ isLoading: true, error: null });
      await deleteInventoryItem(id);
      set((state) => ({
        items: state.items.filter((item) => item.id !== id),
        transactions: state.transactions.filter((t) => t.itemId !== id),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  revokeTransaction: async (transactionId: string) => {
    try {
      set({ isLoading: true, error: null });
      const state = useInventoryStore.getState();
      await revokeTransaction(transactionId);
      
      // Refetch transactions and items to get updated data
      if (state.transactions.length > 0) {
        const transaction = state.transactions.find(t => t.id === transactionId);
        if (transaction) {
          await state.fetchTransactions(transaction.outletId);
          await state.fetchItems(transaction.outletId);
        }
      }
      
      set({ isLoading: false });
      toast.success('Transaction revoked successfully');
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  fetchTransactions: async (outletId: string, itemId?: string, startDate?: Date, endDate?: Date) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchInventoryTransactions(outletId, itemId, startDate, endDate);
      set({ transactions: data, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  addTransaction: async (transaction) => {
    try {
      set({ isLoading: true, error: null });

      const data = await createInventoryTransaction(transaction);
      
      // Update local item stock immediately
      set((state) => {
        const updatedItems = state.items.map((item) => {
          if (item.id === transaction.itemId) {
            const stockChange = transaction.type === 'inward' 
              ? transaction.quantity 
              : -transaction.quantity;

            return {
              ...item,
              currentStock: (item.currentStock || 0) + stockChange
            };
          }
          return item;
        });

        return {
          transactions: [data, ...state.transactions],
          items: updatedItems,
          isLoading: false,
        };
      });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  fetchAlerts: async (outletId: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchInventoryAlerts(outletId);
      set({ alerts: data, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  acknowledgeAlert: async (alertId: string) => {
    try {
      set({ isLoading: true, error: null });
      await acknowledgeAlert(alertId);
      set(state => ({
        alerts: state.alerts.filter(alert => alert.id !== alertId),
        isLoading: false
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  fetchCategories: async (outletId: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchInventoryCategories(outletId);
      set({ categories: data, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  addCategory: async (outletId: string, name: string, description?: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await createInventoryCategory(outletId, name, description);
      set((state) => ({
        categories: [...state.categories, data],
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  updateCategory: async (id: string, updates: { name?: string; description?: string; isActive?: boolean }) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateInventoryCategory(id, updates);
      set((state) => ({
        categories: state.categories.map((cat) =>
          cat.id === id ? data : cat
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  deleteCategory: async (id: string) => {
    try {
      set({ isLoading: true, error: null });
      await deleteInventoryCategory(id);
      set((state) => ({
        categories: state.categories.filter((cat) => cat.id !== id),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },
}));