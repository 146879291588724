import React from 'react';
import { Filter } from 'lucide-react';
import { Category } from '../../lib/api/categories';

interface CategoryFilterProps {
  categories: Category[];
  selectedCategory: string;
  onCategoryChange: (category: string) => void;
  className?: string;
}

export function CategoryFilter({ categories, selectedCategory, onCategoryChange, className = '' }: CategoryFilterProps) {
  return (
    <div className={`flex items-center gap-3 overflow-x-auto pb-2 ${className}`}>
      <Filter className="h-5 w-5 text-gray-400 flex-shrink-0" />
      <button
        onClick={() => onCategoryChange('all')}
        className={`px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap ${
          selectedCategory === 'all'
            ? 'bg-red-100 text-red-700 border border-red-500'
            : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-200 hover:border-red-300'
        }`}
      >
        All Items
      </button>
      {categories.map((category) => (
        <button
          key={category.id}
          onClick={() => onCategoryChange(category.name)}
          className={`px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap ${
            selectedCategory === category.name
              ? 'bg-red-100 text-red-700 border border-red-500'
              : 'bg-white text-gray-600 hover:bg-gray-50 border border-gray-200 hover:border-red-300'
          }`}
        >
          {category.name}
        </button>
      ))}
    </div>
  );
}