import React from 'react';
import { Edit2, Plus, AlertCircle, Calendar, Trash2 } from 'lucide-react';
import { InventoryItem, InventoryCategory } from '../../types/inventory';
import { formatCurrency } from '../../lib/utils';
import { format, isBefore } from 'date-fns';
import { useAuthStore } from '../../store/useAuthStore';
import { useInventoryStore } from '../../store/useInventoryStore';
import toast from 'react-hot-toast';

interface InventoryListProps {
  items: InventoryItem[];
  categories: InventoryCategory[];
  onEdit: (item: InventoryItem) => void;
  onTransaction: (item: InventoryItem) => void;
}

export function InventoryList({ items, categories, onEdit, onTransaction }: InventoryListProps) {
  const { user } = useAuthStore();
  const { deleteItem } = useInventoryStore();
  
  const handleDelete = async (item: InventoryItem, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!user?.role || user.role !== 'admin') {
      toast.error('You must be an administrator to delete items');
      return;
    }

    const confirmed = window.confirm(
      `Are you sure you want to delete ${item.name}?\n\nNote: Items with existing transactions cannot be deleted. You must revoke all transactions first.`
    );

    if (confirmed) {
      try {
        await deleteItem(item.id);
        toast.success('Item deleted successfully');
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        } else {
          toast.error('Failed to delete item');
        }
      }
    }
  };

  return (
    <div className="bg-white shadow-sm rounded-xl overflow-hidden border border-gray-100">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-200">
            <tr>
              <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Item
              </th>
              <th className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Category
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Current Stock
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Next Purchase
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Value
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {items.map((item) => {
              const isLowStock = (item.currentStock || 0) <= item.minimumStock;
              const stockValue = (item.currentStock || 0) * (item.averageCost || 0);

              const getNextPurchaseInfo = (item: InventoryItem) => {
                if (!item.purchasePattern) return null;
                if (item.purchasePattern.nextPurchaseDate) {
                  return item.purchasePattern.nextPurchaseDate;
                }
                
                const today = new Date();
                let result = null;
                
                switch (item.purchasePattern.frequency) {
                  case 'daily':
                    result = new Date(today.setDate(today.getDate() + 1));
                    break;
                  case 'weekly':
                    if (item.purchasePattern.days?.length) {
                      const currentDay = today.getDay();
                      const nextDay = item.purchasePattern.days.find(d => d > currentDay) || item.purchasePattern.days[0];
                      const daysToAdd = nextDay > currentDay ? nextDay - currentDay : 7 - currentDay + nextDay;
                      result = new Date(today.setDate(today.getDate() + daysToAdd));
                    }
                    break;
                  case 'monthly':
                    if (item.purchasePattern.days?.length) {
                      const currentDate = today.getDate();
                      const nextDay = item.purchasePattern.days.find(d => d > currentDate) || item.purchasePattern.days[0];
                      const targetDate = new Date(today.getFullYear(), today.getMonth(), nextDay);
                      if (isBefore(targetDate, today)) {
                        targetDate.setMonth(targetDate.getMonth() + 1);
                      }
                      result = targetDate;
                    }
                    break;
                }
                
                return result;
              };

              return (
                <tr key={item.id} className="hover:bg-indigo-50/30 transition-colors">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>
                        <div className="text-sm font-medium text-gray-900">{item.name}</div>
                        <div className="text-sm text-gray-500">{item.description}</div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {categories.find(c => c.id === item.categoryId)?.name || '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <span className={`text-sm ${isLowStock ? 'text-red-600 font-medium' : 'text-gray-900'}`}>
                        {item.currentStock || 0} {item.unit}
                      </span>
                      {isLowStock && (
                        <AlertCircle className="h-4 w-4 text-red-500 ml-1" />
                      )}
                    </div>
                    {isLowStock && (
                      <span className="text-xs text-red-500">
                        Below minimum ({item.minimumStock} {item.unit})
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.purchasePattern && (
                      <div className="flex items-center text-sm">
                        <Calendar className="h-4 w-4 text-gray-400 mr-2" />
                        <div>
                          <div className="font-medium">
                            {item.purchasePattern.frequency.charAt(0).toUpperCase() + 
                             item.purchasePattern.frequency.slice(1)}
                            {item.purchasePattern.lastPurchaseDate && (
                              <div className="text-xs text-gray-500">
                                Last: {format(item.purchasePattern.lastPurchaseDate, 'MMM d, yyyy')}
                              </div>
                            )}
                          </div>
                          {getNextPurchaseInfo(item) && (
                            <div className="text-xs text-gray-500">
                              Next: {format(getNextPurchaseInfo(item)!, 'MMM d, yyyy')}
                            </div>
                          )}
                          {item.purchasePattern.averageQuantity > 0 && (
                            <div className="text-xs text-gray-500">
                              Qty: {item.purchasePattern.averageQuantity} {item.unit}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{formatCurrency(stockValue)}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => onTransaction(item)}
                      className="p-1.5 text-indigo-600 hover:text-indigo-900 hover:bg-indigo-50 rounded-lg transition-colors mr-2"
                    >
                      <Plus className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => onEdit(item)}
                      className="p-1.5 text-indigo-600 hover:text-indigo-900 hover:bg-indigo-50 rounded-lg transition-colors mr-2"
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                    {user?.role === 'admin' && (
                      <button
                        onClick={(e) => handleDelete(item, e)}
                        className="p-1.5 text-red-600 hover:text-red-900 hover:bg-red-50 rounded-lg transition-colors"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}