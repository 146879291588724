import { create } from 'zustand';
import { CustomerOrder } from '../types/order';
import { createCustomerOrder, fetchCustomerOrders, updateCustomerOrder } from '../lib/api/store';
import toast from 'react-hot-toast';

interface CustomerStore {
  orders: CustomerOrder[];
  isLoading: boolean;
  error: Error | null;
  fetchOrders: (customerId: string) => Promise<void>;
  placeOrder: (order: Omit<CustomerOrder, 'id' | 'created_at' | 'updated_at'>) => Promise<CustomerOrder>;
}

export const useCustomerStore = create<CustomerStore>((set) => ({
  orders: [],
  isLoading: false,
  error: null,

  fetchOrders: async (customerId: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchCustomerOrders(customerId);
      set({ orders: data, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  placeOrder: async (orderData) => {
    try {
      set({ isLoading: true, error: null });

      if (!orderData.items?.length) {
        throw new Error('Order must contain items');
      }

      // Create order with COD status
      const order = await createCustomerOrder({
        ...orderData,
        status: 'confirmed',
        paymentStatus: 'pending',
        paymentMethod: 'cod'
      });

      set((state) => ({
        orders: [order, ...state.orders],
        isLoading: false
      }));

      toast.success('Order placed successfully! You can pay on delivery.');
      return order;
    } catch (error) {
      console.error('Error placing order:', error);
      set({ error: error as Error, isLoading: false });
      toast.error('Failed to place order');
      throw error;
    }
  }
}));