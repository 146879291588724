import React from 'react';
import { Search } from 'lucide-react';
import { MenuGrid } from './MenuGrid';
import { MenuItem } from '../../types';
import { cn } from '../../lib/utils';

interface MenuSectionProps {
  items: MenuItem[];
  searchTerm: string;
  selectedCategory: string;
  categories: string[];
  onSearchChange: (value: string) => void;
  onCategoryChange: (value: string) => void;
  onItemClick: (item: MenuItem) => void;
}

export function MenuSection({
  items,
  searchTerm,
  selectedCategory,
  categories,
  onSearchChange,
  onCategoryChange,
  onItemClick,
}: MenuSectionProps) {
  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="flex-none py-4 bg-white border-b">
        <div className="flex flex-col space-y-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search menu items..."
              value={searchTerm}
              onChange={(e) => onSearchChange(e.target.value)}
              className="w-full pl-10 pr-4 py-2.5 border rounded-lg bg-white shadow-sm focus:ring-2 focus:ring-brand-red-500 focus:border-brand-red-500 transition-colors"
            />
          </div>
          <div className="flex overflow-x-auto hide-scrollbar -mx-1 pb-2">
            <button
              onClick={() => onCategoryChange('all')}
              className={cn(
                "flex-none mx-1 px-4 py-2 rounded-full text-sm font-medium transition-colors",
                selectedCategory === 'all'
                  ? 'bg-brand-red-100 text-brand-red-800'
                  : 'bg-white text-gray-600 hover:bg-brand-red-50'
              )}
            >
              All Items
            </button>
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => onCategoryChange(category)}
                className={cn(
                  "flex-none mx-1 px-4 py-2 rounded-full text-sm font-medium transition-colors whitespace-nowrap",
                  selectedCategory === category
                    ? 'bg-brand-red-100 text-brand-red-800'
                    : 'bg-white text-gray-600 hover:bg-brand-red-50'
                )}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-6 min-h-0">
        <MenuGrid items={items} onItemClick={onItemClick} />
      </div>
    </div>
  );
}