import React from 'react';
import { Bell, Calendar, AlertCircle, CheckCircle } from 'lucide-react';
import { InventoryAlert } from '../../types/inventory';
import { format } from 'date-fns';

interface InventoryAlertsProps {
  alerts: InventoryAlert[];
  onAcknowledge: (alertId: string) => void;
}

export function InventoryAlerts({ alerts, onAcknowledge }: InventoryAlertsProps) {
  if (alerts.length === 0) return null;

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
      <div className="p-4 bg-indigo-50 border-b border-indigo-100 flex items-center justify-between">
        <div className="flex items-center">
          <Bell className="h-5 w-5 text-indigo-500 mr-2" />
          <h3 className="text-lg font-medium text-indigo-900">Inventory Alerts</h3>
        </div>
        <span className="bg-indigo-100 text-indigo-600 text-sm font-medium px-2.5 py-0.5 rounded-full">
          {alerts.length} Active
        </span>
      </div>

      <div className="divide-y divide-gray-200">
        {alerts.map((alert) => (
          <div key={alert.id} className="p-4 hover:bg-gray-50">
            <div className="flex items-start justify-between">
              <div className="flex items-start space-x-3">
                {alert.type === 'low_stock' ? (
                  <AlertCircle className="h-5 w-5 text-red-500 mt-0.5" />
                ) : (
                  <Calendar className="h-5 w-5 text-yellow-500 mt-0.5" />
                )}
                <div>
                  <p className="text-sm font-medium text-gray-900">{alert.message}</p>
                  <div className="mt-1 text-sm text-gray-500">
                    <p>Created: {format(new Date(alert.createdAt), 'PPp')}</p>
                    {alert.currentStock !== undefined && alert.minimumStock !== undefined && (
                      <p className="mt-1">
                        Current Stock: {alert.currentStock} {alert.itemUnit} 
                        (Min: {alert.minimumStock} {alert.itemUnit})
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <button
                onClick={() => onAcknowledge(alert.id)}
                className="ml-4 inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <CheckCircle className="h-4 w-4 mr-1" />
                Acknowledge
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}