import React, { useState, useEffect, useMemo } from 'react';
import { useOrderStore } from '../store/useOrderStore';
import { useOutletStore } from '../store/useOutletStore';
import { formatCurrency, getDateRange } from '../lib/utils';
import { format } from 'date-fns';
import { Eye, Search, AlertCircle } from 'lucide-react';
import { OrderDetailsModal } from '../components/orders/OrderDetailsModal';
import { DateRangeFilter } from '../components/orders/DateRangeFilter';
import { Order, DateRangeType } from '../types';

export function Orders() {
  const { activeOrders, fetchOrders } = useOrderStore();
  const { currentOutlet } = useOutletStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOrderType, setSelectedOrderType] = useState<'all' | 'regular' | 'party' | 'daily'>('all');
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [dateRange, setDateRange] = useState<DateRangeType>('today');
  const [customStartDate, setCustomStartDate] = useState<Date>(new Date());
  const [customEndDate, setCustomEndDate] = useState<Date>(new Date());

  useEffect(() => {
    if (currentOutlet) {
      fetchOrders(currentOutlet);
    }
  }, [currentOutlet, fetchOrders]);

  const { start, end } = getDateRange(
    dateRange,
    dateRange === 'custom' ? customStartDate : undefined,
    dateRange === 'custom' ? customEndDate : undefined,
  );

  const filteredOrders = useMemo(() => {
    return activeOrders.filter(order => {
      const matchesOutlet = order.outletId === currentOutlet;
      const searchLower = searchTerm.toLowerCase().trim();
      const matchesSearch = searchLower === '' ||
        order.id.toLowerCase().includes(searchLower) ||
        (order.status && order.status.toLowerCase().includes(searchLower)) ||
        (order.description && order.description.toLowerCase().includes(searchLower));
      const matchesOrderType = selectedOrderType === 'all' || 
        (order.orderType || 'regular') === selectedOrderType;
      const orderDate = new Date(order.createdAt);
      const matchesDateRange = orderDate >= start && orderDate <= end;
      return matchesOutlet && matchesSearch && matchesDateRange && matchesOrderType;
    });
  }, [activeOrders, currentOutlet, searchTerm, selectedOrderType, start, end, dateRange]);

  if (!currentOutlet) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <AlertCircle className="h-16 w-16 text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">No Outlet Selected</h2>
        <p className="text-gray-600 mb-4">Please select an outlet to view orders</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Orders</h1>
        <div className="flex items-center gap-4">
          <select
            value={selectedOrderType}
            onChange={(e) => setSelectedOrderType(e.target.value as typeof selectedOrderType)}
            className="px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-brand-red-500"
          >
            <option value="all">All Types</option>
            <option value="regular">Regular</option>
            <option value="party">Party</option>
            <option value="daily">Daily</option>
          </select>
          <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search by order ID, status or description..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-brand-red-500 focus:border-brand-red-500"
          />
          </div>
        </div>
      </div>

      <DateRangeFilter
        dateRange={dateRange}
        onDateRangeChange={setDateRange}
        customStartDate={customStartDate}
        customEndDate={customEndDate}
        onCustomStartDateChange={setCustomStartDate}
        onCustomEndDateChange={setCustomEndDate}
      />

      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        {filteredOrders.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-500">No orders found</p>
          </div>
        ) : (
          <ul className="divide-y divide-gray-200">
            {filteredOrders.map((order) => (
              <li key={order.id}>
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <p className="text-sm font-medium text-indigo-600 truncate">
                        Order #{order.id}
                      </p>
                      <span
                        className={`ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          order.status === 'completed'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {order.status ? order.status.charAt(0).toUpperCase() + order.status.slice(1) : 'Unknown'}
                      </span>
                      <span className={`ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        order.orderType === 'party' 
                          ? 'bg-purple-100 text-purple-800'
                          : order.orderType === 'daily'
                          ? 'bg-orange-100 text-orange-800'
                          : 'bg-blue-100 text-blue-800'
                      }`}>
                        {(order.orderType || 'regular').charAt(0).toUpperCase() + (order.orderType || 'regular').slice(1)}
                      </span>
                    </div>
                    <div className="ml-2 flex-shrink-0 flex">
                      <button
                        onClick={() => setSelectedOrder(order)}
                        className="flex items-center text-indigo-600 hover:text-indigo-900"
                      >
                        <Eye className="h-5 w-5 mr-1" />
                        View Details
                      </button>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        Table {order.table_number || '-'}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        {order.items.length} items
                      </p>
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <p className="mr-4">
                        {format(new Date(order.createdAt), 'PPp')}
                      </p>
                      <p className="font-medium text-gray-900">
                        {formatCurrency(order.total)}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {selectedOrder && (
        <OrderDetailsModal
          order={selectedOrder}
          onClose={() => setSelectedOrder(null)}
        />
      )}
    </div>
  );
}