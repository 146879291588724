import React from 'react';
import { format } from 'date-fns';
import { Activity, User, Calendar, MapPin } from 'lucide-react';
import { AuditLog } from '../../types/auth';

interface AuditLogListProps {
  logs: AuditLog[];
}

export function AuditLogList({ logs }: AuditLogListProps) {
  const getActionColor = (action: string) => {
    switch (action.toLowerCase()) {
      case 'insert':
        return 'text-green-600 bg-green-50';
      case 'update':
        return 'text-blue-600 bg-blue-50';
      case 'delete':
        return 'text-red-600 bg-red-50';
      default:
        return 'text-gray-600 bg-gray-50';
    }
  };

  const getEntityIcon = (entityType: string) => {
    switch (entityType.toLowerCase()) {
      case 'menu':
        return <Activity className="h-5 w-5" />;
      case 'order':
        return <Calendar className="h-5 w-5" />;
      case 'outlet':
        return <MapPin className="h-5 w-5" />;
      default:
        return <User className="h-5 w-5" />;
    }
  };

  return (
    <div className="bg-white shadow-sm rounded-xl overflow-hidden border border-gray-100">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold text-gray-900">Activity Log</h2>
      </div>
      <div className="overflow-hidden">
        <ul className="divide-y divide-gray-200">
          {logs.map((log) => (
            <li key={log.id} className="p-6 hover:bg-gray-50 transition-colors">
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-indigo-50 text-indigo-600">
                    {getEntityIcon(log.entityType)}
                  </span>
                </div>
                <div className="min-w-0 flex-1">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900">
                      {log.userName}
                    </p>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getActionColor(log.action)}`}>
                      {log.action}
                    </span>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-gray-600">
                      {log.details}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center space-x-4 text-xs text-gray-500">
                    <div className="flex items-center">
                      <Calendar className="h-4 w-4 mr-1" />
                      {format(new Date(log.timestamp), 'PPp')}
                    </div>
                    {log.outletId && (
                      <div className="flex items-center">
                        <MapPin className="h-4 w-4 mr-1" />
                        {log.outletId}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
          {logs.length === 0 && (
            <li className="p-6 text-center text-gray-500">
              No activity logs found
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}