import { create } from 'zustand';
import { AuditLog } from '../types/auth';
import { fetchAuditLogs } from '../lib/api/audit';

interface AuditStore {
  logs: AuditLog[];
  isLoading: boolean;
  error: Error | null;
  fetchLogs: (outletId?: string, entityType?: string, startDate?: Date, endDate?: Date) => Promise<void>;
}

export const useAuditStore = create<AuditStore>((set) => ({
  logs: [],
  isLoading: false,
  error: null,

  fetchLogs: async (outletId?: string, entityType?: string, startDate?: Date, endDate?: Date) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchAuditLogs(outletId, entityType, startDate, endDate);
      set({ logs: data, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  }
}));