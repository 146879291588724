import React, { useState } from 'react';
import { MenuItem, GSTRate } from '../../types';
import { X, Image as ImageIcon, DollarSign, Tag, Globe } from 'lucide-react';
import { Category } from '../../lib/api/categories';
import { useEffect } from 'react';

interface MenuItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Omit<MenuItem, 'id'>) => void;
  initialData?: MenuItem;
  categories: Category[];
}

export function MenuItemModal({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  categories,
}: MenuItemModalProps) {
  const [formData, setFormData] = useState<Omit<MenuItem, 'id'>>({
    name: '',
    description: '',
    basePrice: 0,
    gstRate: 5,
    categoryId: categories[0]?.id || '',
    image: '',
    availability: { pos: true, online: true },
    allergens: [],
    dietary: [],
    modifiers: [],
    outletId: '',
  });

  useEffect(() => {
    if (isOpen && initialData) {
      setFormData({
        name: initialData.name,
        description: initialData.description,
        basePrice: initialData.basePrice,
        gstRate: initialData.gstRate,
        categoryId: initialData.categoryId,
        image: initialData.image,
        availability: initialData.availability,
        allergens: initialData.allergens,
        dietary: initialData.dietary,
        modifiers: initialData.modifiers,
        outletId: initialData.outletId,
      });
    } else if (isOpen) {
      setFormData({
        name: '',
        description: '',
        basePrice: 0,
        gstRate: 5,
        categoryId: categories[0]?.id || '',
        image: '',
        availability: { pos: true, online: true },
        allergens: [],
        dietary: [],
        modifiers: [],
        outletId: '',
      });
    }
  }, [isOpen, initialData, categories]);

  const gstAmount = (formData.basePrice * formData.gstRate) / 100;
  const totalPrice = formData.basePrice + gstAmount;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900 flex items-center gap-2">
            <Tag className="h-5 w-5 text-indigo-600" />
            {initialData ? 'Edit Menu Item' : 'Add New Menu Item'}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-700 mb-1">Item Name</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  required
                  placeholder="Enter item name"
                />
              </div>

              <div className="relative">
                <label className="block text-sm font-medium text-gray-700 mb-1">Category</label>
                <div className="relative">
                  <Tag className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
                  <select
                    value={formData.categoryId}
                    onChange={(e) => setFormData({ ...formData, categoryId: e.target.value })}
                    className="block w-full pl-10 pr-10 rounded-lg border-gray-300 bg-white shadow-sm focus:ring-indigo-500 focus:border-indigo-500 appearance-none"
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <svg className="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </div>
                </div>
                <p className="mt-1 text-xs text-gray-500">Choose a category for this menu item</p>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-700 mb-1">Base Price</label>
                <div className="relative">
                  <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
                  <input
                    type="number"
                    step="0.01"
                    value={formData.basePrice || ''}
                    onChange={(e) => setFormData({ ...formData, basePrice: parseFloat(e.target.value) })}
                    className="block w-full pl-10 rounded-lg border-gray-300 bg-white shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    required
                    min="0"
                    placeholder="0.00"
                  />
                </div>
              </div>

              <div className="relative">
                <label className="block text-sm font-medium text-gray-700 mb-1">GST Rate</label>
                <div className="relative">
                  <select
                    value={formData.gstRate}
                    onChange={(e) => setFormData({ ...formData, gstRate: parseInt(e.target.value) as GSTRate })}
                    className="block w-full rounded-lg border-gray-300 bg-white shadow-sm focus:ring-indigo-500 focus:border-indigo-500 appearance-none pr-10"
                    required
                  >
                    <option value={0}>0%</option>
                    <option value={5}>5%</option>
                    <option value={12}>12%</option>
                    <option value={18}>18%</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <span className="text-gray-500">%</span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                className="block w-full rounded-lg border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                rows={3}
                placeholder="Enter item description"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Image URL</label>
              <div className="relative">
                <ImageIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
                <input
                  type="url"
                  value={formData.image}
                  onChange={(e) => setFormData({ ...formData, image: e.target.value })}
                  className="block w-full pl-10 rounded-lg border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="https://example.com/image.jpg"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center gap-2">
                <Globe className="h-4 w-4 text-gray-400" />
                Availability
              </label>
              <div className="flex items-center gap-6 mt-2">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.availability.pos}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        availability: { ...formData.availability, pos: e.target.checked },
                      })
                    }
                    className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">POS System</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.availability.online}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        availability: { ...formData.availability, online: e.target.checked },
                      })
                    }
                    className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">Online Store</span>
                </label>
              </div>
            </div>

            <div className="bg-indigo-50 p-4 rounded-lg border border-indigo-100">
              <h3 className="text-sm font-medium text-indigo-900 mb-3">Price Breakdown</h3>
              <div className="space-y-2 text-sm">
                <div className="flex justify-between">
                  <span className="text-gray-600">Base Price:</span>
                  <span className="font-medium">₹{Number(formData.basePrice).toFixed(2)}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">GST ({formData.gstRate}%):</span>
                  <span className="font-medium">₹{Number(gstAmount).toFixed(2)}</span>
                </div>
                <div className="flex justify-between pt-2 border-t border-indigo-200">
                  <span className="font-medium text-indigo-900">Total Price:</span>
                  <span className="font-bold text-indigo-900">₹{Number(totalPrice).toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-4 border-t">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
            >
              {initialData ? 'Update Item' : 'Add Item'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}