import React, { useMemo } from 'react';
import { InventoryItem, InventoryTransaction } from '../../types/inventory';
import { formatCurrency } from '../../lib/utils';
import { format } from 'date-fns';
import { ArrowRight, Package } from 'lucide-react';
import { Link } from 'react-router-dom';

interface InventorySummaryProps {
  items: InventoryItem[];
  transactions: InventoryTransaction[];
}

export function InventorySummary({ items, transactions }: InventorySummaryProps) {
  const summary = useMemo(() => {
    // Calculate total purchase value within date range
    const purchaseValue = transactions
      .filter(t => t.type === 'inward')
      .reduce((sum, t) => sum + (t.quantity * (t.unitPrice || 0)), 0);

    // Calculate current stock value
    const totalValue = items.reduce((sum, item) => {
      const stock = item.currentStock || 0;
      
      // Get the latest transaction with price for this item
      const latestTransaction = transactions
        .filter(t => t.itemId === item.id && t.type === 'inward' && t.unitPrice)
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];
      
      // Use the latest transaction price or average cost if available
      const unitPrice = latestTransaction?.unitPrice || 0;
      const itemValue = stock * unitPrice;
      
      return sum + itemValue;
    }, 0);

    const lowStockItems = items.filter(item => 
      (item.currentStock || 0) <= item.minimumStock
    ).length;

    const outOfStockItems = items.filter(item => 
      (item.currentStock || 0) === 0
    ).length;

    const recentTransactions = transactions
      .slice(0, 5)
      .map(t => ({
        ...t,
        item: items.find(i => i.id === t.itemId),
      }));

    const topItems = items
      .map(item => {
        const itemTransactions = transactions.filter(t => t.itemId === item.id);
        const inward = itemTransactions
          .filter(t => t.type === 'inward')
          .reduce((sum, t) => sum + t.quantity, 0);
        const outward = itemTransactions
          .filter(t => t.type === 'outward')
          .reduce((sum, t) => sum + Math.abs(t.quantity), 0);

        const latestTransaction = transactions
          .filter(t => t.itemId === item.id && t.type === 'inward' && t.unitPrice)
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];

        return {
          ...item,
          inwardQuantity: inward,
          outwardQuantity: outward,
          value: (item.currentStock || 0) * (latestTransaction?.unitPrice || 0),
        };
      })
      .sort((a, b) => b.value - a.value)
      .slice(0, 5);

    return {
      purchaseValue,
      totalValue,
      lowStockItems,
      outOfStockItems,
      recentTransactions,
      topItems,
    };
  }, [items, transactions]);

  return (
    <div className="bg-white shadow-sm rounded-xl divide-y divide-gray-200 border border-gray-100">
      <div className="p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2">
          <Package className="h-5 w-5 text-indigo-600" />
          Overview
        </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5">
          <div className="bg-gradient-to-br from-indigo-50 to-indigo-100/50 px-6 py-4 rounded-lg border border-indigo-100">
            <dt className="text-sm font-medium text-indigo-600">Total Purchase Value</dt>
            <dd className="mt-1 text-3xl font-bold text-indigo-700">
              {formatCurrency(summary.purchaseValue)}
            </dd>
            <dd className="text-sm text-indigo-600 mt-1">Based on selected date range</dd>
          </div>
          <div className="bg-gradient-to-br from-emerald-50 to-emerald-100/50 px-6 py-4 rounded-lg border border-emerald-100">
            <dt className="text-sm font-medium text-indigo-600">Total Value</dt>
            <dd className="mt-1 text-3xl font-bold text-indigo-700">
              {formatCurrency(summary.totalValue)}
            </dd>
            <dd className="text-sm text-indigo-600 mt-1">Current stock value at latest prices</dd>
          </div>
          <div className="bg-gradient-to-br from-red-50 to-red-100/50 px-6 py-4 rounded-lg border border-red-100">
            <dt className="text-sm font-medium text-red-600">Stock Alerts</dt>
            <dd className="mt-1">
              <div className="text-3xl font-bold text-red-700">
                {summary.lowStockItems} items low
              </div>
              <div className="text-sm text-red-600 mt-1">
                {summary.outOfStockItems} out of stock
              </div>
            </dd>
          </div>
        </dl>
      </div>

      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h4 className="text-sm font-medium text-gray-900">Recent Transactions</h4>
          <Link 
            to="/inventory/transactions" 
            className="text-sm text-indigo-600 hover:text-indigo-900 flex items-center"
          >
            View All
            <ArrowRight className="h-4 w-4 ml-1" />
          </Link>
        </div>
        <div className="space-y-4 divide-y divide-gray-100">
          {summary.recentTransactions.map((transaction) => (
            <div key={transaction.id} className="flex justify-between items-start">
              <div>
                <div className="text-sm font-medium text-gray-900">
                  {transaction.item?.name}
                </div>
                <div className="text-xs text-gray-500">
                  {format(new Date(transaction.createdAt), 'PPp')}
                </div>
                <div className="text-xs text-gray-500">
                  By: {transaction.createdByName}
                </div>
                {transaction.notes && (
                  <div className="text-xs text-gray-500 mt-1">
                    Note: {transaction.notes}
                  </div>
                )}
              </div>
              <div className="text-sm">
                <span className={`font-medium ${
                  transaction.type === 'inward' 
                    ? 'text-emerald-600 bg-emerald-50 px-2 py-1 rounded-full' 
                    : 'text-red-600 bg-red-50 px-2 py-1 rounded-full'
                }`}>
                  {transaction.type === 'inward' ? '+' : '-'}
                  {Math.abs(transaction.quantity)} {transaction.item?.unit}
                </span>
                {transaction.unitPrice && (
                  <div className="text-xs text-gray-500">
                    @ {formatCurrency(transaction.unitPrice)}/{transaction.item?.unit}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="p-6">
        <h4 className="text-sm font-medium text-gray-900 mb-4">Top Items by Value</h4>
        <div className="space-y-4">
          {summary.topItems.map((item) => (
            <div key={item.id} className="flex justify-between items-start">
              <div>
                <div className="text-sm font-medium text-gray-900">{item.name}</div>
                <div className="text-xs text-gray-500">
                  Stock: {item.currentStock} {item.unit}
                </div>
              </div>
              <div className="text-right">
                <div className="text-sm font-medium text-gray-900">
                  {formatCurrency(item.value)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}