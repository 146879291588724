import { Order } from '../types';
import { formatCurrency } from './utils';
import { format } from 'date-fns';

interface PrintOptions {
  type: 'thermal' | 'browser';
  copies?: number;
}

export async function printReceipt(
  order: Order,
  restaurant: any,
  outlet: any,
  options: PrintOptions = { type: 'browser', copies: 1 }
) {
  try {
    const content = generateReceiptHTML(order, restaurant, outlet);
    return await printToBrowser(content);
  } catch (error) {
    console.error('Print error:', error);
    throw new Error('Failed to print receipt');
  }
}

async function printToBrowser(content: string): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      
      const printDocument = iframe.contentWindow?.document;
      if (!printDocument) {
        throw new Error('Could not create print document');
      }

      printDocument.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <title>Print Receipt</title>
            <style>
              @page {
                size: 80mm auto;
                margin: 0;
                padding: 0;
              }
              body {
                margin: 0;
                padding: 0;
                font-family: 'Courier New', monospace;
                font-size: 12px;
                line-height: 1.2;
                width: 80mm;
              }
              .receipt {
                padding: 5mm;
              }
              .header {
                text-align: center;
                margin-bottom: 3mm;
              }
              .header img {
                max-width: 15%;
                height: auto;
                margin-bottom: 2mm;
              }
              .header h1 {
                font-size: 16px;
                font-weight: bold;
                margin: 0 0 1mm 0;
              }
              .header p {
                margin: 0;
                font-size: 12px;
              }
              .divider {
                border-bottom: 1px dashed #000;
                margin: 2mm 0;
              }
              .order-info {
                margin: 2mm 0;
              }
              .items {
                margin: 2mm 0;
              }
              .item {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1mm;
                white-space: pre-wrap;
                word-wrap: break-word;
              }
              .item-details {
                font-size: 10px;
                color: #666;
                padding-left: 4mm;
              }
              .totals {
                margin: 2mm 0;
              }
              .total-row {
                display: flex;
                justify-content: space-between;
              }
              .grand-total {
                font-weight: bold;
                font-size: 14px;
              }
              .payment {
                margin: 2mm 0;
              }
              .footer {
                text-align: center;
                margin-top: 3mm;
                font-size: 12px;
                white-space: pre-wrap;
                word-wrap: break-word;
              }
              @media print {
                body {
                  width: 100%;
                }
              }
            </style>
          </head>
          <body>${content}</body>
        </html>
      `);
      printDocument.close();

      iframe.contentWindow?.focus();
      
      setTimeout(async () => {
        try {
          await iframe.contentWindow?.print();
          document.body.removeChild(iframe);
          resolve();
        } catch (error) {
          reject(error);
        }
      }, 1000);
    } catch (error) {
      reject(error);
    }
  });
}

function generateReceiptHTML(order: Order, restaurant: any, outlet: any): string {
  return `
    <div class="receipt">
      <div class="header">
        ${restaurant?.logo ? `<img src="${restaurant.logo}" class="h-10 w-auto object-contain" alt="${restaurant.name}" />` : ''}
        <h1>${restaurant?.name || ''}</h1>
        <p>${outlet?.name || ''}</p>
        <p>${outlet?.address || ''}</p>
        <p>Tel: ${outlet?.phone || ''}</p>
        <p>GST No: ${restaurant?.gstNumber || ''}</p>
      </div>

      <div class="divider"></div>

      <div class="order-info">
        Order #${order.id}<br/>
        Date: ${format(new Date(order.createdAt), 'dd/MM/yyyy HH:mm')}<br/>
        Table: ${order.table_number}<br/>
        Type: ${order.orderType.toUpperCase()}
      </div>

      <div class="divider"></div>

      <div class="items">
        ${order.items.map(item => `
          <div class="item">
            <span>${item.quantity}x ${item.menuItem.name}</span>
            <span>${formatCurrency(item.totalAmount)}</span>
          </div>
          <div class="item-details">
            Base: ${formatCurrency(item.baseAmount)} GST @${item.menuItem.gstRate}%: ${formatCurrency(item.gstAmount)}
          </div>
        `).join('')}
      </div>

      <div class="divider"></div>

      <div class="totals">
        <div class="total-row">
          <span>Subtotal:</span>
          <span>${formatCurrency(order.subtotal)}</span>
        </div>
        <div class="total-row">
          <span>GST:</span>
          <span>${formatCurrency(order.gstAmount)}</span>
        </div>
        <div class="total-row">
          <span>Package Charge:</span>
          <span>${formatCurrency(order.packageCharge)}</span>
        </div>
        ${order.discount ? `
          <div class="total-row" style="color: #dc2626;">
            <span>Discount:</span>
            <span>-${formatCurrency(order.discount.amount)}</span>
          </div>
        ` : ''}
        <div class="total-row grand-total">
          <span>Total:</span>
          <span>${formatCurrency(order.total)}</span>
        </div>
      </div>

      <div class="divider"></div>

      <div class="payment">
        ${order.payments.map(payment => `
          <div class="total-row">
            <span>${payment.method.toUpperCase()}:</span>
            <span>${formatCurrency(payment.amount)}</span>
          </div>
          ${payment.method === 'cash' ? `
            <div class="item-details">
              <div class="total-row">
                <span>Tendered:</span>
                <span>${formatCurrency(payment.tenderAmount || 0)}</span>
              </div>
              <div class="total-row">
                <span>Change:</span>
                <span>${formatCurrency(payment.changeAmount || 0)}</span>
              </div>
            </div>
          ` : ''}
          ${payment.method === 'upi' ? `
            <div class="item-details">UPI ID: ${payment.upiId}</div>
          ` : ''}
        `).join('')}
      </div>

      <div class="divider"></div>

      <div class="footer">
        Good food, great memories – thank you for making us a part of your story!<br/>
        Please visit again
      </div>
    </div>
  `;
}