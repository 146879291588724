import React, { useMemo } from 'react';
import { Expense, ExpenseCategory } from '../../types/expense';
import { formatCurrency } from '../../lib/utils';
import { format, parseISO } from 'date-fns';
import { ArrowRight, DollarSign } from 'lucide-react';
import { Link } from 'react-router-dom';

interface ExpenseSummaryProps {
  expenses: Expense[];
  categories: ExpenseCategory[];
}

export function ExpenseSummary({ expenses, categories }: ExpenseSummaryProps) {
  const summary = useMemo(() => {
    const byCategory = categories.map(category => ({
      categoryId: category.id,
      categoryName: category.name,
      amount: expenses
        .filter(e => e.categoryId === category.id)
        .reduce((sum, e) => sum + e.amount, 0),
      count: expenses.filter(e => e.categoryId === category.id).length,
    }));

    const byPaymentMethod = Object.entries(
      expenses.reduce((acc, expense) => {
        const method = expense.paymentMethod;
        if (!acc[method]) {
          acc[method] = { amount: 0, count: 0 };
        }
        acc[method].amount += expense.amount;
        acc[method].count += 1;
        return acc;
      }, {} as Record<string, { amount: number; count: number }>)
    ).map(([method, data]) => ({
      method,
      ...data,
    }));

    const recentTransactions = expenses
      .slice(0, 5)
      .map(expense => ({
        ...expense,
        categoryName: categories.find(c => c.id === expense.categoryId)?.name || 'Unknown Category'
      }));

    return {
      totalAmount: expenses.reduce((sum, e) => sum + e.amount, 0),
      totalTax: expenses.reduce((sum, e) => sum + e.taxAmount, 0),
      byCategory,
      byPaymentMethod,
      recentTransactions
    };
  }, [expenses, categories]);

  const formatDate = (dateString: string | Date) => {
    try {
      const date = typeof dateString === 'string' ? parseISO(dateString) : dateString;
      return format(date, 'PPp');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  return (
    <div className="bg-white shadow-sm rounded-xl divide-y divide-gray-200 border border-gray-100">
      <div className="p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2">
          <DollarSign className="h-5 w-5 text-indigo-600" />
          Overview
        </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5">
          <div className="bg-gradient-to-br from-indigo-50 to-indigo-100/50 px-6 py-4 rounded-lg border border-indigo-100">
            <dt className="text-sm font-medium text-indigo-600">Total Expenses</dt>
            <dd className="mt-1 text-3xl font-bold text-indigo-700">
              {formatCurrency(summary.totalAmount)}
            </dd>
            <dd className="mt-1 text-sm text-indigo-600">
              Tax: {formatCurrency(summary.totalTax)}
            </dd>
          </div>
        </dl>
      </div>

      <div className="p-6">
        <h4 className="text-sm font-medium text-gray-900 mb-4">By Category</h4>
        <div className="space-y-4">
          {summary.byCategory.map((cat) => (
            <div key={cat.categoryId} className="flex justify-between items-center">
              <div>
                <div className="text-sm font-medium text-gray-900">{cat.categoryName}</div>
                <div className="text-sm text-gray-500">{cat.count} expenses</div>
              </div>
              <div className="text-sm font-medium text-gray-900">
                {formatCurrency(cat.amount)}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h4 className="text-sm font-medium text-gray-900">Recent Transactions</h4>
          <Link 
            to="/expenses/transactions" 
            className="text-sm text-indigo-600 hover:text-indigo-900 flex items-center"
          >
            View All
            <ArrowRight className="h-4 w-4 ml-1" />
          </Link>
        </div>
        <div className="space-y-4">
          {summary.recentTransactions.map((expense) => (
            <div key={expense.id} className="flex justify-between items-start">
              <div>
                <div className="text-sm font-medium text-gray-900">
                  {expense.categoryName}
                </div>
                <div className="text-xs text-gray-500">
                  {formatDate(expense.createdAt)}
                </div>
                <div className="text-xs text-gray-500">
                  By: {expense.createdByName || 'System'}
                </div>
                {expense.description && (
                  <div className="text-xs text-gray-500 mt-1">
                    Note: {expense.description}
                  </div>
                )}
              </div>
              <div className="text-right">
                <div className="text-sm font-medium text-gray-900">
                  {formatCurrency(expense.amount)}
                </div>
                {expense.taxAmount > 0 && (
                  <div className="text-xs text-gray-500">
                    Tax: {formatCurrency(expense.taxAmount)}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="p-6">
        <h4 className="text-sm font-medium text-gray-900 mb-4">By Payment Method</h4>
        <div className="space-y-4">
          {summary.byPaymentMethod.map((method) => (
            <div key={method.method} className="flex justify-between items-center">
              <div>
                <div className="text-sm font-medium text-gray-900 capitalize">
                  {method.method?.toLowerCase().replace(/_/g, ' ') || 'Unknown'}
                </div>
                <div className="text-sm text-gray-500">{method.count} expenses</div>
              </div>
              <div className="text-sm font-medium text-gray-900">
                {formatCurrency(method.amount)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}