import { create } from 'zustand';
import { AuthState, LoginCredentials, RegisterData, User } from '../types/auth';
import { loginUser, registerUser, updateUserProfile } from '../lib/api/auth';
import { supabase } from '../lib/supabase-client';
import toast from 'react-hot-toast';

let store: AuthStore | null = null;

interface AuthStore extends AuthState {
  login: (credentials: LoginCredentials) => Promise<void>;
  register: (data: RegisterData) => Promise<void>;
  logout: () => Promise<void>;
  initialize: () => Promise<void>;
  updateProfile: (updates: Partial<User>) => Promise<void>;
}

export const useAuthStore = create<AuthStore>((set) => ({
  user: null,
  isLoading: true,
  error: null,

  initialize: async () => {
    try {
      set({ isLoading: true, error: null });
      await new Promise(resolve => setTimeout(resolve, 100)); // Small delay to prevent flash
      
      // Get current session
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError) {
        console.error('Session error:', sessionError);
        set({ user: null, isLoading: false, error: sessionError.message });
        return;
      }
      
      if (session?.user) {
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('*')
          .eq('id', session.user.id)
          .single();

        if (userError) {
          console.error('User data error:', userError);
          set({ user: null, isLoading: false, error: userError.message });
          return;
        }

        if (userData) {
          const user = {
            id: session.user.id,
            email: userData.email,
            name: userData.name,
            role: userData.role,
            phone: userData.phone,
            address: userData.address,
            createdAt: new Date(userData.created_at),
            updatedAt: new Date(userData.updated_at),
          };

          set({ user, isLoading: false, error: null });
          return;
        }
      }
      
      set({ user: null, isLoading: false, error: null });
    } catch (error) {
      console.error('Initialize error:', error);
      set({ user: null, isLoading: false, error: (error as Error).message });
    }
  },

  login: async (credentials) => {
    try {
      set({ isLoading: true, error: null });
      const user = await loginUser(credentials);
      set({ user, isLoading: false, error: null });
      toast.success('Login successful');
    } catch (error) {
      console.error('Login error:', error);
      set({ isLoading: false, error: (error as Error).message });
      throw error;
    }
  },

  register: async (data) => {
    try {
      set({ isLoading: true, error: null });
      const user = await registerUser(data);
      set({ user, isLoading: false, error: null });
      toast.success('Registration successful');
    } catch (error) {
      console.error('Registration error:', error);
      set({ isLoading: false, error: (error as Error).message });
      throw error;
    }
  },

  logout: async () => {
    try {
      set({ isLoading: true, error: null });
      
      // First check if we have a session
      const { data: { session } } = await supabase.auth.getSession();
      
      if (session) {
        const { error } = await supabase.auth.signOut();
        if (error) {
          console.error('Signout error:', error);
          throw error;
        }
      }
      
      // Clear user state regardless of session status
      set({ user: null, isLoading: false, error: null });
      toast.success('Logged out successfully');
    } catch (error) {
      console.error('Logout error:', error);
      set({ isLoading: false, error: (error as Error).message });
      // Still clear user state on error
      set({ user: null });
      throw error;
    }
  },

  updateProfile: async (updates) => {
    try {
      set({ isLoading: true, error: null });
      const user = set.getState().user;
      if (!user) throw new Error('No user logged in');

      const updatedUser = await updateUserProfile(user.id, updates);
      set({ 
        user: { ...user, ...updatedUser },
        isLoading: false,
        error: null 
      });
      toast.success('Profile updated successfully');
    } catch (error) {
      console.error('Profile update error:', error);
      set({ isLoading: false, error: (error as Error).message });
      throw error;
    }
  }
}));

// Helper to get store instance
export const getAuthStore = () => {
  if (!store) {
    store = useAuthStore.getState();
  }
  return store;
};

// Update store reference when state changes
useAuthStore.subscribe((state) => {
  store = state;
});