import React from 'react';
import { format } from 'date-fns';
import { Edit2, Trash2, DollarSign, ArrowUp, ArrowDown } from 'lucide-react';
import { Expense } from '../../types/expense';
import { formatCurrency } from '../../lib/utils';
import { useExpenseStore } from '../../store/useExpenseStore';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

type SortField = 'date' | 'category' | 'amount';
type SortOrder = 'asc' | 'desc';

interface ExpenseListProps {
  expenses: Expense[];
  onEdit: (expense: Expense) => void;
  sortConfig: { field: SortField; order: SortOrder };
  onSort: (field: SortField) => void;
}

export function ExpenseList({ expenses, onEdit, sortConfig, onSort }: ExpenseListProps) {
  const { deleteExpense } = useExpenseStore();

  const handleDelete = async (expense: Expense) => {
    if (window.confirm('Are you sure you want to delete this expense?')) {
      try {
        await deleteExpense(expense.id);
        toast.success('Expense deleted successfully');
      } catch (error) {
        console.error('Error deleting expense:', error);
        toast.error('Failed to delete expense');
      }
    }
  };

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortConfig.field !== field) return null;
    return sortConfig.order === 'desc' ? 
      <ArrowDown className="h-4 w-4 ml-1" /> : 
      <ArrowUp className="h-4 w-4 ml-1" />;
  };

  return (
    <div className="bg-white shadow-sm rounded-xl overflow-hidden border border-gray-100">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-200">
            <tr>
              <th 
                className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('date')}
              >
                <div className="flex items-center">
                  Date
                  <SortIcon field="date" />
                </div>
              </th>
              <th 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('category')}
              >
                <div className="flex items-center">
                  Category
                  <SortIcon field="category" />
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('amount')}
              >
                <div className="flex items-center">
                  Amount
                  <SortIcon field="amount" />
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Payment Method
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Added By
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {expenses.map((expense) => (
              <tr key={expense.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {format(new Date(expense.date), 'dd/MM/yyyy')}
                  <div className="text-xs text-gray-500">
                    {format(new Date(expense.createdAt), 'HH:mm')}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {expense.category?.name}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900">
                  {expense.description || '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <div>{formatCurrency(expense.amount)}</div>
                  {expense.taxAmount > 0 && (
                    <div className="text-xs text-gray-500">
                      Tax: {formatCurrency(expense.taxAmount)}
                    </div>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">
                  {expense.paymentMethod?.toLowerCase().replace(/_/g, ' ') || '-'}
                  {expense.referenceNumber && (
                    <div className="text-xs text-gray-500">
                      Ref: {expense.referenceNumber}
                    </div>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {expense.createdByName || 'System'}
                  <div className="text-xs text-gray-500">
                    {format(new Date(expense.createdAt), 'dd/MM/yyyy HH:mm')}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => onEdit(expense)}
                    className="text-indigo-600 hover:text-indigo-900 mr-3"
                  >
                    <Edit2 className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(expense)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {expenses.length === 0 && (
        <div className="text-center py-8">
          <p className="text-gray-500">No expenses found</p>
        </div>
      )}

      <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
        <Link
          to="/expenses/transactions"
          className="text-sm text-indigo-600 hover:text-indigo-900 font-medium"
        >
          View All Transactions →
        </Link>
      </div>
    </div>
  );
}