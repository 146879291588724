import React from 'react';
import { Link } from 'react-router-dom';
import { X, Minus, Plus, ShoppingBag, ArrowRight } from 'lucide-react';
import { MenuItem } from '../../types';
import { formatCurrency } from '../../lib/utils';
import { useCartStore } from '../../store/useCartStore';

interface CartSlideOverProps {
  isOpen: boolean;
  onClose: () => void;
  cartItems: Array<{ item: MenuItem; quantity: number }>;
  cartTotal: number;
  isLoggedIn: boolean;
}

export function CartSlideOver({ isOpen, onClose, cartItems, cartTotal, isLoggedIn }: CartSlideOverProps) {
  const { updateQuantity, removeItem } = useCartStore();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        {/* Overlay */}
        <div 
          className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" 
          onClick={onClose}
        />

        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <div className="pointer-events-auto w-screen max-w-md">
            <div className="flex h-full flex-col bg-white shadow-xl">
              {/* Header */}
              <div className="flex items-center justify-between px-4 py-6 sm:px-6">
                <div className="flex items-center">
                  <ShoppingBag className="h-6 w-6 text-red-600 mr-2" />
                  <h2 className="text-lg font-medium text-gray-900">Your Cart</h2>
                  <span className="ml-2 text-sm text-gray-500">
                    ({cartItems.reduce((sum, item) => sum + item.quantity, 0)} items)
                  </span>
                </div>
                <button
                  type="button"
                  onClick={onClose}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>

              {/* Cart Items */}
              <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                {cartItems.length === 0 ? (
                  <div className="text-center py-12">
                    <ShoppingBag className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">Cart is empty</h3>
                    <p className="mt-1 text-sm text-gray-500">Start adding some items to your cart</p>
                  </div>
                ) : (
                  <div className="space-y-8">
                    {cartItems.map(({ item, quantity }) => (
                      <div key={item.id} className="flex items-center">
                        {item.image && (
                          <img
                            src={item.image}
                            alt={item.name}
                            className="h-20 w-20 rounded-lg object-cover"
                          />
                        )}
                        <div className="ml-4 flex-1">
                          <div className="flex justify-between">
                            <div>
                              <h3 className="text-base font-medium text-gray-900">{item.name}</h3>
                              <p className="mt-1 text-sm text-gray-500">
                                {formatCurrency(item.basePrice)} + {item.gstRate}% GST
                              </p>
                            </div>
                            <button
                              onClick={() => removeItem(item.id)}
                              className="text-sm font-medium text-red-600 hover:text-red-500"
                            >
                              Remove
                            </button>
                          </div>
                          <div className="mt-2 flex items-center">
                            <button
                              onClick={() => updateQuantity(item.id, quantity - 1)}
                              className="rounded-full border border-gray-300 p-1 text-gray-600 hover:bg-gray-50"
                            >
                              <Minus className="h-4 w-4" />
                            </button>
                            <span className="mx-3 text-gray-900">{quantity}</span>
                            <button
                              onClick={() => updateQuantity(item.id, quantity + 1)}
                              className="rounded-full border border-gray-300 p-1 text-gray-600 hover:bg-gray-50"
                            >
                              <Plus className="h-4 w-4" />
                            </button>
                            <div className="ml-auto text-right">
                              <p className="text-sm font-medium text-gray-900">
                                {formatCurrency(item.basePrice * quantity)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Footer */}
              {cartItems.length > 0 && (
                <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                  <div className="space-y-4">
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <p>Subtotal</p>
                      <p>{formatCurrency(cartTotal)}</p>
                    </div>
                    <Link
                      to={isLoggedIn ? "/checkout" : "/login?redirect=/checkout"}
                      onClick={onClose}
                      className="flex items-center justify-center rounded-full bg-red-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-700"
                    >
                      Checkout
                      <ArrowRight className="ml-2 h-5 w-5" />
                    </Link>
                    <div className="text-center">
                      <button
                        type="button"
                        onClick={onClose}
                        className="text-sm font-medium text-gray-500 hover:text-gray-600"
                      >
                        Continue Shopping
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}