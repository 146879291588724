import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useMenuStore } from '../store/useMenuStore';
import { useOrderStore } from '../store/useOrderStore';
import { useOutletStore } from '../store/useOutletStore';
import { MenuItem, Order, OrderItem, Payment } from '../types';
import { generateOrderId } from '../lib/utils';
import { Search, AlertCircle, Menu as MenuIcon } from 'lucide-react';
import { PaymentModal } from '../components/pos/PaymentModal';
import { OrderCompleteModal } from '../components/pos/OrderCompleteModal';
import { MenuSection } from '../components/pos/MenuSection';
import { OrderSummary } from '../components/pos/OrderSummary';
import { cn } from '../lib/utils';
import toast from 'react-hot-toast';

export function POS() {
  const { items, categories, fetchItems, fetchCategories } = useMenuStore();
  const { addOrder } = useOrderStore();
  const { currentOutlet } = useOutletStore();
  
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showOrderComplete, setShowOrderComplete] = useState(false);
  const [completedOrder, setCompletedOrder] = useState<Order | null>(null);
  const [tableNumber, setTableNumber] = useState(1);
  const [orderDescription, setOrderDescription] = useState('');
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [orderType, setOrderType] = useState<'regular' | 'party' | 'daily'>('regular');

  const [currentOrder, setCurrentOrder] = useState<Order>(() => ({
    id: generateOrderId(), // Generate a unique ID for the order
    items: [],
    orderType: 'regular',
    table_number: tableNumber,
    status: 'pending',
    createdAt: new Date(),
    updatedAt: new Date(),
    subtotal: 0,
    gstAmount: 0,
    total: 0,
    packageCharge: 0,
    discount: null,
    payments: [],
    outletId: currentOutlet || '',
  }));

  useEffect(() => {
    if (currentOutlet) {
      fetchItems(currentOutlet);
      fetchCategories(currentOutlet);
    }
  }, [currentOutlet, fetchItems, fetchCategories]);

  // Reset order when needed
  const resetOrder = useCallback(() => {
    setCurrentOrder({
      id: generateOrderId(),
      items: [],
      orderType: 'regular',
      description: '',
      table_number: tableNumber,
      status: 'pending',
      createdAt: new Date(),
      updatedAt: new Date(),
      subtotal: 0,
      gstAmount: 0,
      total: 0,
      packageCharge: 0,
      discount: null,
      payments: [],
      outletId: currentOutlet || '',
    });
  }, [tableNumber, currentOutlet]);

  // Filter items by search and category
  const filteredItems = useMemo(() => {
    return items.filter(item => {
      const matchesSearch = item.name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesCategory = selectedCategory === 'all' || item.category === selectedCategory;
      const isAvailableInPOS = item.availability.pos;
      const belongsToOutlet = item.outletId === currentOutlet;
      return matchesSearch && matchesCategory && isAvailableInPOS && belongsToOutlet;
    });
  }, [items, searchTerm, selectedCategory, currentOutlet]);

  const handleAddItem = (menuItem: MenuItem) => {
    const existingItemIndex = currentOrder.items.findIndex(
      item => item.menuItem.id === menuItem.id
    );

    const basePrice = orderType === 'regular' ? menuItem.basePrice : 0; // Set 0 for all special order types

    if (existingItemIndex >= 0) {
      const updatedItems = [...currentOrder.items];
      const item = updatedItems[existingItemIndex];
      item.quantity += 1;
      item.baseAmount = (item.customPrice || basePrice) * item.quantity;
      item.gstAmount = (item.baseAmount * item.menuItem.gstRate) / 100;
      item.totalAmount = item.baseAmount + item.gstAmount;
      updateOrderTotals(updatedItems);
    } else {
      const newItem: OrderItem = {
        menuItem,
        quantity: 1,
        customPrice: orderType === 'regular' ? undefined : 0,
        modifiers: [],
        baseAmount: basePrice,
        gstAmount: (basePrice * menuItem.gstRate) / 100,
        totalAmount: basePrice + (basePrice * menuItem.gstRate) / 100,
      };
      updateOrderTotals([...currentOrder.items, newItem]);
    }
  };

  const handleRemoveItem = useCallback((index: number) => {
    const updatedItems = currentOrder.items.filter((_, i) => i !== index);
    const subtotal = updatedItems.reduce((sum, item) => sum + item.baseAmount, 0);
    const gstAmount = updatedItems.reduce((sum, item) => sum + item.gstAmount, 0);
    
    setCurrentOrder(prev => ({
      ...prev,
      items: updatedItems,
      subtotal,
      gstAmount,
      total: subtotal + gstAmount,
      updatedAt: new Date()
    }));

    if (updatedItems.length === 0) {
      resetOrder();
    }
  }, [currentOrder, resetOrder]);

  const handleUpdateQuantity = (index: number, newQuantity: number) => {
    if (newQuantity < 1) {
      handleRemoveItem(index);
      return;
    }

    const updatedItems = [...currentOrder.items];
    const item = updatedItems[index];
    item.quantity = newQuantity;
    item.baseAmount = item.menuItem.basePrice * newQuantity;
    item.gstAmount = (item.baseAmount * item.menuItem.gstRate) / 100;
    item.totalAmount = item.baseAmount + item.gstAmount;
    updateOrderTotals(updatedItems);
  };

  const updateOrderTotals = useCallback((items: OrderItem[]) => {
    const subtotal = items.reduce((sum, item) => sum + item.baseAmount, 0);
    const gstAmount = items.reduce((sum, item) => sum + item.gstAmount, 0);
    const total = subtotal + gstAmount;

    setCurrentOrder(prev => ({
      ...prev,
      items,
      subtotal,
      gstAmount,
      total,
      updatedAt: new Date(),
    }));
  }, []);

  const handlePaymentComplete = async (payments: Payment[]) => {
    try {
      const finalOrder = {
        ...currentOrder,
        orderType,
        description: orderDescription,
        payments,
        status: 'completed' as const,
      };

      await addOrder(finalOrder);
      setCompletedOrder(finalOrder);
      setShowPaymentModal(false);
      setShowOrderComplete(true);

      resetOrder();
    } catch (error) {
      console.error('Error completing order:', error);
      toast.error('Failed to complete order');
    }
  };

  if (!currentOutlet) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <AlertCircle className="h-16 w-16 text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">No Outlet Selected</h2>
        <p className="text-gray-600 mb-4">Please select an outlet to continue</p>
      </div>
    );
  }

  return (
    <div className="fixed inset-x-0 bottom-0 top-16 flex flex-col md:flex-row">
      {/* Mobile Header */}
      <div className="md:hidden flex-none bg-white border-b px-4 py-2 flex items-center justify-between">
        <button
          onClick={() => setShowMobileMenu(!showMobileMenu)}
          className="p-2 rounded-lg hover:bg-gray-100"
        >
          <MenuIcon className="h-6 w-6" />
        </button>
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-500">Table</span>
          <input
            type="number"
            min="1"
            value={tableNumber}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setTableNumber(value);
              setCurrentOrder(prev => ({ ...prev, table: value }));
            }}
            className="w-16 px-2 py-1 border rounded text-center"
          />
        </div>
      </div>

      {/* Main Container with Max Width and Auto Margins */}
      <div className="flex-1 flex flex-col md:flex-row max-w-[1920px] mx-auto w-full">
        {/* Left Section - Menu */}
        <div className={cn(
          "flex-1 md:w-2/3 flex flex-col bg-gradient-to-br from-brand-red-50 to-white transition-all duration-300 md:border-r border-gray-200",
          showMobileMenu ? "hidden md:flex" : "flex"
        )}>
          <div className="max-w-5xl mx-auto w-full px-4 md:px-6 lg:px-8 h-full overflow-hidden">
            <MenuSection
              items={filteredItems}
              searchTerm={searchTerm}
              selectedCategory={selectedCategory}
              categories={categories.map(c => c.name)}
              onSearchChange={setSearchTerm}
              onCategoryChange={setSelectedCategory}
              onItemClick={handleAddItem}
            />
          </div>
        </div>

        {/* Right Section - Order Summary */}
        <div className={cn(
          "w-full md:w-[400px] lg:w-[480px] flex-none flex flex-col bg-white border-l border-brand-red-100 transition-all duration-300 overflow-hidden",
          showMobileMenu ? "flex" : "hidden md:flex"
        )}>
          <div className="h-full">
            <OrderSummary
              order={currentOrder}
              onRemoveItem={handleRemoveItem}
              onUpdateQuantity={handleUpdateQuantity}
              onPaymentComplete={handlePaymentComplete}
              tableNumber={tableNumber}
              onTableChange={(value) => {
                setTableNumber(value);
                setCurrentOrder(prev => ({ ...prev, table: value }));
              }}
              updateOrderTotals={updateOrderTotals}
              orderType={orderType}
              onOrderTypeChange={setOrderType}
              orderDescription={orderDescription}
              onOrderDescriptionChange={setOrderDescription}
              setShowPaymentModal={setShowPaymentModal}
            />
          </div>
        </div>
      </div>

      {/* Modals */}
      {showPaymentModal && (
        <PaymentModal
          order={currentOrder}
          onClose={() => setShowPaymentModal(false)}
          onPaymentComplete={handlePaymentComplete}
        />
      )}

      {showOrderComplete && completedOrder && (
        <OrderCompleteModal
          order={completedOrder}
          onClose={() => {
            setShowOrderComplete(false);
            setCompletedOrder(null);
          }}
        />
      )}
    </div>
  );
}