import React, { useState } from 'react';
import { X, Edit2 } from 'lucide-react';
import { InventoryTransaction } from '../../types/inventory';
import { formatCurrency } from '../../lib/utils';
import { format } from 'date-fns';

interface TransactionEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  transaction: InventoryTransaction;
  onSave: (updates: Partial<InventoryTransaction>) => Promise<void>;
}

export function TransactionEditModal({ isOpen, onClose, transaction, onSave }: TransactionEditModalProps) {
  const [formData, setFormData] = useState({
    notes: transaction.notes || '',
    referenceNumber: transaction.referenceNumber || '',
    supplier: transaction.supplier || ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await onSave(formData);
      onClose();
    } catch (error) {
      console.error('Error updating transaction:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Edit Transaction</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="mb-6 bg-gray-50 p-4 rounded-lg">
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <span className="text-gray-500 block mb-1">Date:</span>
              <p className="font-medium">{format(transaction.createdAt, 'PPp')}</p>
            </div>
            <div>
              <span className="text-gray-500 block mb-1">Item:</span>
              <p className="font-medium">{transaction.item?.name}</p>
            </div>
            <div>
              <span className="text-gray-500 block mb-1">Type:</span>
              <p className={`font-medium inline-flex items-center px-2.5 py-0.5 rounded-full text-xs ${
                transaction.type === 'inward'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800'
              }`}>
                {transaction.type.toUpperCase()}
              </p>
            </div>
            <div>
              <span className="text-gray-500 block mb-1">Quantity:</span>
              <p className="font-medium">{transaction.quantity} {transaction.item?.unit}</p>
            </div>
            <div>
              <span className="text-gray-500 block mb-1">Unit Price:</span>
              <p className="font-medium">{transaction.unitPrice ? formatCurrency(transaction.unitPrice) : '-'}</p>
            </div>
            <div>
              <span className="text-gray-500 block mb-1">Total:</span>
              <p className="font-medium">{formatCurrency(transaction.totalPrice || 0)}</p>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Reference Number</label>
            <input
              type="text"
              value={formData.referenceNumber}
              onChange={(e) => setFormData({ ...formData, referenceNumber: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Supplier</label>
            <input
              type="text"
              value={formData.supplier}
              onChange={(e) => setFormData({ ...formData, supplier: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Notes</label>
            <textarea
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}