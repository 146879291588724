import React, { useEffect } from 'react';
import { useCustomerStore } from '../../store/useCustomerStore';
import { useAuthStore } from '../../store/useAuthStore';
import { formatCurrency } from '../../lib/utils';
import { parseISO, format } from 'date-fns';
import { Package, Clock, MapPin, CreditCard } from 'lucide-react';

export function CustomerOrders() {
  const { orders, fetchOrders } = useCustomerStore();
  const { user } = useAuthStore();

  useEffect(() => {
    if (user) {
      fetchOrders(user.id);
    }
  }, [user]);

  const formatDate = (dateString: string) => {
    try {
      return format(parseISO(dateString), 'PPp');
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'delivered':
        return 'bg-green-100 text-green-800';
      case 'preparing':
        return 'bg-yellow-100 text-yellow-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-blue-100 text-blue-800';
    }
  };

  const formatStatus = (status: string) => {
    return status.split('_').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-8">Order History</h1>

        <div className="space-y-6">
          {orders.length === 0 ? (
            <div className="text-center py-12 bg-white rounded-xl shadow-sm">
              <Package className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No orders yet</h3>
              <p className="mt-1 text-sm text-gray-500">Start ordering your favorite dishes!</p>
            </div>
          ) : (
            orders.map(order => (
              <div key={order.id} className="bg-white rounded-xl shadow-sm overflow-hidden">
                <div className="border-b border-gray-200 bg-gray-50 px-6 py-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <Package className="h-5 w-5 text-gray-400" />
                      <span className="text-sm font-medium text-gray-900">
                        Order #{order.id}
                      </span>
                    </div>
                    <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                      getStatusColor(order.delivery_status)
                    }`}>
                      {formatStatus(order.delivery_status)}
                    </span>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <Clock className="h-4 w-4 mr-1.5" />
                    {formatDate(order.created_at)}
                  </div>
                </div>

                <div className="px-6 py-4">
                  <div className="flow-root">
                    <ul className="-my-5 divide-y divide-gray-200">
                      {order.items.map((item, index) => (
                        <li key={index} className="py-5">
                          <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {item.menuItem.name}
                              </p>
                              <p className="text-sm text-gray-500">
                                {formatCurrency(item.menuItem.basePrice)} x {item.quantity}
                              </p>
                            </div>
                            <div className="text-right">
                              <p className="text-sm font-medium text-gray-900">
                                {formatCurrency(item.totalAmount)}
                              </p>
                              <p className="text-xs text-gray-500">
                                Incl. {item.menuItem.gstRate}% GST
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="mt-6 border-t border-gray-200 pt-4">
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Subtotal</span>
                      <span className="text-gray-900">{formatCurrency(order.subtotal)}</span>
                    </div>
                    <div className="flex justify-between text-sm mt-2">
                      <span className="text-gray-500">GST</span>
                      <span className="text-gray-900">{formatCurrency(order.gst_amount)}</span>
                    </div>
                    <div className="flex justify-between text-base font-medium mt-4 pt-4 border-t">
                      <span className="text-gray-900">Total</span>
                      <span className="text-gray-900">{formatCurrency(order.total)}</span>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 px-6 py-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <div className="flex items-center text-sm text-gray-500 mb-1">
                        <MapPin className="h-4 w-4 mr-1.5" />
                        Delivery Address
                      </div>
                      <p className="text-sm text-gray-900">{order.customer_name}</p>
                      <p className="text-sm text-gray-900">{order.customer_phone}</p>
                      <p className="text-sm text-gray-900">{order.customer_address}</p>
                    </div>
                    <div className="text-right">
                      <div className="flex items-center justify-end text-sm text-gray-500 mb-1">
                        <CreditCard className="h-4 w-4 mr-1.5" />
                        Payment Status
                      </div>
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        order.payment_status === 'paid'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {formatStatus(order.payment_status)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}