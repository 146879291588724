import React, { useState, useEffect } from 'react';
import { useMenuStore } from '../store/useMenuStore';
import { useOrderStore } from '../store/useOrderStore';
import { useOutletStore } from '../store/useOutletStore';
import { formatCurrency, getDateRange } from '../lib/utils';
import { DateRangeType } from '../types';
import { Calendar, TrendingUp, Users, Store, AlertCircle, DollarSign, Package, ShoppingBag, ArrowUp, ArrowDown } from 'lucide-react';
import { format } from 'date-fns';
import { LineChart, Line, PieChart, Pie, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell } from 'recharts';

export function Dashboard() {
  const { items, fetchItems } = useMenuStore();
  const { activeOrders, fetchOrders } = useOrderStore();
  const { currentOutlet } = useOutletStore();
  const [dateRange, setDateRange] = useState<DateRangeType>('today');
  const [customStartDate, setCustomStartDate] = useState<Date>(new Date());
  const [customEndDate, setCustomEndDate] = useState<Date>(new Date());
  const [selectedMetric, setSelectedMetric] = useState<'sales' | 'orders'>('sales');

  // Brand colors for charts
  const BRAND_COLORS = {
    red: '#ff0000',
    green: '#006400',
    darkYellow: '#cc8800',
    lightRed: '#ffcccc'
  };

  // Colors for pie chart
  const PIE_COLORS = [
    '#ff0000',    // Red for Regular
    '#cc8800',    // Dark Yellow for Party  
    '#006400',    // Green for Daily
  ];

  useEffect(() => {
    if (currentOutlet) {
      fetchItems(currentOutlet);
      fetchOrders(currentOutlet);
    }
  }, [currentOutlet, fetchItems, fetchOrders]);

  const { start, end } = getDateRange(
    dateRange,
    dateRange === 'custom' ? customStartDate : undefined,
    dateRange === 'custom' ? customEndDate : undefined
  );

  const filteredOrders = activeOrders.filter(
    order => {
      const orderDate = new Date(order.createdAt);
      return orderDate >= start && orderDate <= end && order.outletId === currentOutlet;
    }
  );

  const totalSales = filteredOrders.reduce((sum, order) => sum + order.total, 0);
  const totalOrders = filteredOrders.length;
  const averageOrderValue = totalOrders > 0 ? totalSales / totalOrders : 0;
  const totalTax = filteredOrders.reduce((sum, order) => sum + order.gstAmount, 0);
  const totalDiscount = filteredOrders.reduce((sum, order) => sum + (order.discount?.amount || 0), 0);

  // Calculate order type distribution
  const orderTypeDistribution = filteredOrders.reduce((acc, order) => {
    const type = order.orderType;
    acc[type] = (acc[type] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  // Prepare data for recharts
  const salesData = filteredOrders
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
    .reduce((acc, order) => {
      const date = format(new Date(order.createdAt), dateRange === 'today' ? 'HH:mm' : 'MMM dd');
      const existingEntry = acc.find(entry => entry.date === date);
      
      if (existingEntry) {
        existingEntry.amount += Number(order.total);
        existingEntry.amount = Number(existingEntry.amount.toFixed(2));
      } else {
        acc.push({
          date,
          amount: Number(order.total.toFixed(2))
        });
      }
      
      return acc;
    }, [] as Array<{ date: string; amount: number }>);

  const orderTypeData = Object.entries(orderTypeDistribution).map(([type, count]) => ({
    name: type.charAt(0).toUpperCase() + type.slice(1),
    value: count
  }));

  const popularItems = items
    .map(item => ({
      ...item,
      orderCount: filteredOrders.reduce(
        (count, order) =>
          count +
          order.items.filter(orderItem => orderItem.menuItem.id === item.id).length,
        0
      ),
      revenue: filteredOrders.reduce(
        (sum, order) =>
          sum +
          order.items
            .filter(orderItem => orderItem.menuItem.id === item.id)
            .reduce((itemSum, orderItem) => itemSum + orderItem.totalAmount, 0),
        0
      )
    }))
    .sort((a, b) => b.orderCount - a.orderCount)
    .slice(0, 5);

  if (!currentOutlet) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <AlertCircle className="h-16 w-16 text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">No Outlet Selected</h2>
        <p className="text-gray-600 mb-4">Please select an outlet to view dashboard</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        <div className="flex items-center gap-4">
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value as DateRangeType)}
            className="border rounded-md px-3 py-2"
          >
            <option value="today">Today</option>
            <option value="thisWeek">This Week</option>
            <option value="thisMonth">This Month</option>
            <option value="lastWeek">Last Week</option>
            <option value="lastMonth">Last Month</option>
            <option value="custom">Custom Range</option>
          </select>

          {dateRange === 'custom' && (
            <div className="flex items-center gap-2">
              <input
                type="date"
                value={format(customStartDate, 'yyyy-MM-dd')}
                onChange={(e) => setCustomStartDate(new Date(e.target.value))}
                className="border rounded-md px-3 py-2"
              />
              <span>to</span>
              <input
                type="date"
                value={format(customEndDate, 'yyyy-MM-dd')}
                onChange={(e) => setCustomEndDate(new Date(e.target.value))}
                className="border rounded-md px-3 py-2"
              />
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-medium text-gray-900">Total Sales</h2>
            <TrendingUp className="h-6 w-6 text-brand-green-500" />
          </div>
          <p className="text-3xl font-bold text-brand-red-600">{formatCurrency(totalSales)}</p>
          <div className="mt-2 flex items-center text-sm">
            <DollarSign className="h-4 w-4 text-gray-400 mr-1" />
            <span className="text-gray-600">Tax: {formatCurrency(totalTax)}</span>
          </div>
          <div className="mt-1 flex items-center text-sm">
            <ArrowDown className="h-4 w-4 text-red-500 mr-1" />
            <span className="text-red-500">Discounts: {formatCurrency(totalDiscount)}</span>
          </div>
          <p className="text-sm text-gray-500 mt-2">
            {format(start, 'PPP')} - {format(end, 'PPP')}
          </p>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-medium text-gray-900">Total Orders</h2>
            <Calendar className="h-6 w-6 text-brand-green-500" />
          </div>
          <p className="text-3xl font-bold text-brand-red-600">{totalOrders}</p>
          <div className="mt-2 space-y-1">
            {Object.entries(orderTypeDistribution).map(([type, count]) => (
              <div key={type} className="flex items-center text-sm">
                <div className="w-2 h-2 rounded-full bg-indigo-600 mr-2" />
                <span className="capitalize">{type}: {count}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-medium text-gray-900">Average Order</h2>
            <Users className="h-6 w-6 text-brand-green-500" />
          </div>
          <p className="text-3xl font-bold text-brand-red-600">{formatCurrency(averageOrderValue)}</p>
          <div className="mt-2 flex items-center text-sm">
            <ArrowUp className="h-4 w-4 text-green-500 mr-1" />
            <span className="text-green-500">
              {((averageOrderValue / (totalSales / totalOrders || 1) - 1) * 100).toFixed(1)}% vs. last period
            </span>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-medium text-gray-900">Menu Items</h2>
            <Store className="h-6 w-6 text-brand-green-500" />
          </div>
          <p className="text-3xl font-bold text-brand-red-600">{items.length}</p>
          <div className="mt-2 flex items-center text-sm">
            <Package className="h-4 w-4 text-gray-400 mr-1" />
            <span className="text-gray-600">{items.filter(i => i.availability.pos).length} Available in POS</span>
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Sales Trend</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={salesData}>
                <XAxis 
                  dataKey="date"
                  interval={0}
                  angle={dateRange !== 'today' ? -45 : 0}
                  textAnchor={dateRange !== 'today' ? 'end' : 'middle'}
                  height={60}
                />
                <YAxis />
                <Tooltip />
                <Line 
                  type="monotone" 
                  dataKey="amount" 
                  stroke={BRAND_COLORS.red}
                  strokeWidth={2}
                  dot={dateRange !== 'today'}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Order Types</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={orderTypeData}
                  cx="50%"
                  cy="50%"
                  labelLine={true}
                  label={({ name, value, percent }) => 
                    `${name} (${value} - ${(percent * 100).toFixed(0)}%)`
                  }
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {orderTypeData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">Popular Items</h2>
          </div>
          <ul className="divide-y divide-gray-200">
            {popularItems.map((item) => (
              <li key={item.id} className="px-6 py-4">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-900">{item.name}</p>
                    <p className="text-sm text-gray-500">{item.category}</p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm font-medium text-indigo-600">
                      <span className="text-brand-red-600">{formatCurrency(item.revenue)}</span>
                    </p>
                    <p className="text-sm text-gray-500">{item.orderCount} orders</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">Recent Orders</h2>
          </div>
          <ul className="divide-y divide-gray-200">
            {filteredOrders.slice(-5).map((order) => (
              <li key={order.id} className="px-6 py-4">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-900">Order #{order.id}</p>
                    <p className="text-sm text-gray-500">
                      {format(new Date(order.createdAt), 'PPp')}
                    </p>
                    <p className="text-xs text-gray-500 mt-1 capitalize">
                      Type: {order.orderType}
                      {order.description && ` • ${order.description}`}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm font-medium text-indigo-600">
                      <span className="text-brand-red-600">{formatCurrency(order.total)}</span>
                    </p>
                    <p className="text-sm text-gray-500">
                      {order.items.length} items
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}