import React, { useState } from 'react';
import { CustomerOrdersPanel } from '../components/orders/CustomerOrdersPanel';
import { DateRangeFilter } from '../components/orders/DateRangeFilter';
import { DateRangeType } from '../types';
import { SearchInput } from '../components/common/SearchInput'; 

export function CustomerOrdersManagement() {
  const [dateRange, setDateRange] = useState<DateRangeType>('today');
  const [customStartDate, setCustomStartDate] = useState<Date>(new Date());
  const [customEndDate, setCustomEndDate] = useState<Date>(new Date());
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Customer Orders Management</h1>
        <SearchInput
          value={searchTerm}
          onChange={setSearchTerm}
          placeholder="Search orders by customer name, phone..."
          className="w-64"
        />
      </div>

      <DateRangeFilter
        dateRange={dateRange}
        onDateRangeChange={setDateRange}
        customStartDate={customStartDate}
        customEndDate={customEndDate}
        onCustomStartDateChange={setCustomStartDate}
        onCustomEndDateChange={setCustomEndDate}
      />

      <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
        <div className="flex flex-wrap gap-2">
          {['all', 'pending', 'confirmed', 'preparing', 'ready', 'completed', 'cancelled'].map((status) => (
            <button
              key={status}
              onClick={() => setSelectedStatus(status)}
              className={`px-4 py-2 rounded-full text-sm font-medium capitalize ${
                selectedStatus === status
                  ? 'bg-primary-100 text-primary-800'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {status}
            </button>
          ))}
        </div>
      </div>

      <CustomerOrdersPanel
        dateRange={dateRange}
        customStartDate={customStartDate}
        customEndDate={customEndDate}
        selectedStatus={selectedStatus}
        searchTerm={searchTerm}
      />
    </div>
  );
}