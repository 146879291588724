import { supabase } from '../supabase-client';
import { AuditLog } from '../../types/auth';
import toast from 'react-hot-toast';

export async function fetchAuditLogs(
  outletId?: string,
  entityType?: string,
  startDate?: Date,
  endDate?: Date
): Promise<AuditLog[]> {
  try {
    const { data, error } = await supabase.rpc(
      'get_filtered_audit_logs',
      {
        p_outlet_id: outletId,
        p_entity_type: entityType,
        p_start_date: startDate?.toISOString(),
        p_end_date: endDate?.toISOString()
      }
    );

    if (error) throw error;

    return data.map(log => ({
      id: log.id,
      userId: log.user_id,
      userName: log.user_name,
      action: log.action,
      entityType: log.entity_type,
      entityId: log.entity_id,
      details: log.details,
      outletId: log.outlet_id,
      timestamp: new Date(log.timestamp)
    }));
  } catch (error) {
    console.error('Error fetching audit logs:', error);
    toast.error('Failed to fetch activity logs');
    throw error;
  }
}

export async function createAuditLog(
  userId: string,
  userName: string,
  action: string,
  entityType: string,
  entityId: string,
  details: string,
  outletId?: string
): Promise<void> {
  try {
    const { error } = await supabase
      .from('audit_logs')
      .insert([{
        user_id: userId,
        user_name: userName,
        action,
        entity_type: entityType,
        entity_id: entityId,
        details,
        outlet_id: outletId,
        timestamp: new Date().toISOString()
      }]);

    if (error) throw error;
  } catch (error) {
    console.error('Error creating audit log:', error);
    // Don't show toast for audit log errors to avoid disrupting user experience
  }
}