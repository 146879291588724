import React, { useState, useEffect } from 'react';
import { useInventoryStore } from '../store/useInventoryStore';
import { useOutletStore } from '../store/useOutletStore';
import { format } from 'date-fns';
import { Plus, AlertCircle, Filter, Package, ListFilter } from 'lucide-react';
import { InventoryItemModal } from '../components/inventory/InventoryItemModal';
import { InventoryTransactionModal } from '../components/inventory/InventoryTransactionModal';
import { InventoryAlerts } from '../components/inventory/InventoryAlerts';
import { InventoryList } from '../components/inventory/InventoryList';
import { InventorySummary } from '../components/inventory/InventorySummary';
import { CategoryList } from '../components/inventory/CategoryList';
import { ImportExportButtons } from '../components/inventory/ImportExportButtons';
import { CategoryModal } from '../components/inventory/CategoryModal';
import { InventoryItem, InventoryCategory } from '../types/inventory';
import { SearchInput } from '../components/common/SearchInput';
import { formatCurrency, validateImportData } from '../lib/utils';
import toast from 'react-hot-toast';

interface FilterState {
  search: string;
  category: string;
}

export function InventoryManagement() {
  const { 
    items, 
    transactions, 
    alerts, 
    categories,
    fetchItems, 
    fetchTransactions, 
    fetchAlerts,
    addItem,
    fetchCategories,
    acknowledgeAlert,
    addCategory,
    updateCategory,
    deleteCategory 
  } = useInventoryStore();
  const { currentOutlet } = useOutletStore();
  const [filters, setFilters] = useState<FilterState>({
    search: '',
    category: 'all'
  });
  const [showItemModal, setShowItemModal] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [selectedItem, setSelectedItem] = useState<InventoryItem | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<InventoryCategory | null>(null);
  const [dateRange, setDateRange] = useState({
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    end: new Date(),
  });

  useEffect(() => {
    if (currentOutlet) {
      fetchItems(currentOutlet);
      fetchTransactions(currentOutlet, undefined, dateRange.start, dateRange.end);
      fetchCategories(currentOutlet);
      fetchAlerts(currentOutlet);
    }
  }, [currentOutlet, dateRange, fetchItems, fetchTransactions, fetchAlerts]);

  const filteredItems = items.filter((item) => {
    const searchLower = filters.search.toLowerCase();
    const matchesCategory = filters.category === 'all' || item.categoryId === filters.category;
    return matchesCategory && (
      item.name.toLowerCase().includes(searchLower) ||
      item.description?.toLowerCase().includes(searchLower)
    );
  });

  const handleExport = () => {
    // TODO: Implement CSV export
    toast.success('Export feature coming soon!');
  };

  if (!currentOutlet) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <AlertCircle className="h-16 w-16 text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">No Outlet Selected</h2>
        <p className="text-gray-600 mb-4">Please select an outlet to manage inventory</p>
      </div>
    );
  }

  const totalValue = items.reduce((sum, item) => {
    const stock = item.currentStock || 0;
    const cost = item.averageCost || 0;
    return sum + (stock * cost);
  }, 0);

  const lowStockItems = items.filter(item => 
    (item.currentStock || 0) <= item.minimumStock
  ).length;

  return (
    <div className="space-y-6">
      {/* Header Section */}
      <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
          <div>
            <h1 className="text-xl font-semibold text-gray-900 flex items-center gap-3">
              <Package className="h-8 w-8 text-indigo-600" />
              Inventory Management
            </h1>
          </div>
          <div className="flex flex-wrap items-center gap-4">
            <SearchInput
              value={filters.search}
              onChange={(value) => setFilters(prev => ({ ...prev, search: value }))}
              placeholder={showCategories ? "Search categories..." : "Search items by name..."}
              className="w-64"
            />
            {!showCategories && (
              <ImportExportButtons
                items={items}
                categories={categories}
                onImport={async (importedItems) => {
                  try {
                    for (const item of importedItems) {
                      // Find category ID by name
                      const category = categories.find(c => c.name === item.Category);
                      if (!category) {
                        toast.error(`Category "${item.Category}" not found for item "${item.Name}"`);
                        continue;
                      }

                      await addItem({
                        outletId: currentOutlet,
                        name: item.Name,
                        description: item.Description,
                        categoryId: category.id,
                        unit: item.Unit,
                        minimumStock: Number(item['Minimum Stock']),
                        isActive: true,
                        purchasePattern: {
                          frequency: item['Purchase Frequency'] || 'daily',
                          days: item['Purchase Days']?.split(';').map(Number) || [],
                          averageQuantity: Number(item['Average Purchase Quantity']) || 0
                        }
                      });
                    }
                  } catch (error) {
                    console.error('Error importing items:', error);
                    toast.error('Failed to import some items');
                  }
                }}
              />
            )}
            <button
              onClick={() => setShowCategories(!showCategories)}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
            >
              <ListFilter className="h-5 w-5 mr-2" />
              {showCategories ? 'View Items' : 'Manage Categories'}
            </button>
           
            <button
              onClick={() => {
                setSelectedItem(null);
                showCategories ? setShowCategoryModal(true) : setShowItemModal(true); 
              }}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
            >
              <Plus className="h-5 w-5 mr-2" />
              Add {showCategories ? 'Category' : 'Item'}
            </button>
          </div>
        </div>
      </div>

      {/* Alerts */}
      <InventoryAlerts 
        alerts={alerts}
        onAcknowledge={acknowledgeAlert}
      />

      {/* Main Content */}
      {showCategories ? (
        <CategoryList
          categories={categories}
          className="bg-white rounded-lg shadow-sm"
          onEdit={(category) => {
            setSelectedCategory(category);
            setShowCategoryModal(true);
          }}
          onDelete={deleteCategory}
        />
      ) : (
        <>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Summary */}
          <div className="lg:col-span-1 order-2 lg:order-1">
            <InventorySummary items={items} transactions={transactions} />
          </div>

          {/* Inventory List */}
          <div className="lg:col-span-3 order-1 lg:order-2">
            <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100">
              <div className="flex flex-wrap items-center gap-4 mb-6">
                <Filter className="h-5 w-5 text-gray-400 flex-shrink-0" />
                <div className="flex flex-wrap items-center gap-4">
                  <div className="w-40">
                    <label className="block text-sm font-medium text-gray-700">Start Date</label>
                    <input
                      type="date"
                      value={format(dateRange.start, 'yyyy-MM-dd')} 
                      onChange={(e) => setDateRange(prev => ({ ...prev, start: new Date(e.target.value) }))}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="w-40">
                    <label className="block text-sm font-medium text-gray-700">End Date</label>
                    <input
                      type="date"
                      value={format(dateRange.end, 'yyyy-MM-dd')} 
                      onChange={(e) => setDateRange(prev => ({ ...prev, end: new Date(e.target.value) }))}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="w-48">
                    <label className="block text-sm font-medium text-gray-700">Category</label>
                    <select
                      value={filters.category}
                      onChange={(e) => setFilters(prev => ({ ...prev, category: e.target.value }))} 
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="all">All Categories</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <InventoryList
                items={filteredItems}
                categories={categories}
                onEdit={(item) => {
                  setSelectedItem(item);
                  setShowItemModal(true);
                }}
                onTransaction={(item) => {
                  setSelectedItem(item);
                  setShowTransactionModal(true);
                }}
              />
            </div>
          </div>
        </div>
        </>
      )}

      {/* Modals */}
      {showItemModal && (
        <InventoryItemModal
          isOpen={showItemModal}
          onClose={() => {
            setShowItemModal(false);
            setSelectedItem(null);
          }}
          item={selectedItem}
          outletId={currentOutlet}
          categories={categories}
        />
      )}

      {showTransactionModal && selectedItem && (
        <InventoryTransactionModal
          isOpen={showTransactionModal}
          onClose={() => {
            setShowTransactionModal(false);
            setSelectedItem(null);
          }}
          item={selectedItem}
          outletId={currentOutlet}
        />
      )}

      {showCategoryModal && (
        <CategoryModal
          isOpen={showCategoryModal}
          onClose={() => {
            setShowCategoryModal(false);
            setSelectedCategory(null);
          }}
          category={selectedCategory}
          onSubmit={async (data) => {
            try {
              if (selectedCategory) {
                await updateCategory(selectedCategory.id, {
                  name: data.name,
                  description: data.description,
                  isActive: data.isActive
                });
              } else {
                await addCategory(currentOutlet, data.name, data.description);
              }
              setShowCategoryModal(false);
              setSelectedCategory(null);
            } catch (error) {
              console.error('Error saving category:', error);
            }
          }}
        />
      )}
    </div>
  );
}