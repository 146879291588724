import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useInventoryStore } from '../../store/useInventoryStore';
import { InventoryItem } from '../../types/inventory';
import { formatCurrency } from '../../lib/utils';
import toast from 'react-hot-toast';

interface InventoryTransactionModalProps {
  isOpen: boolean;
  onClose: () => void;
  item: InventoryItem;
  outletId: string;
}

export function InventoryTransactionModal({ isOpen, onClose, item, outletId }: InventoryTransactionModalProps) {
  const { addTransaction } = useInventoryStore();
  const [formData, setFormData] = useState({
    type: 'inward',
    quantity: '',
    priceType: 'unit', // 'unit' or 'total'
    unitPrice: '',
    totalPrice: '',
    referenceNumber: '',
    supplier: '',
    notes: '',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const quantity = parseFloat(formData.quantity);
      if (quantity <= 0) {
        toast.error('Quantity must be greater than 0');
        return;
      }
      
      let unitPrice;
      if (formData.type === 'inward') {
        if (formData.priceType === 'unit') {
          unitPrice = formData.unitPrice ? parseFloat(formData.unitPrice) : undefined;
        } else {
          // Calculate unit price from total price
          const totalPrice = parseFloat(formData.totalPrice);
          unitPrice = totalPrice / quantity;
        }
      }

      if (formData.type === 'outward' && quantity > (item.currentStock || 0)) {
        toast.error(`Cannot remove more than current stock (${item.currentStock} ${item.unit})`);
        return;
      }

      if (formData.type === 'inward' && !unitPrice) {
        toast.error('Unit price is required for stock in');
        return;
      }

      await addTransaction({
        outletId,
        itemId: item.id,
        type: formData.type as 'inward' | 'outward',
        quantity,
        unitPrice: unitPrice,
        totalPrice: unitPrice ? unitPrice * quantity : undefined,
        referenceNumber: formData.referenceNumber || undefined,
        supplier: formData.supplier || undefined,
        notes: formData.notes || undefined,
      });

      onClose();
    } catch (error) {
      console.error('Error creating transaction:', error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Failed to create transaction');
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Record Transaction</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="mb-4">
          <h3 className="font-medium text-gray-900">{item.name}</h3>
          <p className="text-sm text-gray-500">
            Current Stock: {item.currentStock || 0} {item.unit}
            {item.averageCost && ` • Avg. Cost: ${formatCurrency(item.averageCost)}`}
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Transaction Type</label>
            <select
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            >
              <option value="inward">Stock In</option>
              <option value="outward">Stock Out</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Quantity ({item.unit})</label>
            <input
              type="number"
              placeholder={`Available: ${item.currentStock} ${item.unit}`}
              value={formData.quantity}
              onChange={(e) => {
                const newQuantity = e.target.value;
                setFormData(prev => {
                  // If using total price, update unit price
                  if (prev.priceType === 'total' && prev.totalPrice) {
                    const total = parseFloat(prev.totalPrice);
                    const qty = parseFloat(newQuantity);
                    return {
                      ...prev,
                      quantity: newQuantity,
                      unitPrice: qty > 0 ? (total / qty).toString() : ''
                    };
                  }
                  return { ...prev, quantity: newQuantity };
                });
              }}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              min="0"
              step="0.01"
              required
            />
          </div>

          {formData.type === 'inward' && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Price Input Type</label>
                <div className="flex gap-4 mb-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="unit"
                      checked={formData.priceType === 'unit'}
                      onChange={(e) => setFormData({ ...formData, priceType: e.target.value })}
                      className="form-radio h-4 w-4 text-indigo-600"
                    />
                    <span className="ml-2">Unit Price</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="total"
                      checked={formData.priceType === 'total'}
                      onChange={(e) => setFormData({ ...formData, priceType: e.target.value })}
                      className="form-radio h-4 w-4 text-indigo-600"
                    />
                    <span className="ml-2">Total Price</span>
                  </label>
                </div>

                {formData.priceType === 'unit' ? (
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Price per {item.unit}</label>
                    <input
                      type="number"
                      value={formData.unitPrice}
                      onChange={(e) => setFormData({ ...formData, unitPrice: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      required
                      min="0"
                      step="0.01"
                    />
                    {formData.quantity && formData.unitPrice && (
                      <p className="mt-1 text-sm text-gray-500">
                        Total: {formatCurrency(parseFloat(formData.quantity) * parseFloat(formData.unitPrice))}
                      </p>
                    )}
                  </div>
                ) : (
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Total Price</label>
                    <input
                      type="number"
                      value={formData.totalPrice}
                      onChange={(e) => {
                        const newTotal = e.target.value;
                        setFormData(prev => {
                          const qty = parseFloat(prev.quantity);
                          return {
                            ...prev,
                            totalPrice: newTotal,
                            unitPrice: qty > 0 ? (parseFloat(newTotal) / qty).toString() : ''
                          };
                        });
                      }}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      required
                      min="0"
                      step="0.01"
                    />
                    {formData.quantity && formData.totalPrice && (
                      <p className="mt-1 text-sm text-gray-500">
                        Price per {item.unit}: {formatCurrency(parseFloat(formData.totalPrice) / parseFloat(formData.quantity))}
                      </p>
                    )}
                  </div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Supplier</label>
                <input
                  type="text"
                  value={formData.supplier}
                  onChange={(e) => setFormData({ ...formData, supplier: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">Reference Number</label>
            <input
              type="text"
              value={formData.referenceNumber}
              onChange={(e) => setFormData({ ...formData, referenceNumber: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Notes</label>
            <textarea
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Record Transaction
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}