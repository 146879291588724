import React from 'react';
import { format } from 'date-fns';
import { Calendar } from 'lucide-react';
import { DateRangeType } from '../../types';

interface DateRangeFilterProps {
  dateRange: DateRangeType;
  onDateRangeChange: (range: DateRangeType) => void;
  customStartDate: Date;
  customEndDate: Date;
  onCustomStartDateChange: (date: Date) => void;
  onCustomEndDateChange: (date: Date) => void;
}

export function DateRangeFilter({
  dateRange,
  onDateRangeChange,
  customStartDate,
  customEndDate,
  onCustomStartDateChange,
  onCustomEndDateChange,
}: DateRangeFilterProps) {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm">
      <div className="flex items-center space-x-4">
        <Calendar className="h-5 w-5 text-gray-400" />
        <div className="flex flex-wrap gap-4">
          <select
            value={dateRange}
            onChange={(e) => onDateRangeChange(e.target.value as DateRangeType)}
            className="border rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="thisWeek">This Week</option>
            <option value="lastWeek">Last Week</option>
            <option value="thisMonth">This Month</option>
            <option value="lastMonth">Last Month</option>
            <option value="custom">Custom Range</option>
          </select>

          {dateRange === 'custom' && (
            <div className="flex items-center gap-2">
              <input
                type="date"
                value={format(customStartDate, 'yyyy-MM-dd')}
                onChange={(e) => onCustomStartDateChange(new Date(e.target.value))}
                className="border rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <span className="text-gray-500">to</span>
              <input
                type="date"
                value={format(customEndDate, 'yyyy-MM-dd')}
                onChange={(e) => onCustomEndDateChange(new Date(e.target.value))}
                className="border rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}