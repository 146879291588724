import { create } from 'zustand';
import { Outlet } from '../types';
import { fetchOutlets, createOutlet, updateOutlet, deleteOutlet } from '../lib/api/outlets';
import toast from 'react-hot-toast';

interface OutletStore {
  outlets: Outlet[];
  currentOutlet: string | null;
  isLoading: boolean;
  error: Error | null;
  fetchOutlets: () => Promise<void>;
  addOutlet: (outlet: Omit<Outlet, 'id'>) => Promise<void>;
  updateOutlet: (id: string, updates: Partial<Outlet>) => Promise<void>;
  deleteOutlet: (id: string) => Promise<void>;
  setCurrentOutlet: (id: string) => void;
}

export const useOutletStore = create<OutletStore>((set) => ({
  outlets: [],
  currentOutlet: localStorage.getItem('currentOutlet'),
  isLoading: false,
  error: null,

  fetchOutlets: async () => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchOutlets();
      set({ outlets: data, isLoading: false });

      // If there's no current outlet selected but we have outlets, select the first one
      const currentOutlet = localStorage.getItem('currentOutlet');
      if (!currentOutlet && data.length > 0) {
        localStorage.setItem('currentOutlet', data[0].id);
        set({ currentOutlet: data[0].id });
      }
      
      return data;
    } catch (error) {
      console.error('Error fetching outlets:', error);
      set({ error: error as Error, isLoading: false });
      toast.error('Failed to fetch outlets');
      throw error;
    }
  },

  // Rest of the store implementation remains the same
}));