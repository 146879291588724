import React, { useState, useEffect } from 'react';
import { Order, Payment, PaymentMethod } from '../../types';
import { useOutletStore } from '../../store/useOutletStore';
import { formatCurrency } from '../../lib/utils';
import { X, CreditCard, Wallet, IndianRupee } from 'lucide-react';
import QRCode from 'qrcode.react';

interface PaymentModalProps {
  order: Order;
  onClose: () => void;
  onPaymentComplete: (payments: Payment[]) => void;
}

export function PaymentModal({ order, onClose, onPaymentComplete }: PaymentModalProps) {
  const { outlets } = useOutletStore();
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethod>('cash');
  const [tenderAmount, setTenderAmount] = useState('');
  const [upiId, setUpiId] = useState('');
  const [cardType, setCardType] = useState<'credit' | 'debit'>('credit');
  const [cardNumber, setCardNumber] = useState('');
  const [splitPayment, setSplitPayment] = useState(false);
  const [splitAmount, setSplitAmount] = useState('');

  // Auto-fetch UPI ID from outlet when UPI is selected
  useEffect(() => {
    if (selectedMethod === 'upi' && order.outletId) {
      const outlet = outlets.find(o => o.id === order.outletId);
      if (outlet?.upiId) {
        setUpiId(outlet.upiId);
      }
    }
  }, [selectedMethod, order.outletId, outlets]);

  const remainingAmount = splitPayment
    ? order.total - Number(splitAmount)
    : order.total;

  // Quick amount suggestions for cash payments
  const getQuickAmounts = () => {
    const amount = splitPayment ? Number(splitAmount) : order.total;
    // Add actual bill amount as first option
    return [
      amount, // Actual bill amount
      Math.ceil(amount / 10) * 10, // Round to nearest 10
      Math.ceil(amount / 100) * 100, // Round to nearest 100
      Math.ceil(amount / 100) * 100 + 100, // Next 100
    ];
  };

  const handlePayment = () => {
    const payments: Payment[] = [];
    const timestamp = new Date();

    if (splitPayment && Number(splitAmount) > 0) {
      // First payment
      payments.push({
        id: crypto.randomUUID(),
        amount: Number(splitAmount),
        method: selectedMethod,
        status: 'completed',
        timestamp,
        ...(selectedMethod === 'cash' && {
          tenderAmount: Number(tenderAmount),
          changeAmount: Number(tenderAmount) - Number(splitAmount),
        }),
        ...(selectedMethod === 'upi' && { upiId }),
        ...(selectedMethod === 'card' && {
          cardType,
          cardLastDigits: cardNumber.slice(-4),
        }),
      });

      // Second payment
      payments.push({
        id: crypto.randomUUID(),
        amount: remainingAmount,
        method: 'cash', // Default to cash for remaining amount
        status: 'completed',
        timestamp,
        tenderAmount: remainingAmount,
        changeAmount: 0,
      });
    } else {
      // Single payment
      payments.push({
        id: crypto.randomUUID(),
        amount: order.total,
        method: selectedMethod,
        status: 'completed',
        timestamp,
        ...(selectedMethod === 'cash' && {
          tenderAmount: Number(tenderAmount),
          changeAmount: Number(tenderAmount) - order.total,
        }),
        ...(selectedMethod === 'upi' && { upiId }),
        ...(selectedMethod === 'card' && {
          cardType,
          cardLastDigits: cardNumber.slice(-4),
        }),
      });
    }

    onPaymentComplete(payments);
  };

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl shadow-xl w-full max-w-md max-h-[90vh] overflow-y-auto mx-4">
        <div className="flex justify-between items-center mb-6">
          <div className="p-6 pb-0">
            <h2 className="text-2xl font-semibold text-gray-900">Complete Payment</h2>
            <p className="text-sm text-gray-500 mt-1">Order #{order.id}</p>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 mr-4">
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6 pt-0 space-y-6">
          <div>
            <div className="bg-gray-50 rounded-xl p-4 space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-600">Bill Amount</span>
                <span>{formatCurrency(order.subtotal)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">GST</span>
                <span>{formatCurrency(order.gstAmount)}</span>
              </div>
              {order.packageCharge > 0 && (
                <div className="flex justify-between">
                  <span className="text-gray-600">Package Charge</span>
                  <span>{formatCurrency(order.packageCharge)}</span>
                </div>
              )}
              {order.discount && (
                <div className="flex justify-between text-red-600">
                  <span>Discount</span>
                  <span>-{formatCurrency(order.discount.amount)}</span>
                </div>
              )}
              <div className="flex justify-between font-bold text-lg border-t border-gray-200 pt-3 mt-3">
                <span>Total</span>
                <span>{formatCurrency(order.total)}</span>
              </div>
            </div>
          </div>

          <div>
            <label className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={splitPayment}
                onChange={(e) => setSplitPayment(e.target.checked)}
                className="mr-2"
              />
              Split Payment
            </label>

            {splitPayment && (
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">
                  First Payment Amount
                </label>
                <input
                  type="number"
                  value={splitAmount}
                  onChange={(e) => setSplitAmount(e.target.value)}
                  className="w-full border rounded-md p-2"
                  max={order.total}
                />
              </div>
            )}
          </div>

          <div className="grid grid-cols-3 gap-4">
            <button
              onClick={() => setSelectedMethod('cash')}
              className={`p-4 border rounded-lg flex flex-col items-center transition-colors ${
                selectedMethod === 'cash' ? 'border-brand-red-500 bg-brand-red-50 text-brand-red-700' : 'hover:bg-gray-50'
              }`}
            >
              <IndianRupee className="h-6 w-6 mb-2" />
              <span>Cash</span>
            </button>
            <button
              onClick={() => setSelectedMethod('card')}
              className={`p-4 border rounded-lg flex flex-col items-center transition-colors ${
                selectedMethod === 'card' ? 'border-brand-red-500 bg-brand-red-50 text-brand-red-700' : 'hover:bg-gray-50'
              }`}
            >
              <CreditCard className="h-6 w-6 mb-2" />
              <span>Card</span>
            </button>
            <button
              onClick={() => setSelectedMethod('upi')}
              className={`p-4 border rounded-lg flex flex-col items-center transition-colors ${
                selectedMethod === 'upi' ? 'border-brand-red-500 bg-brand-red-50 text-brand-red-700' : 'hover:bg-gray-50'
              }`}
            >
              <Wallet className="h-6 w-6 mb-2" />
              <span>UPI</span>
            </button>
          </div>

          {selectedMethod === 'cash' && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-3">
                Quick Amount
              </label>
              <div className="grid grid-cols-2 gap-3 mb-4">
                {getQuickAmounts().map((amount) => (
                  <button
                    key={amount}
                    onClick={() => setTenderAmount(amount.toString())}
                    className={`p-3 border rounded-xl text-center font-medium transition-colors ${
                      amount === order.total 
                        ? 'bg-brand-red-50 border-brand-red-200 text-brand-red-700'
                        : 'border-gray-200 hover:bg-gray-50'
                    }`}
                  >
                    {formatCurrency(amount)}
                  </button>
                ))}
              </div>
              <label className="block text-sm font-medium mb-1">
                Tender Amount
              </label>
              <input
                type="number"
                value={tenderAmount}
                onChange={(e) => setTenderAmount(e.target.value)}
                className="w-full border rounded-md p-2"
              />
              {Number(tenderAmount) > 0 && (
                <div className="mt-2 text-sm">
                  Change: {formatCurrency(Number(tenderAmount) - (splitPayment ? Number(splitAmount) : order.total))}
                </div>
              )}
            </div>
          )}

          {selectedMethod === 'card' && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">
                  Card Type
                </label>
                <select
                  value={cardType}
                  onChange={(e) => setCardType(e.target.value as 'credit' | 'debit')}
                  className="w-full border rounded-md p-2"
                >
                  <option value="credit">Credit Card</option>
                  <option value="debit">Debit Card</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Card Number
                </label>
                <input
                  type="text"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                  maxLength={16}
                  className="w-full border rounded-md p-2"
                  placeholder="XXXX XXXX XXXX XXXX"
                />
              </div>
            </div>
          )}

          {selectedMethod === 'upi' && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Store UPI ID</label>
                <input
                  type="text"
                  value={upiId}
                  readOnly
                  className="w-full border rounded-md p-2"
                />
              </div>
              <div className="flex justify-center">
                <QRCode value={`upi://pay?pa=${upiId}&am=${splitPayment ? splitAmount : order.total}`} />
              </div>
            </div>
          )}

          <button
            onClick={handlePayment}
            disabled={
              (selectedMethod === 'cash' && !tenderAmount) ||
              (selectedMethod === 'card' && !cardNumber) ||
              (selectedMethod === 'upi' && !upiId)
            }
            className="w-full bg-brand-red-600 text-white py-3 rounded-lg font-medium hover:bg-brand-red-700 transition-colors disabled:bg-gray-400"
          >
            Complete Payment
          </button>
        </div>
      </div>
    </div>
  );
}