import { supabase } from '../supabase-client';
import { InventoryCategory } from '../../types/inventory';
import toast from 'react-hot-toast';

export async function fetchInventoryCategories(outletId: string) {
  try {
    const { data, error } = await supabase
      .from('inventory_categories')
      .select('*')
      .eq('outlet_id', outletId)
      .eq('is_active', true)
      .order('sort_order');

    if (error) throw error;
    
    return data.map(category => ({
      id: category.id,
      outletId: category.outlet_id,
      name: category.name,
      description: category.description,
      isActive: category.is_active,
      sortOrder: category.sort_order,
      createdAt: new Date(category.created_at),
      updatedAt: new Date(category.updated_at)
    }));
  } catch (error) {
    console.error('Error fetching inventory categories:', error);
    toast.error('Failed to fetch inventory categories');
    throw error;
  }
}

export async function createInventoryCategory(outletId: string, name: string, description?: string) {
  try {
    const { data, error } = await supabase
      .from('inventory_categories')
      .insert([{
        outlet_id: outletId,
        name,
        description,
        is_active: true,
        sort_order: 0,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;
    
    toast.success('Category created successfully');
    return {
      id: data.id,
      outletId: data.outlet_id,
      name: data.name,
      description: data.description,
      isActive: data.is_active,
      sortOrder: data.sort_order,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at)
    };
  } catch (error) {
    console.error('Error creating inventory category:', error);
    toast.error('Failed to create category');
    throw error;
  }
}

export async function updateInventoryCategory(id: string, updates: { name?: string; description?: string; isActive?: boolean }) {
  try {
    const updateData = {
      ...(updates.name && { name: updates.name }),
      ...(updates.description !== undefined && { description: updates.description }),
      ...(updates.isActive !== undefined && { is_active: updates.isActive }),
      updated_at: new Date().toISOString()
    };

    const { data, error } = await supabase
      .from('inventory_categories')
      .update(updateData)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    
    toast.success('Category updated successfully');
    return {
      id: data.id,
      outletId: data.outlet_id,
      name: data.name,
      description: data.description,
      isActive: data.is_active,
      sortOrder: data.sort_order,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at)
    };
  } catch (error) {
    console.error('Error updating inventory category:', error);
    toast.error('Failed to update category');
    throw error;
  }
}

export async function deleteInventoryCategory(id: string) {
  try {
    const { error } = await supabase
      .from('inventory_categories')
      .update({ is_active: false })
      .eq('id', id);

    if (error) throw error;
    toast.success('Category deleted successfully');
  } catch (error) {
    console.error('Error deleting inventory category:', error);
    toast.error('Failed to delete category');
    throw error;
  }
}