import { supabase } from '../supabase-client';
import toast from 'react-hot-toast';

export interface Category {
  id: string;
  name: string;
  description?: string;
  sortOrder: number;
  isActive: boolean;
}

export async function fetchCategories(outletId: string) {
  try {
    let { data, error } = await supabase
      .from('categories')
      .select('*')
      .eq('outlet_id', outletId)
      .eq('is_active', true)
      .order('sort_order');

    if (error) throw error;
    
    // If no categories found, provide defaults
    if (!data || data.length === 0) {
      data = [
        {
          id: '1',
          outlet_id: outletId,
          name: 'Biryani',
          description: 'Authentic biryanis',
          sort_order: 1,
          is_active: true,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        },
        {
          id: '2',
          outlet_id: outletId,
          name: 'Starters',
          description: 'Appetizers and snacks',
          sort_order: 2,
          is_active: true,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }
      ];
    }

    return data.map(category => ({
      id: category.id,
      name: category.name,
      description: category.description,
      sortOrder: category.sort_order,
      isActive: category.is_active
    })) || [];
  } catch (error) {
    console.error('Error fetching categories:', error);
    toast.error('Failed to fetch categories');
    throw error;
  }
}

export async function createCategory(outletId: string, name: string, description?: string) {
  try {
    const { data, error } = await supabase
      .from('categories')
      .insert([{
        outlet_id: outletId,
        name,
        description,
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;
    toast.success('Category created successfully');
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      sortOrder: data.sort_order,
      isActive: data.is_active
    };
  } catch (error) {
    console.error('Error creating category:', error);
    toast.error('Failed to create category');
    throw error;
  }
}

export async function updateCategory(id: string, updates: { name?: string; description?: string; isActive?: boolean }) {
  try {
    const { data, error } = await supabase
      .from('categories')
      .update({
        name: updates.name,
        description: updates.description,
        is_active: updates.isActive,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    toast.success('Category updated successfully');
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      sortOrder: data.sort_order,
      isActive: data.is_active
    };
  } catch (error) {
    console.error('Error updating category:', error);
    toast.error('Failed to update category');
    throw error;
  }
}

export async function deleteCategory(id: string) {
  try {
    const { error } = await supabase
      .from('categories')
      .update({ is_active: false })
      .eq('id', id);

    if (error) throw error;
    toast.success('Category deleted successfully');
  } catch (error) {
    console.error('Error deleting category:', error);
    toast.error('Failed to delete category');
    throw error;
  }
}