import { supabase } from '../supabase-client';
import { CustomerOrder } from '../../types/order';
import toast from 'react-hot-toast';

export async function fetchCustomerOrders(customerId: string) {
  try {
    const { data, error } = await supabase
      .from('customer_orders')
      .select('*')
      .eq('customer_id', customerId)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching customer orders:', error);
    toast.error('Failed to fetch orders');
    throw error;
  }
}

export async function createCustomerOrder(order: Omit<CustomerOrder, 'id' | 'created_at' | 'updated_at'>) {
  try {
    const { data, error } = await supabase
      .from('customer_orders')
      .insert([{
        customer_id: order.customerId,
        customer_name: order.customerName,
        customer_phone: order.customerPhone,
        customer_address: order.customerAddress,
        outlet_id: order.outletId,
        items: order.items,
        subtotal: order.subtotal,
        gst_amount: order.gstAmount,
        total: order.total,
        status: order.status || 'pending',
        delivery_type: order.deliveryType,
        delivery_status: order.deliveryStatus || 'pending',
        payment_status: order.paymentStatus || 'pending',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;
    toast.success('Order placed successfully');
    return data;
  } catch (error) {
    console.error('Error creating order:', error);
    toast.error('Failed to place order');
    throw error;
  }
}

export async function updateCustomerOrder(id: string, updates: Partial<CustomerOrder>) {
  try {
    const { data, error } = await supabase
      .from('customer_orders')
      .update({
        status: updates.status,
        delivery_status: updates.deliveryStatus,
        payment_status: updates.paymentStatus,
        razorpay_order_id: updates.razorpayOrderId,
        razorpay_payment_id: updates.razorpayPaymentId,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating order:', error);
    toast.error('Failed to update order');
    throw error;
  }
}