import React, { useState, useEffect } from 'react';
import { Order, OrderItem, Payment, DiscountType } from '../../types';
import { formatCurrency } from '../../lib/utils';
import { Trash2, Plus, Minus, Receipt, Tag, FileText } from 'lucide-react';
import toast from 'react-hot-toast';

interface OrderSummaryProps {
  order: Order;
  onRemoveItem: (index: number) => void;
  onUpdateQuantity: (index: number, quantity: number) => void;
  onPaymentComplete: (payments: Payment[]) => void;
  tableNumber: number;
  onTableChange: (value: number) => void;
  updateOrderTotals?: (items: OrderItem[]) => void;
  orderType: 'regular' | 'party' | 'daily';
  onOrderTypeChange: (type: 'regular' | 'party' | 'daily') => void;
  orderDescription?: string;
  onOrderDescriptionChange?: (description: string) => void;
  setShowPaymentModal: (show: boolean) => void;
}

export function OrderSummary({
  order,
  onRemoveItem,
  onUpdateQuantity,
  tableNumber,
  onTableChange,
  orderType,
  onOrderTypeChange,
  orderDescription = '',
  onOrderDescriptionChange,
  setShowPaymentModal,
  updateOrderTotals,
}: OrderSummaryProps) {
  const [discountType, setDiscountType] = useState<DiscountType>('percentage');
  const [discountValue, setDiscountValue] = useState('');
  const [packageCharge, setPackageCharge] = useState(0);
  const [currentOrder, setCurrentOrder] = useState<Order>(order);
  const [editingItemIndex, setEditingItemIndex] = useState<number | null>(null);

  const handleTableChange = (value: number) => {
    setTableNumber(value);
    setCurrentOrder(prev => ({ ...prev, table_number: value }));
  };

  const handleApplyDiscount = () => {
    const value = Number(discountValue);
    if (!value || value <= 0) {
      toast.error('Please enter a valid discount value');
      return;
    }

    if (discountType === 'percentage' && value > 100) {
      toast.error('Percentage discount cannot exceed 100%');
      return;
    }

    if (discountType === 'amount' && value > currentOrder.subtotal) {
      toast.error('Discount amount cannot exceed subtotal');
      return;
    }

    const discountAmount = discountType === 'percentage' 
      ? (currentOrder.subtotal * value) / 100
      : value;
    
    const total = currentOrder.subtotal + currentOrder.gstAmount + packageCharge - discountAmount;

    // Create new order state with discount
    const updatedOrder = {
      ...currentOrder,
      discount: {
        type: discountType,
        value,
        amount: discountAmount
      },
      total,
      packageCharge
    };

    setCurrentOrder(updatedOrder);
    order.discount = updatedOrder.discount;
    order.total = total;
    order.packageCharge = packageCharge;

    // Clear discount input
    setDiscountValue('');
    toast.success('Discount applied successfully');
  };

  const handlePackageChargeChange = (value: string) => {
    const charge = parseFloat(value) || 0;
    setPackageCharge(charge);
    
    // Calculate new total
    const total = currentOrder.subtotal + currentOrder.gstAmount + charge - (currentOrder.discount?.amount || 0);
    
    // Create new order state with updated package charge
    const updatedOrder = {
      ...currentOrder,
      packageCharge: charge,
      total
    };
    
    setCurrentOrder(updatedOrder);
    order.packageCharge = charge;
    order.total = updatedOrder.total;
  };

  const handleCustomPriceChange = (index: number, newPrice: number) => {
    const updatedItems = [...currentOrder.items];
    const item = updatedItems[index];
    if (!item) return;

    item.customPrice = newPrice;
    item.baseAmount = newPrice * item.quantity;
    item.gstAmount = (item.baseAmount * item.menuItem.gstRate) / 100;
    item.totalAmount = item.baseAmount + item.gstAmount;
    if (updateOrderTotals) {
      updateOrderTotals(updatedItems);
    }
  };

  // Reset form when order changes
  useEffect(() => {
    setCurrentOrder(order);
    setDiscountValue('');
    setPackageCharge(order.packageCharge);
  }, [order]);
  return (
    <div className="flex flex-col h-full max-h-full">
      {/* Header */}
      <div className="flex-none p-4 border-b">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-lg font-semibold text-brand-green-500">Current Order</h2>
          <div className="flex items-center gap-4">
            <select
              value={orderType}
              onChange={(e) => onOrderTypeChange(e.target.value as 'regular' | 'party' | 'daily')}
              className="px-3 py-1 border rounded-lg text-sm focus:ring-2 focus:ring-brand-red-500 focus:border-brand-red-500"
            >
              <option value="regular">Regular</option>
              <option value="party">Party</option>
              <option value="daily">Daily</option>
            </select>
            <span className="text-sm text-gray-500">Table</span>
            <input
              type="number"
              min="1"
              value={tableNumber}
              onChange={(e) => onTableChange(parseInt(e.target.value))}
              className="w-16 px-2 py-1 border rounded-lg text-center focus:ring-2 focus:ring-brand-red-500 focus:border-brand-red-500"
            />
          </div>
        </div>
        {(orderType === 'party' || orderType === 'daily') && (
          <div className="mt-4">
            <div className="flex items-center gap-2 mb-2">
              <FileText className="h-4 w-4 text-gray-500" />
              <label className="text-sm font-medium text-gray-700">
                {orderType === 'party' ? 'Party Details' : 'Daily Order Notes'}
              </label>
            </div>
            <textarea
              value={orderDescription}
              onChange={(e) => onOrderDescriptionChange?.(e.target.value)}
              placeholder={orderType === 'party' 
                ? "Enter party details (e.g., event type, special requirements)"
                : "Enter daily order notes (e.g., delivery time, special instructions)"
              }
              className="w-full px-3 py-2 border rounded-lg text-sm resize-none focus:ring-2 focus:ring-brand-red-500 focus:border-brand-red-500"
              rows={2}
            />
          </div>
        )}
        <div className="text-sm text-gray-500">
          Order #{order.id}
        </div>
      </div>

      {/* Order Items */}
      <div className="flex-1 overflow-y-auto p-4">
        {order.items.length === 0 ? (
          <div className="text-center text-gray-500 py-8">
            No items in order
          </div>
        ) : (
          <div className="space-y-4">
            {order.items.map((item, index) => (
              <div key={index} className="flex items-start gap-3 bg-gray-50 p-3 rounded-lg">
                <div className="flex-1 min-w-0">
                  <div className="flex justify-between items-start">
                    <h3 className="font-medium text-gray-900 truncate">{item.menuItem.name}</h3>
                    <button
                      onClick={() => onRemoveItem(index)}
                      className="text-red-500 hover:text-red-600 p-1"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                  <div className="mt-2 flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => onUpdateQuantity(index, item.quantity - 1)}
                        className="w-8 h-8 flex items-center justify-center rounded-full border hover:bg-gray-100"
                      >
                        <Minus className="h-4 w-4" />
                      </button>
                      <span className="w-8 text-center">{item.quantity}</span>
                      <button
                        onClick={() => onUpdateQuantity(index, item.quantity + 1)}
                        className="w-8 h-8 flex items-center justify-center rounded-full border hover:bg-gray-100"
                      >
                        <Plus className="h-4 w-4" />
                      </button>
                    </div>
                    <div className="text-right">
                      <p className="font-medium">{formatCurrency(item.totalAmount)}</p>
                      <p className="text-xs text-gray-500">
                        {orderType !== 'regular' && (
                          <button
                            onClick={() => setEditingItemIndex(index)}
                            className="text-indigo-600 hover:text-indigo-800"
                          >
                            Edit Price
                          </button>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {editingItemIndex !== null && (
              <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
                <div className="bg-white p-6 rounded-lg w-96">
                  <h3 className="text-lg font-medium mb-4">Edit Price</h3>
                  <input
                    type="number"
                    value={currentOrder.items[editingItemIndex].customPrice || ''}
                    onChange={(e) => {
                      const newPrice = parseFloat(e.target.value);
                      const updatedItems = [...currentOrder.items];
                      const item = updatedItems[editingItemIndex];
                      item.customPrice = newPrice;
                      item.baseAmount = newPrice * item.quantity;
                      item.gstAmount = (item.baseAmount * item.menuItem.gstRate) / 100;
                      item.totalAmount = item.baseAmount + item.gstAmount;
                      updateOrderTotals(updatedItems);
                    }}
                    className="w-full border rounded p-2 mb-4"
                    placeholder="Enter custom price"
                    min="0"
                    step="0.01"
                  />
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={() => setEditingItemIndex(null)}
                      className="px-4 py-2 border rounded"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => setEditingItemIndex(null)}
                      className="px-4 py-2 bg-indigo-600 text-white rounded"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Order Summary */}
      <div className="flex-none border-t p-4 bg-gray-50">
        {/* Discount Section */}
        {order.items.length > 0 && (
          <div className="mb-4">
            <div className="flex items-center gap-2 mb-2">
              <Tag className="h-4 w-4 text-gray-500" />
              <span className="text-sm font-medium text-gray-700">Apply Discount</span>
            </div>
            <div className="flex gap-2">
              <select
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value as DiscountType)}
                className="flex-none w-32 text-sm border rounded px-2 py-1.5"
              >
                <option value="percentage">Percentage</option>
                <option value="amount">Amount</option>
              </select>
              <input
                type="number"
                value={discountValue}
                onChange={(e) => setDiscountValue(e.target.value)}
                placeholder={discountType === 'percentage' ? 'Enter %' : 'Enter amount'}
                className="flex-1 text-sm border rounded px-2 py-1.5"
              />
              <button
                onClick={handleApplyDiscount}
                className="flex-none px-3 py-1.5 bg-gray-200 text-gray-700 rounded text-sm hover:bg-gray-300"
              >
                Apply
              </button>
            </div>
          </div>
        )}

        {/* Totals */}
        <div className="space-y-2 mb-4">
          <div className="flex justify-between text-sm">
            <span>Subtotal</span>
            <span>{formatCurrency(currentOrder.subtotal)}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span>GST</span>
            <span>{formatCurrency(currentOrder.gstAmount)}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span>Package Charge</span>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                step="0.01"
                value={packageCharge}
                onChange={(e) => handlePackageChargeChange(e.target.value)}
                className="w-20 px-2 py-1 text-right border rounded focus:outline-none focus:ring-1 focus:ring-brand-red-500"
              />
            </div>
          </div>
          {currentOrder.discount && (
            <div className="flex justify-between text-sm text-red-600">
              <span>Discount ({currentOrder.discount.type === 'percentage' ? `${currentOrder.discount.value}%` : 'amount'})</span>
              <span>-{formatCurrency(currentOrder.discount.amount)}</span>
            </div>
          )}
          <div className="flex justify-between font-medium text-lg pt-2 border-t">
            <span>Total</span>
            <span>{formatCurrency(currentOrder.total)}</span>
          </div>
        </div>

        <button
          onClick={() => {
            if (order.items.length === 0) {
              toast.error('Please add items to the order');
              return;
            }
            setShowPaymentModal(true);
          }}
          className="w-full bg-brand-red-600 text-white py-3 rounded-lg font-medium hover:bg-brand-red-700 disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center justify-center"
          disabled={order.items.length === 0}
        >
          <Receipt className="h-5 w-5 mr-2" />
          Process Payment
        </button>
      </div>
    </div>
  );
}