import React, { useState, useEffect } from 'react';
import { useExpenseStore } from '../store/useExpenseStore';
import { useOutletStore } from '../store/useOutletStore';
import { format } from 'date-fns';
import { Plus, AlertCircle, Filter, Download, Settings, ListFilter, DollarSign } from 'lucide-react';
import { ExpenseModal } from '../components/expense/ExpenseModal';
import { ExpenseCategoryModal } from '../components/expense/ExpenseCategoryModal';
import { ExpenseCategoryList } from '../components/expense/ExpenseCategoryList';
import { ExpenseList } from '../components/expense/ExpenseList';
import { ExpenseSummary } from '../components/expense/ExpenseSummary';
import { ExpenseCategory } from '../types/expense';
import { formatCurrency, sortByDate } from '../lib/utils';
import toast from 'react-hot-toast';
import { SearchInput } from '../components/common/SearchInput';

type SortField = 'date' | 'category' | 'amount';
type SortOrder = 'asc' | 'desc';

export function ExpenseManagement() {
  const { expenses, categories, fetchExpenses, fetchCategories } = useExpenseStore();
  const { currentOutlet } = useOutletStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState<ExpenseCategory | null>(null);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState<string>('all');
  const [showCategories, setShowCategories] = useState(false);
  const [sortConfig, setSortConfig] = useState<{ field: SortField; order: SortOrder }>({
    field: 'date',
    order: 'desc'
  });
  const [dateRange, setDateRange] = useState({
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    end: new Date(),
  });

  useEffect(() => {
    if (currentOutlet) {
      fetchCategories(currentOutlet);
      fetchExpenses(currentOutlet, dateRange.start, dateRange.end);
    }
  }, [currentOutlet, dateRange]);

  const sortedAndFilteredExpenses = expenses.filter(expense => {
    const searchLower = searchTerm.toLowerCase();
    const categoryMatch = selectedCategoryFilter === 'all' || expense.categoryId === selectedCategoryFilter;
    const searchMatch = 
      expense.description?.toLowerCase().includes(searchLower) ||
      expense.category?.name.toLowerCase().includes(searchLower) ||
      expense.referenceNumber?.toLowerCase().includes(searchLower) ||
      expense.createdByName?.toLowerCase().includes(searchLower);
      
    return categoryMatch && searchMatch;
  }).sort((a, b) => {
    const { field, order } = sortConfig;
    const multiplier = order === 'desc' ? -1 : 1;

    switch (field) {
      case 'date':
        return multiplier * (new Date(b.date).getTime() - new Date(a.date).getTime());
      case 'category':
        return multiplier * (a.category?.name || '').localeCompare(b.category?.name || '');
      case 'amount':
        return multiplier * (a.amount - b.amount);
      default:
        return 0;
    }
  });

  const handleExport = () => {
    // TODO: Implement CSV export
    toast.success('Export feature coming soon!');
  };

  if (!currentOutlet) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <AlertCircle className="h-16 w-16 text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">No Outlet Selected</h2>
        <p className="text-gray-600 mb-4">Please select an outlet to manage expenses</p>
      </div>
    );
  }

  const totalExpenses = expenses.reduce((sum, exp) => sum + exp.amount, 0);
  const totalTax = expenses.reduce((sum, exp) => sum + exp.taxAmount, 0);

  return (
    <div className="space-y-6">
      {/* Header Section */}
      <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
          <div>
            <h1 className="text-xl font-semibold text-gray-900 flex items-center gap-3">
              <DollarSign className="h-8 w-8 text-indigo-600" />
              {showCategories ? 'Expense Categories' : 'Expense Management'}
            </h1>
            {!showCategories && (
              <p className="mt-2 text-sm text-gray-500">
                Total Expenses: {formatCurrency(totalExpenses)} (Tax: {formatCurrency(totalTax)})
              </p>
            )}
          </div>
          <div className="flex flex-wrap items-center gap-4">
            {!showCategories && (
              <SearchInput
                value={searchTerm}
                onChange={setSearchTerm}
                placeholder={showCategories ? "Search categories..." : "Search expenses..."}
                className="w-64"
              />
            )}
            <button
              onClick={() => setShowCategories(!showCategories)}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
            >
              <ListFilter className="h-5 w-5 mr-2" />
              {showCategories ? 'View Expenses' : 'Manage Categories'}
            </button>
            {!showCategories && (
              <>
                <button
                  onClick={handleExport}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
                >
                  <Download className="h-5 w-5 mr-2" />
                  Export
                </button>
              </>
            )}
            <button
              onClick={() => {
                setSelectedExpense(null);
                showCategories ? setShowCategoryModal(true) : setShowExpenseModal(true);
              }}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
            >
              <Plus className="h-5 w-5 mr-2" />
              Add {showCategories ? 'Category' : 'Expense'}
            </button>
          </div>
        </div>
      </div>

      {showCategories ? (
        <ExpenseCategoryList
          categories={categories}
          className="bg-white rounded-lg shadow-sm"
          onEdit={(category) => {
            setSelectedCategory(category);
            setShowCategoryModal(true);
          }}
        />
      ) : (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
            {/* Summary */}
            <div className="lg:col-span-1 order-2 lg:order-1">
              <ExpenseSummary expenses={expenses} categories={categories} />
            </div>

            {/* Expense List */}
            <div className="lg:col-span-3 order-1 lg:order-2">
              <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100">
                <div className="flex flex-wrap items-center gap-4 mb-6">
                  <Filter className="h-5 w-5 text-gray-400 flex-shrink-0" />
                  <div className="flex flex-wrap items-center gap-4">
                    <div className="w-40">
                      <label className="block text-sm font-medium text-gray-700">Start Date</label>
                      <input
                        type="date"
                        value={format(dateRange.start, 'yyyy-MM-dd')}
                        onChange={(e) => setDateRange(prev => ({ ...prev, start: new Date(e.target.value) }))}
                        className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="w-40">
                      <label className="block text-sm font-medium text-gray-700">End Date</label>
                      <input
                        type="date"
                        value={format(dateRange.end, 'yyyy-MM-dd')}
                        onChange={(e) => setDateRange(prev => ({ ...prev, end: new Date(e.target.value) }))}
                        className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div className="w-48">
                      <label className="block text-sm font-medium text-gray-700">Category</label>
                      <select
                        value={selectedCategoryFilter}
                        onChange={(e) => setSelectedCategoryFilter(e.target.value)}
                        className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        <option value="all">All Categories</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <ExpenseList
                  expenses={sortedAndFilteredExpenses}
                  sortConfig={sortConfig}
                  onSort={(field) => {
                    setSortConfig(prev => ({
                      field,
                      order: prev.field === field && prev.order === 'desc' ? 'asc' : 'desc'
                    }));
                  }}
                  onEdit={(expense) => {
                    setSelectedExpense(expense);
                    setShowExpenseModal(true);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {/* Modals */}
      {showExpenseModal && (
        <ExpenseModal
          isOpen={showExpenseModal}
          onClose={() => {
            setShowExpenseModal(false);
            setSelectedExpense(null);
          }}
          expense={selectedExpense}
          categories={categories}
          outletId={currentOutlet}
        />
      )}

      {showCategoryModal && (
        <ExpenseCategoryModal
          isOpen={showCategoryModal}
          onClose={() => {
            setShowCategoryModal(false);
            setSelectedCategory(null);
          }}
          category={selectedCategory}
          outletId={currentOutlet}
        />
      )}
    </div>
  );
}