import { supabase } from '../supabase-client';
import { User, LoginCredentials, RegisterData } from '../../types/auth';
import toast from 'react-hot-toast';

export async function loginUser(credentials: LoginCredentials) {
  try {
    const { data: { user: authUser }, error: authError } = 
      await supabase.auth.signInWithPassword(credentials);

    if (authError) throw authError;
    if (!authUser) throw new Error('Authentication failed');

    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('*')
      .eq('id', authUser.id)
      .single();

    if (userError) throw userError;

    return {
      id: authUser.id,
      email: userData.email,
      name: userData.name,
      role: userData.role,
      phone: userData.phone,
      address: userData.address,
      createdAt: new Date(userData.created_at),
      updatedAt: new Date(userData.updated_at)
    } as User;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
}

export async function registerUser(data: RegisterData) {
  try {
    // First create auth user
    const { data: { user: authUser }, error: authError } = 
      await supabase.auth.signUp({
        email: data.email,
        password: data.password,
      });

    if (authError) throw authError;
    if (!authUser) throw new Error('Registration failed');

    // Then create user profile
    const { error: insertError } = await supabase
      .from('users')
      .insert([{
        id: authUser.id,
        email: data.email,
        name: data.name,
        role: data.role,
        phone: data.phone,
        address: data.address,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }]);

    if (insertError) throw insertError;

    // Return user data
    return {
      id: authUser.id,
      email: data.email,
      name: data.name,
      role: data.role,
      phone: data.phone,
      address: data.address,
      createdAt: new Date(),
      updatedAt: new Date()
    } as User;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
}

export async function updateUserProfile(userId: string, updates: Partial<User>) {
  try {
    const { data, error } = await supabase
      .from('users')
      .update({
        name: updates.name,
        phone: updates.phone,
        address: updates.address,
        updated_at: new Date().toISOString()
      })
      .eq('id', userId)
      .select()
      .single();

    if (error) throw error;

    return {
      ...data,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at)
    } as User;
  } catch (error) {
    console.error('Profile update error:', error);
    throw error;
  }
}