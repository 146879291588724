import { create } from 'zustand';
import { Order } from '../types';
import { fetchOrders, createOrder, updateOrder, deleteOrder } from '../lib/api/orders';

interface OrderStore {
  activeOrders: Order[];
  isLoading: boolean;
  error: Error | null;
  fetchOrders: (outletId: string) => Promise<void>;
  addOrder: (order: Omit<Order, 'id'>) => Promise<void>;
  updateOrder: (id: string, updates: Partial<Order>) => Promise<void>;
  deleteOrder: (id: string) => Promise<void>;
}

export const useOrderStore = create<OrderStore>((set) => ({
  activeOrders: [],
  isLoading: false,
  error: null,

  fetchOrders: async (outletId: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchOrders(outletId);
      set({ activeOrders: data, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  addOrder: async (order: Omit<Order, 'id'>) => {
    try {
      set({ isLoading: true, error: null });
      const data = await createOrder(order);
      set((state) => ({
        activeOrders: [data, ...state.activeOrders],
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  updateOrder: async (id: string, updates: Partial<Order>) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateOrder(id, updates);
      set((state) => ({
        activeOrders: state.activeOrders.map((order) =>
          order.id === id ? data : order
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  deleteOrder: async (id: string) => {
    try {
      set({ isLoading: true, error: null });
      await deleteOrder(id);
      set((state) => ({
        activeOrders: state.activeOrders.filter((order) => order.id !== id),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },
}));