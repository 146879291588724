import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCartStore } from '../../store/useCartStore';
import { useAuthStore } from '../../store/useAuthStore';
import { useCustomerStore } from '../../store/useCustomerStore';
import { DeliveryDetailsForm } from '../../components/store/DeliveryDetailsForm';
import { formatCurrency } from '../../lib/utils';
import { ShoppingBag, CreditCard, Trash2 } from 'lucide-react';
import toast from 'react-hot-toast';

export function Checkout() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { items, removeItem, updateQuantity, clearCart, getTotal } = useCartStore();
  const { placeOrder } = useCustomerStore();
  const [isProcessing, setIsProcessing] = useState(false);
  const [deliveryDetails, setDeliveryDetails] = useState({
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    address: user?.address || ''
  });

  useEffect(() => {
    if (!user) {
      navigate('/login?redirect=/checkout');
      return;
    }
    
    if (items.length === 0) {
      navigate('/menu');
      return;
    }
  }, [user, items, navigate]);

  if (!user || items.length === 0) return null;

  const handlePlaceOrder = async () => {
    try {
      setIsProcessing(true);

      const orderItems = items.map(({ item, quantity }) => ({
        menuItem: {
          id: item.id,
          name: item.name,
          basePrice: item.basePrice,
          gstRate: item.gstRate,
        },
        quantity,
        baseAmount: item.basePrice * quantity,
        gstAmount: (item.basePrice * quantity * item.gstRate) / 100,
        totalAmount: (item.basePrice * quantity) * (1 + item.gstRate / 100),
      }));

      const total = getTotal();
      const subtotal = items.reduce((sum, { item, quantity }) => 
        sum + (item.basePrice * quantity), 0);
      const gstAmount = total - subtotal;

      await placeOrder({
        customerId: user.id,
        customerName: deliveryDetails.name,
        customerPhone: deliveryDetails.phone,
        customerEmail: deliveryDetails.email,
        customerAddress: deliveryDetails.address,
        outletId: items[0].item.outletId, // Assuming all items are from same outlet
        items: orderItems,
        subtotal,
        gstAmount,
        total,
        status: 'pending',
        deliveryType: 'delivery',
        deliveryStatus: 'pending',
        paymentStatus: 'pending',
      });

      clearCart();
      toast.success('Order placed successfully!');
      navigate('/my-orders');
    } catch (error) {
      console.error('Error placing order:', error);
      toast.error('Failed to place order. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Cart Items */}
          <div>
            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-xl font-semibold text-gray-900">Order Summary</h2>
                <ShoppingBag className="h-6 w-6 text-gray-400" />
              </div>

              <div className="space-y-4">
                {items.map(({ item, quantity }) => (
                  <div key={item.id} className="flex items-center justify-between">
                    <div className="flex items-center">
                      {item.image && (
                        <img
                          src={item.image}
                          alt={item.name}
                          className="h-16 w-16 rounded object-cover"
                        />
                      )}
                      <div className="ml-4">
                        <h3 className="text-sm font-medium text-gray-900">{item.name}</h3>
                        <p className="text-sm text-gray-500">
                          {formatCurrency(item.basePrice)} + {item.gstRate}% GST
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="flex items-center border rounded-md">
                        <button
                          onClick={() => updateQuantity(item.id, quantity - 1)}
                          className="px-3 py-1 text-gray-600 hover:text-gray-800"
                        >
                          -
                        </button>
                        <span className="px-3 py-1 text-gray-800">{quantity}</span>
                        <button
                          onClick={() => updateQuantity(item.id, quantity + 1)}
                          className="px-3 py-1 text-gray-600 hover:text-gray-800"
                        >
                          +
                        </button>
                      </div>
                      <button
                        onClick={() => removeItem(item.id)}
                        className="ml-4 text-red-500 hover:text-red-700"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Order Details */}
          <div>
            <div className="bg-white rounded-lg shadow p-6">
              <div className="mb-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Delivery Details</h2>
                <DeliveryDetailsForm
                  initialValues={deliveryDetails}
                  onSave={setDeliveryDetails}
                />
              </div>

              <div className="border-t pt-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Payment</h2>
                <div className="flex items-center mb-4">
                  <CreditCard className="h-5 w-5 text-gray-400" />
                  <span className="ml-2 text-sm text-gray-700">Cash on Delivery</span>
                </div>
              </div>

              <div className="border-t pt-6">
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Subtotal</span>
                    <span className="text-gray-900">{formatCurrency(items.reduce((sum, { item, quantity }) => 
                      sum + (item.basePrice * quantity), 0))}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">GST</span>
                    <span className="text-gray-900">{formatCurrency(getTotal() - items.reduce((sum, { item, quantity }) => 
                      sum + (item.basePrice * quantity), 0))}</span>
                  </div>
                  <div className="flex justify-between text-base font-medium pt-4 border-t">
                    <span className="text-gray-900">Total</span>
                    <span className="text-indigo-600">{formatCurrency(getTotal())}</span>
                  </div>
                </div>

                <button
                  onClick={handlePlaceOrder}
                  disabled={isProcessing}
                  className="mt-6 w-full flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-gray-400"
                >
                  {isProcessing ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                      Processing...
                    </>
                  ) : (
                    'Place Order'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}