import { supabase } from '../supabase-client';
import { Order } from '../../types';
import { useAuthStore } from '../../store/useAuthStore';
import toast from 'react-hot-toast';

export async function fetchOrders(outletId: string) {
  try {
    const { data, error } = await supabase
      .from('orders')
      .select('*')
      .eq('outlet_id', outletId) 
      .order('created_at', { ascending: false });

    if (error) throw error;

    return data.map(order => ({
      id: order.id,
      items: order.items,
      orderType: order.order_type || 'regular',
      description: order.description || '',
      table_number: order.table_number,
      status: order.status,
      createdAt: new Date(order.created_at),
      updatedAt: new Date(order.updated_at),
      subtotal: order.subtotal,
      gstAmount: order.gst_amount,
      total: order.total,
      packageCharge: order.package_charge,
      discount: order.discount,
      payments: order.payments,
      outletId: order.outlet_id
    })) || [];
  } catch (error) {
    console.error('Error fetching orders:', error);
    toast.error('Failed to fetch orders');
    throw error;
  }
}

export async function createOrder(order: Omit<Order, 'id'>) {
  try {
    const user = useAuthStore.getState().user;
    if (!user) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('orders')
      .insert([{
        outlet_id: order.outletId,
        order_type: order.orderType,
        description: order.description,
        table_number: order.table_number,
        status: order.status,
        items: order.items,
        subtotal: order.subtotal,
        gst_amount: order.gstAmount,
        total: order.total,
        package_charge: order.packageCharge,
        discount: order.discount,
        payments: order.payments,
        created_by: user.id,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;

    return {
      id: data.id,
      items: data.items,
      table_number: data.table_number,
      status: data.status,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at),
      subtotal: data.subtotal,
      gstAmount: data.gst_amount,
      total: data.total,
      packageCharge: data.package_charge,
      discount: data.discount,
      payments: data.payments,
      outletId: data.outlet_id
    };
  } catch (error) {
    console.error('Error creating order:', error);
    toast.error('Failed to create order');
    throw error;
  }
}

export async function updateOrder(id: string, updates: Partial<Order>) {
  try {
    const user = useAuthStore.getState().user;
    if (!user) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('orders')
      .update({
        table_number: updates.table_number,
        status: updates.status,
        items: updates.items,
        subtotal: updates.subtotal,
        gst_amount: updates.gstAmount,
        total: updates.total,
        package_charge: updates.packageCharge,
        discount: updates.discount,
        payments: updates.payments,
        updated_at: new Date().toISOString(),
        updated_by: user.id
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;

    return {
      id: data.id,
      items: data.items,
      table_number: data.table_number,
      status: data.status,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at),
      subtotal: data.subtotal,
      gstAmount: data.gst_amount,
      total: data.total,
      packageCharge: data.package_charge,
      discount: data.discount,
      payments: data.payments,
      outletId: data.outlet_id
    };
  } catch (error) {
    console.error('Error updating order:', error);
    toast.error('Failed to update order');
    throw error;
  }
}

export async function deleteOrder(id: string) {
  try {
    const { error } = await supabase
      .from('orders')
      .delete()
      .eq('id', id);

    if (error) throw error;
    toast.success('Order deleted successfully');
  } catch (error) {
    console.error('Error deleting order:', error);
    toast.error('Failed to delete order');
    throw error;
  }
}