import { supabase } from '../supabase-client';
import { Outlet } from '../../types';
import toast from 'react-hot-toast';

export async function fetchOutlets() {
  try {
    const { data, error } = await supabase
      .from('outlets')
      .select('*')
      .eq('is_active', true)
      .order('name');

    if (error) throw error;

    return data.map(outlet => ({
      id: outlet.id,
      restaurantId: outlet.restaurant_id,
      name: outlet.name,
      address: outlet.address,
      phone: outlet.phone,
      email: outlet.email,
      isActive: outlet.is_active,
      tables: outlet.tables,
      operatingHours: outlet.operating_hours,
      upiId: outlet.upi_id
    }));
  } catch (error) {
    console.error('Error fetching outlets:', error);
    toast.error('Failed to fetch outlets');
    throw error;
  }
}

export async function createOutlet(outlet: Omit<Outlet, 'id'>) {
  try {
    const { data, error } = await supabase
      .from('outlets')
      .insert([{
        restaurant_id: outlet.restaurantId,
        name: outlet.name,
        address: outlet.address,
        phone: outlet.phone,
        email: outlet.email,
        is_active: outlet.isActive,
        tables: outlet.tables,
        operating_hours: outlet.operatingHours,
        upi_id: outlet.upiId,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;
    toast.success('Outlet created successfully');
    return data;
  } catch (error) {
    console.error('Error creating outlet:', error);
    toast.error('Failed to create outlet');
    throw error;
  }
}

export async function updateOutlet(id: string, updates: Partial<Outlet>) {
  try {
    const { data, error } = await supabase
      .from('outlets')
      .update({
        name: updates.name,
        address: updates.address,
        phone: updates.phone,
        email: updates.email,
        is_active: updates.isActive,
        tables: updates.tables,
        operating_hours: updates.operatingHours,
        upi_id: updates.upiId,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    toast.success('Outlet updated successfully');
    return data;
  } catch (error) {
    console.error('Error updating outlet:', error);
    toast.error('Failed to update outlet');
    throw error;
  }
}

export async function deleteOutlet(id: string) {
  try {
    const { error } = await supabase
      .from('outlets')
      .update({ is_active: false })
      .eq('id', id);

    if (error) throw error;
    toast.success('Outlet deleted successfully');
  } catch (error) {
    console.error('Error deleting outlet:', error);
    toast.error('Failed to delete outlet');
    throw error;
  }
}