import React, { useState, useEffect } from 'react';
import { useMenuStore } from '../../store/useMenuStore';
import { useOutletStore } from '../../store/useOutletStore';
import { useAuthStore } from '../../store/useAuthStore';
import { useCartStore } from '../../store/useCartStore';
import { ShoppingBag, Search } from 'lucide-react';
import { MenuItem } from '../../types';
import { MenuSearch } from '../../components/store/MenuSearch';
import { CategoryFilter } from '../../components/store/CategoryFilter';
import { MenuItemCard } from '../../components/store/MenuItemCard';
import { CartSlideOver } from '../../components/store/CartSlideOver';
import toast from 'react-hot-toast';

export function StoreMenu() {
  const { items, categories, fetchItems, fetchCategories } = useMenuStore();
  const { currentOutlet } = useOutletStore();
  const { user } = useAuthStore();
  const { items: cartItems, addItem } = useCartStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [isCartOpen, setIsCartOpen] = useState(false);

  useEffect(() => {
    if (currentOutlet) {
      fetchItems(currentOutlet);
      fetchCategories(currentOutlet);
    }
  }, [currentOutlet, fetchItems, fetchCategories]);

  const filteredItems = items.filter(item => {
    const matchesSearch = item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         item.description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || item.category === selectedCategory;
    const isAvailableOnline = item.availability.online;
    return matchesSearch && matchesCategory && isAvailableOnline;
  });

  const handleAddToCart = async (item: MenuItem) => {
    addItem(item);
    toast.success(`Added ${item.name} to cart`);
    setIsCartOpen(true);
  };

  return (
    <div className="min-h-screen bg-[#FFF8F0]">
      {/* Search and Filters */}
      <div className="sticky top-16 bg-white/95 backdrop-blur-md border-b z-10 px-4 py-3">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col gap-3">
            <MenuSearch
              searchTerm={searchTerm}
              onSearchChange={setSearchTerm}
              className="w-full"
            />
            <CategoryFilter
              categories={categories}
              selectedCategory={selectedCategory}
              onCategoryChange={setSelectedCategory}
              className="w-full overflow-x-auto flex-nowrap hide-scrollbar"
            />
          </div>
        </div>
      </div>

      {/* Menu Grid */}
      <div className="max-w-7xl mx-auto p-4 sm:p-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {filteredItems.map((item) => (
            <MenuItemCard
              key={item.id}
              item={item}
              onAddToCart={handleAddToCart}
            />
          ))}
          {filteredItems.length === 0 && (
            <div className="col-span-full text-center py-12">
              <div className="mx-auto w-24 h-24 bg-gray-100 rounded-full flex items-center justify-center mb-4">
                <UtensilsCrossed className="w-12 h-12 text-gray-400" />
              </div>
              <h3 className="text-lg font-medium text-gray-900">No items found</h3>
              <p className="mt-2 text-gray-500">Try adjusting your search or filters</p>
            </div>
          )}
        </div>
      </div>

      {/* Cart Slide Over */}
      <CartSlideOver
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        cartItems={cartItems}
        cartTotal={useCartStore.getState().getTotal()}
        isLoggedIn={!!user}
      />
      
      {/* Cart Toggle Button */}
      {cartItems.length > 0 && (
        <button
          onClick={() => setIsCartOpen(true)}
          className="fixed bottom-6 right-6 bg-red-600 text-white p-4 rounded-full shadow-lg hover:bg-red-700 transition-colors z-40"
        >
          <div className="relative">
            <ShoppingBag className="h-6 w-6" />
            <span className="absolute -top-2 -right-2 bg-white text-red-600 text-xs font-bold w-5 h-5 flex items-center justify-center rounded-full">
              {cartItems.reduce((sum, item) => sum + item.quantity, 0)}
            </span>
          </div>
        </button>
      )}
    </div>
  );
}