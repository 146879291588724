import React from 'react';
import { UtensilsCrossed } from 'lucide-react';
import { MenuItem } from '../../types';
import { formatCurrency } from '../../lib/utils';

interface MenuItemCardProps {
  item: MenuItem;
  onAddToCart: (item: MenuItem) => void;
}

export function MenuItemCard({ item, onAddToCart }: MenuItemCardProps) {
  return (
    <div className="group bg-white rounded-2xl shadow-sm hover:shadow-lg transition-all duration-300 hover:-translate-y-1 overflow-hidden">
      <div className="relative h-48">
        {item.image ? (
          <img
            src={item.image}
            alt={item.name}
            className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-300"
          />
        ) : (
          <div className="w-full h-full bg-gray-100 flex items-center justify-center">
            <UtensilsCrossed className="h-12 w-12 text-gray-400" />
          </div>
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        {item.gstRate > 0 && (
          <span className="absolute top-2 right-2 bg-yellow-500 text-white text-xs font-bold px-2 py-1 rounded-full">
            GST {item.gstRate}%
          </span>
        )}
      </div>
      <div className="p-5">
        <h3 className="text-lg font-semibold text-gray-900 group-hover:text-red-600 transition-colors duration-300">
          {item.name}
        </h3>
        <p className="mt-2 text-sm text-gray-600 line-clamp-2">{item.description}</p>
        <div className="mt-4 flex items-end justify-between">
          <div>
            <span className="text-xl font-bold text-red-600">
              {formatCurrency(item.basePrice)}
            </span>
          </div>
          <button
            onClick={() => onAddToCart(item)}
            className="inline-flex items-center px-4 py-2 border-2 border-red-600 rounded-full text-sm font-medium text-red-600 hover:bg-red-600 hover:text-white transition-colors duration-300"
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
}