import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { MenuItem } from '../types';

interface CartItem {
  item: MenuItem;
  quantity: number;
}

interface CartStore {
  items: CartItem[];
  addItem: (item: MenuItem) => void;
  removeItem: (itemId: string) => void;
  updateQuantity: (itemId: string, quantity: number) => void;
  clearCart: () => void;
  getTotal: () => number;
}

export const useCartStore = create<CartStore>()(
  persist(
    (set, get) => ({
      items: [],
      
      addItem: (item) => {
        set((state) => {
          const existingItem = state.items.find(i => i.item.id === item.id);
          if (existingItem) {
            return {
              items: state.items.map(i =>
                i.item.id === item.id
                  ? { ...i, quantity: i.quantity + 1 }
                  : i
              ),
            };
          }
          return { items: [...state.items, { item, quantity: 1 }] };
        });
      },

      removeItem: (itemId) => {
        set((state) => ({
          items: state.items.filter(i => i.item.id !== itemId),
        }));
      },

      updateQuantity: (itemId, quantity) => {
        set((state) => ({
          items: state.items.map(i =>
            i.item.id === itemId
              ? { ...i, quantity: Math.max(0, quantity) }
              : i
          ).filter(i => i.quantity > 0),
        }));
      },

      clearCart: () => {
        set({ items: [] });
      },

      getTotal: () => {
        const items = get().items;
        return items.reduce((total, { item, quantity }) => {
          const itemTotal = item.basePrice * quantity;
          const gstAmount = (itemTotal * item.gstRate) / 100;
          return total + itemTotal + gstAmount;
        }, 0);
      },
    }),
    {
      name: 'cart-storage',
    }
  )
);