import { supabase } from '../supabase-client';
import { MenuItem } from '../../types';
import toast from 'react-hot-toast';

export async function fetchMenuItems(outletId: string) {
  try {
    let { data, error } = await supabase
      .from('menu_items')
      .select(`
        *,
        categories:category_id (
          name
        )
      `)
      .eq('outlet_id', outletId)
      .eq('is_active', true);

    if (error) {
      toast.error('Failed to fetch menu items');
      throw error;
    }

    if (!data) data = [];

    return data.map(item => ({
      id: item.id,
      name: item.name,
      description: item.description,
      basePrice: item.base_price,
      gstRate: item.gst_rate,
      categoryId: item.category_id,
      category: item.categories?.name || item.category,
      image: item.image,
      availability: item.availability,
      allergens: item.allergens || [],
      dietary: item.dietary || [],
      modifiers: item.modifiers || [],
      outletId: item.outlet_id
    }));
  } catch (error) {
    console.error('Error fetching menu items:', error);
    toast.error('Failed to fetch menu items');
    throw error;
  }
}

export async function createMenuItem(item: Omit<MenuItem, 'id'>) {
  try {
    const { data, error } = await supabase
      .from('menu_items')
      .insert([{
        outlet_id: item.outletId,
        category_id: item.categoryId,
        name: item.name,
        description: item.description,
        base_price: item.basePrice,
        gst_rate: item.gstRate,
        image: item.image,
        availability: item.availability,
        allergens: item.allergens,
        dietary: item.dietary,
        modifiers: item.modifiers,
        is_active: true,
      }])
      .select()
      .single();

    if (error) throw error;
    toast.success('Menu item created successfully');
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      basePrice: data.base_price,
      gstRate: data.gst_rate,
      categoryId: data.category_id,
      category: item.category,
      image: data.image,
      availability: data.availability,
      allergens: data.allergens,
      dietary: data.dietary,
      modifiers: data.modifiers,
      outletId: data.outlet_id
    };
  } catch (error) {
    console.error('Error creating menu item:', error);
    toast.error('Failed to create menu item');
    throw error;
  }
}

export async function updateMenuItem(id: string, updates: Partial<MenuItem>) {
  try {
    const { data, error } = await supabase
      .from('menu_items')
      .update({
        outlet_id: updates.outletId,
        name: updates.name,
        category_id: updates.categoryId,
        description: updates.description,
        base_price: updates.basePrice,
        gst_rate: updates.gstRate,
        image: updates.image,
        availability: updates.availability,
        allergens: updates.allergens,
        dietary: updates.dietary,
        modifiers: updates.modifiers,
      })
      .eq('id', id)
      .select(`
        *,
        categories:category_id (
          name
        )
      `)
      .single();

    if (error) throw error;
    toast.success('Menu item updated successfully');

    return {
      id: data.id,
      name: data.name,
      description: data.description,
      basePrice: data.base_price,
      gstRate: data.gst_rate,
      categoryId: data.category_id,
      category: updates.category,
      image: data.image,
      availability: data.availability,
      allergens: data.allergens,
      dietary: data.dietary,
      modifiers: data.modifiers,
      outletId: data.outlet_id
    };
  } catch (error) {
    console.error('Error updating menu item:', error);
    toast.error('Failed to update menu item');
    throw error;
  }
}

export async function deleteMenuItem(id: string) {
  try {
    const { error } = await supabase
      .from('menu_items')
      .update({ is_active: false })
      .eq('id', id);

    if (error) throw error;
    toast.success('Menu item deleted successfully');
  } catch (error) {
    console.error('Error deleting menu item:', error);
    toast.error('Failed to delete menu item');
    throw error;
  }
}