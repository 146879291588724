import React, { useState, useRef, useEffect } from 'react';
import { format } from 'date-fns';
import { useRestaurantStore } from '../store/useRestaurantStore';
import { useOutletStore } from '../store/useOutletStore';
import { useAuditStore } from '../store/useAuditStore';
import { Building2, MapPin, Phone, Mail, Receipt, Image, Plus, Edit2, Trash2, AlertCircle } from 'lucide-react';
import { OutletModal } from '../components/management/OutletModal';
import { AuditLogList } from '../components/settings/AuditLogList';
import toast from 'react-hot-toast';

export function Settings() {
  const { restaurant, fetchRestaurant, updateRestaurant, isLoading: restaurantLoading } = useRestaurantStore();
  const { outlets, fetchOutlets, addOutlet, updateOutlet, deleteOutlet, isLoading: outletsLoading } = useOutletStore();
  const { logs, fetchLogs, isLoading: logsLoading } = useAuditStore();
  const [showOutletModal, setShowOutletModal] = useState(false);
  const [selectedOutlet, setSelectedOutlet] = useState<any | null>(null);
  const [previewLogo, setPreviewLogo] = useState<string>(restaurant?.logo || '');
  const logoInputRef = useRef<HTMLInputElement>(null);
  const [dateRange, setDateRange] = useState({
    start: new Date(new Date().setHours(0, 0, 0, 0)), // Start of today
    end: new Date()
  });
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initializeData = async () => {
      try {
        await Promise.all([
          fetchRestaurant(),
          fetchOutlets(),
          fetchLogs(undefined, undefined, dateRange.start, dateRange.end)
        ]);
        setIsInitialized(true);
      } catch (error) {
        console.error('Error initializing data:', error);
        toast.error('Failed to load settings data');
      }
    };
    initializeData();
  }, [fetchRestaurant, fetchOutlets, fetchLogs, dateRange]);

  useEffect(() => {
    if (restaurant?.logo) {
      setPreviewLogo(restaurant.logo);
    }
  }, [restaurant]);

  if (restaurantLoading || outletsLoading || !isInitialized) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!restaurant) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <AlertCircle className="h-16 w-16 text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">Restaurant Not Found</h2>
        <p className="text-gray-600 mb-4">Please contact your administrator</p>
      </div>
    );
  }

  const handleRestaurantSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    
    try {
      await updateRestaurant({
        id: restaurant.id,
        name: formData.get('name') as string,
        address: formData.get('address') as string,
        phone: formData.get('phone') as string,
        email: formData.get('email') as string,
        gstNumber: formData.get('gstNumber') as string,
        logo: previewLogo,
        currency: 'INR',
        timeZone: 'Asia/Kolkata',
      });
      toast.success('Restaurant details updated successfully');
    } catch (error) {
      console.error('Error updating restaurant:', error);
      toast.error('Failed to update restaurant details');
    }
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 500000) {
        toast.error('Logo file size should be less than 500KB');
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewLogo(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOutletSubmit = async (data: any) => {
    try {
      if (selectedOutlet) {
        await updateOutlet(selectedOutlet.id, data);
        toast.success('Outlet updated successfully');
      } else {
        await addOutlet(data);
        toast.success('Outlet created successfully');
      }
      setShowOutletModal(false);
      setSelectedOutlet(null);
    } catch (error) {
      console.error('Error saving outlet:', error);
      toast.error('Failed to save outlet');
    }
  };

  const handleDeleteOutlet = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this outlet?')) {
      try {
        await deleteOutlet(id);
        toast.success('Outlet deleted successfully');
      } catch (error) {
        console.error('Error deleting outlet:', error);
        toast.error('Failed to delete outlet');
      }
    }
  };

  return (
    <div className="max-w-7xl mx-auto py-6 space-y-8">
      {/* Restaurant Settings */}
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h2 className="text-lg font-medium leading-6 text-gray-900 mb-4">Restaurant Details</h2>
          <form onSubmit={handleRestaurantSubmit} className="space-y-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  <Building2 className="h-5 w-5 inline-block mr-2 text-gray-400" />
                  Restaurant Name
                </label>
                <input
                  type="text"
                  name="name"
                  defaultValue={restaurant.name}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  <MapPin className="h-5 w-5 inline-block mr-2 text-gray-400" />
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  defaultValue={restaurant.address}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  <Phone className="h-5 w-5 inline-block mr-2 text-gray-400" />
                  Phone
                </label>
                <input
                  type="tel"
                  name="phone"
                  defaultValue={restaurant.phone}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  <Mail className="h-5 w-5 inline-block mr-2 text-gray-400" />
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  defaultValue={restaurant.email}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  <Receipt className="h-5 w-5 inline-block mr-2 text-gray-400" />
                  GST Number
                </label>
                <input
                  type="text"
                  name="gstNumber"
                  defaultValue={restaurant.gstNumber}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  <Image className="h-5 w-5 inline-block mr-2 text-gray-400" />
                  Logo
                </label>
                <div className="mt-1 flex items-center space-x-4">
                  {previewLogo && (
                    <img
                      src={previewLogo}
                      alt="Restaurant Logo"
                      className="h-12 w-12 rounded-full object-cover"
                    />
                  )}
                  <button
                    type="button"
                    onClick={() => logoInputRef.current?.click()}
                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Change Logo
                  </button>
                  <input
                    ref={logoInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleLogoChange}
                    className="hidden"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Outlets Management */}
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-medium leading-6 text-gray-900">Outlets</h2>
            <button
              onClick={() => {
                setSelectedOutlet(null);
                setShowOutletModal(true);
              }}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <Plus className="h-5 w-5 mr-2" />
              Add Outlet
            </button>
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {outlets.map((outlet) => (
              <div
                key={outlet.id}
                className="relative bg-white border rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">{outlet.name}</h3>
                    <p className="mt-1 text-sm text-gray-500">{outlet.address}</p>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => {
                        setSelectedOutlet(outlet);
                        setShowOutletModal(true);
                      }}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      <Edit2 className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDeleteOutlet(outlet.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
                <div className="mt-4 space-y-1 text-sm text-gray-500">
                  <p>Phone: {outlet.phone}</p>
                  <p>Email: {outlet.email}</p>
                  <p>Tables: {outlet.tables}</p>
                  <p>Hours: {outlet.operatingHours.open} - {outlet.operatingHours.close}</p>
                  <span
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      outlet.isActive
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {outlet.isActive ? 'Active' : 'Inactive'}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Audit Logs */}
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h2 className="text-lg font-medium text-gray-900">Activity Log</h2>
              <p className="mt-1 text-sm text-gray-500">
                Showing activity from {format(dateRange.start, 'PPP')} to {format(dateRange.end, 'PPP')}
              </p>
            </div>
            <div className="flex items-center space-x-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Start Date</label>
                <input
                  type="date"
                  value={format(dateRange.start, 'yyyy-MM-dd')}
                  onChange={(e) => {
                    const newStart = new Date(e.target.value);
                    newStart.setHours(0, 0, 0, 0);
                    setDateRange(prev => ({ ...prev, start: newStart }));
                    fetchLogs(undefined, undefined, newStart, dateRange.end);
                  }}
                  className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">End Date</label>
                <input
                  type="date"
                  value={format(dateRange.end, 'yyyy-MM-dd')}
                  onChange={(e) => {
                    const newEnd = new Date(e.target.value);
                    newEnd.setHours(23, 59, 59, 999);
                    setDateRange(prev => ({ ...prev, end: newEnd }));
                    fetchLogs(undefined, undefined, dateRange.start, newEnd);
                  }}
                  className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <button
                onClick={() => {
                  const today = new Date();
                  const start = new Date(today.setHours(0, 0, 0, 0));
                  const end = new Date(today.setHours(23, 59, 59, 999));
                  setDateRange({ start, end });
                  fetchLogs(undefined, undefined, start, end);
                }}
                className="mt-6 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Today
              </button>
            </div>
          </div>
          <AuditLogList logs={logs} />
        </div>
      </div>

      {showOutletModal && (
        <OutletModal
          isOpen={showOutletModal}
          onClose={() => {
            setShowOutletModal(false);
            setSelectedOutlet(null);
          }}
          onSubmit={handleOutletSubmit}
          initialData={selectedOutlet}
        />
      )}
    </div>
  );
}