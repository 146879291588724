import React, { useRef } from 'react';
import { Upload, Download } from 'lucide-react';
import { downloadCSV, parseCSV, formatInventoryForExport, generateSampleCSV } from '../../lib/utils';
import { InventoryItem } from '../../types/inventory';
import { InventoryCategory } from '../../types/inventory';
import toast from 'react-hot-toast';

interface ImportExportButtonsProps {
  items: InventoryItem[];
  categories: InventoryCategory[];
  onImport: (items: any[]) => Promise<void>;
}

export function ImportExportButtons({ items, categories, onImport }: ImportExportButtonsProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleExport = () => {
    try {
      if (!items || items.length === 0) {
        toast.error('No items to export');
        return;
      }

      const formattedData = formatInventoryForExport(items);
      downloadCSV(formattedData, `inventory-${new Date().toISOString().split('T')[0]}.csv`);
      toast.success('Inventory exported successfully');
    } catch (error) {
      console.error('Export error:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to export inventory');
    }
  };

  const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const data = await parseCSV(file);
      await onImport(data);
      toast.success('Inventory imported successfully');
      
      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Import error:', error);
      toast.error('Failed to import inventory');
    }
  };

  const downloadTemplate = () => {
    try {
      const sampleData = generateSampleCSV(categories);
      downloadCSV(sampleData, 'inventory-import-template.csv');
      toast.success('Template downloaded successfully');
    } catch (error) {
      console.error('Template download error:', error);
      toast.error('Failed to download template');
    }
  };

  return (
    <div className="flex items-center gap-2">
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv"
        onChange={handleImport}
        className="hidden"
      />
      <button
        onClick={downloadTemplate}
        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
      >
        <Download className="h-5 w-5 mr-2" />
        Download Template
      </button>
      <button
        onClick={() => fileInputRef.current?.click()}
        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
      >
        <Upload className="h-5 w-5 mr-2" />
        Import
      </button>
      <button
        onClick={handleExport}
        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
      >
        <Download className="h-5 w-5 mr-2" />
        Export
      </button>
    </div>
  );
}