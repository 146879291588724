import { create } from 'zustand';
import { Restaurant } from '../types';
import { fetchRestaurant, updateRestaurant as updateRestaurantApi } from '../lib/api/restaurant';
import toast from 'react-hot-toast';

interface RestaurantStore {
  restaurant: Restaurant | null;
  isLoading: boolean;
  error: Error | null;
  fetchRestaurant: () => Promise<void>;
  updateRestaurant: (updates: Partial<Restaurant>) => Promise<void>;
}

export const useRestaurantStore = create<RestaurantStore>((set) => ({
  restaurant: null,
  isLoading: false,
  error: null,

  fetchRestaurant: async () => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchRestaurant();
      // Add a small delay to prevent flash of loading state
      // Add a small delay to prevent flash of loading state
      await new Promise(resolve => setTimeout(resolve, 500));
      set({ restaurant: data, isLoading: false, error: null });
    } catch (error) {
      console.error('Error fetching restaurant:', error);
      set({ error: error as Error, isLoading: false, restaurant: null });
      throw error;
    }
  },

  updateRestaurant: async (updates: Partial<Restaurant>) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateRestaurantApi(updates);
      set({ restaurant: data, isLoading: false });
      toast.success('Restaurant details updated successfully');
    } catch (error) {
      console.error('Error updating restaurant:', error);
      set({ error: error as Error, isLoading: false });
      toast.error('Failed to update restaurant details');
    }
  },
}));