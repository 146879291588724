import React from 'react';
import { Order } from '../../types';
import { X, Printer } from 'lucide-react';
import { formatCurrency } from '../../lib/utils';
import { format } from 'date-fns';
import { printReceipt } from '../../lib/print';
import { useRestaurantStore } from '../../store/useRestaurantStore';
import { useOutletStore } from '../../store/useOutletStore';
import toast from 'react-hot-toast';

interface OrderDetailsModalProps {
  order: Order;
  onClose: () => void;
}

export function OrderDetailsModal({ order, onClose }: OrderDetailsModalProps) {
  const { restaurant } = useRestaurantStore();
  const { outlets } = useOutletStore();
  const outlet = outlets.find(o => o.id === order.outletId);

  const handlePrint = async () => {
    try {
      await printReceipt(order, restaurant, outlet);
      toast.success('Receipt printed successfully');
    } catch (error) {
      toast.error('Failed to print receipt');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Order Details</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-500">Order ID</p>
              <p className="font-medium">#{order.id}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Date</p>
              <p className="font-medium">
                {format(new Date(order.createdAt), 'PPp')}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Order Type</p>
              <span className={`inline-flex px-2 py-1 text-xs font-medium rounded-full ${
                order.orderType === 'party' 
                  ? 'bg-purple-100 text-purple-800'
                  : order.orderType === 'daily'
                  ? 'bg-orange-100 text-orange-800'
                  : 'bg-blue-100 text-blue-800'
              }`}>
                {order.orderType.charAt(0).toUpperCase() + order.orderType.slice(1)}
              </span>
            </div>
            <div>
              <p className="text-sm text-gray-500">Table Number</p>
              <p className="font-medium">{order.table_number}</p>
            </div>
            {order.description && (
              <div className="col-span-2">
                <p className="text-sm text-gray-500">Description</p>
                <p className="font-medium italic">{order.description}</p>
              </div>
            )}
            <div>
              <p className="text-sm text-gray-500">Status</p>
              <p
                className={`font-medium ${
                  order.status === 'completed'
                    ? 'text-green-600'
                    : 'text-yellow-600'
                }`}
              >
                {order.status}
              </p>
            </div>
          </div>

          <div>
            <h3 className="font-medium mb-2">Items</h3>
            <div className="space-y-2">
              {order.items.map((item, index) => (
                <div
                  key={index}
                  className="flex justify-between items-start p-2 bg-gray-50 rounded"
                >
                  <div>
                    <p className="font-medium">{item.menuItem.name}</p>
                    <p className="text-sm text-gray-500">
                      {formatCurrency(item.menuItem.basePrice)} x {item.quantity}
                    </p>
                  </div>
                  <p className="font-medium">{formatCurrency(item.totalAmount)}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="border-t pt-4">
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span>Subtotal</span>
                <span>{formatCurrency(order.subtotal)}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>GST</span>
                <span>{formatCurrency(order.gstAmount)}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>Package Charge</span>
                <span>{formatCurrency(order.packageCharge)}</span>
              </div>
              {order.discount && (
                <div className="flex justify-between text-sm text-red-600">
                  <span>Discount</span>
                  <span>-{formatCurrency(order.discount.amount)}</span>
                </div>
              )}
              <div className="flex justify-between font-medium text-lg pt-2 border-t">
                <span>Total</span>
                <span>{formatCurrency(order.total)}</span>
              </div>
            </div>
          </div>

          <div className="border-t pt-4">
            <h3 className="font-medium mb-2">Payments</h3>
            {order.payments.map((payment, index) => (
              <div key={index} className="flex justify-between items-center">
                <span className="capitalize">{payment.method}</span>
                <span>{formatCurrency(payment.amount)}</span>
              </div>
            ))}
          </div>

          <div className="flex justify-end">
            <button
              onClick={handlePrint}
              className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
            >
              <Printer className="h-5 w-5 mr-2" />
              Print Receipt
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}