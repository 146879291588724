import React from 'react';
import { MenuItem } from '../../types';
import { formatCurrency } from '../../lib/utils';
import { UtensilsCrossed } from 'lucide-react';

interface MenuGridProps {
  items: MenuItem[];
  onItemClick: (item: MenuItem) => void;
}

export function MenuGrid({ items, onItemClick }: MenuGridProps) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-3 md:gap-4">
      {items.map((item) => {
        const gstAmount = (item.basePrice * item.gstRate) / 100;
        const totalPrice = item.basePrice + gstAmount;

        return (
          <button
            key={item.id}
            onClick={() => onItemClick(item)}
            className="bg-white rounded-lg shadow-sm hover:shadow-md hover:border-brand-red-200 border border-transparent transition-all duration-200 overflow-hidden text-left h-full"
          >
            {item.image ? (
              <div className="relative h-24 md:h-28">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-full h-full object-cover"
                />
              </div>
            ) : (
              <div className="h-24 md:h-28 bg-gray-50 flex items-center justify-center">
                <UtensilsCrossed className="h-8 w-8 text-gray-400" />
              </div>
            )}
            <div className="p-2 md:p-3">
              <div className="min-h-[40px]">
                <h3 className="font-medium text-brand-green-500 text-xs md:text-sm line-clamp-2">{item.name}</h3>
                <p className="text-xs text-gray-500 line-clamp-1 mt-0.5">{item.description}</p>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-xs md:text-sm font-bold text-brand-red-600">
                    {formatCurrency(totalPrice)}
                  </p>
                  <p className="text-[10px] text-gray-500 leading-tight">
                    {formatCurrency(item.basePrice)} + {item.gstRate}% GST
                  </p>
                </div>
                <div className="h-6 w-6 flex items-center justify-center bg-brand-red-100 text-brand-red-600 rounded-full text-sm">
                  +
                </div>
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
}