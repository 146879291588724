import { create } from 'zustand';
import { MenuItem } from '../types';
import { fetchMenuItems, createMenuItem, updateMenuItem, deleteMenuItem } from '../lib/api/menu';
import { fetchCategories, createCategory, updateCategory, deleteCategory, Category } from '../lib/api/categories';

interface MenuStore {
  items: MenuItem[];
  categories: Category[];
  isLoading: boolean;
  error: Error | null;
  fetchItems: (outletId: string) => Promise<void>;
  fetchCategories: (outletId: string) => Promise<void>;
  addItem: (item: Omit<MenuItem, 'id'>) => Promise<void>;
  updateItem: (id: string, updates: Partial<MenuItem>) => Promise<void>;
  deleteItem: (id: string) => Promise<void>;
  addCategory: (outletId: string, name: string, description?: string) => Promise<void>;
  updateCategory: (id: string, updates: { name?: string; description?: string; isActive?: boolean }) => Promise<void>;
  deleteCategory: (id: string) => Promise<void>;
}

export const useMenuStore = create<MenuStore>((set) => ({
  items: [],
  categories: [],
  isLoading: false,
  error: null,

  fetchItems: async (outletId: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchMenuItems(outletId);
      set({ items: data, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  fetchCategories: async (outletId: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchCategories(outletId);
      set({ categories: data, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  addItem: async (item: Omit<MenuItem, 'id'>) => {
    try {
      set({ isLoading: true, error: null });
      const data = await createMenuItem(item);
      set((state) => ({
        items: [...state.items, data],
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  updateItem: async (id: string, updates: Partial<MenuItem>) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateMenuItem(id, updates);
      set((state) => ({
        items: state.items.map((item) =>
          item.id === id ? { ...item, ...data } : item
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  deleteItem: async (id: string) => {
    try {
      set({ isLoading: true, error: null });
      await deleteMenuItem(id);
      set((state) => ({
        items: state.items.filter((item) => item.id !== id),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  addCategory: async (outletId: string, name: string, description?: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await createCategory(outletId, name, description);
      set((state) => ({
        categories: [...state.categories, data],
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  updateCategory: async (id: string, updates: { name?: string; description?: string; isActive?: boolean }) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateCategory(id, updates);
      set((state) => ({
        categories: state.categories.map((cat) =>
          cat.id === id ? data : cat
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  deleteCategory: async (id: string) => {
    try {
      set({ isLoading: true, error: null });
      await deleteCategory(id);
      set((state) => ({
        categories: state.categories.filter((cat) => cat.id !== id),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },
}));