import React, { useState, useEffect } from 'react';
import { useInventoryStore } from '../../store/useInventoryStore';
import { useOutletStore } from '../../store/useOutletStore';
import { useAuthStore } from '../../store/useAuthStore';
import { TransactionEditModal } from '../../components/inventory/TransactionEditModal';
import type { InventoryTransaction } from '../../types/inventory';
import { format } from 'date-fns';
import { Filter, Download, ArrowLeft, Edit2, RotateCcw } from 'lucide-react';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../lib/utils';
import toast from 'react-hot-toast';

export function TransactionList() {
  const { transactions, fetchTransactions, revokeTransaction, updateTransaction } = useInventoryStore();
  const { currentOutlet } = useOutletStore();
  const { user } = useAuthStore();
  const [selectedTransaction, setSelectedTransaction] = useState<InventoryTransaction | null>(null);
  const [dateRange, setDateRange] = useState({
    start: new Date(new Date().setDate(1)), // First day of current month
    end: new Date(),
  });

  useEffect(() => {
    if (currentOutlet) {
      fetchTransactions(currentOutlet, undefined, dateRange.start, dateRange.end);
    }
  }, [currentOutlet, dateRange]);

  const handleExport = () => {
    toast.success('Export feature coming soon!');
  };

  const handleRevoke = async (transactionId: string) => {
    if (!user?.role || user.role !== 'admin') {
      toast.error('Only administrators can revoke transactions');
      return;
    }

    const confirmed = window.confirm(
      'Are you sure you want to revoke this transaction?\n\n' +
      'This action cannot be undone and will update stock levels accordingly.'
    );

    if (confirmed) {
      try {
        await revokeTransaction(transactionId);
        toast.success('Transaction revoked successfully');
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        } else {
          toast.error('Failed to revoke transaction');
        }
      }
    }
  };

  const handleUpdateTransaction = async (updates: Partial<InventoryTransaction>) => {
    if (!selectedTransaction) return;
    
    try {
      if (!user?.role || user.role !== 'admin') {
        throw new Error('Only administrators can edit transactions');
      }

      await updateTransaction(selectedTransaction.id, updates);
      setSelectedTransaction(null);
      toast.success('Transaction updated successfully');
    } catch (error) {
      console.error('Error updating transaction:', error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Failed to update transaction');
      }
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <Link
            to="/inventory"
            className="text-gray-600 hover:text-gray-900 flex items-center"
          >
            <ArrowLeft className="h-5 w-5 mr-1" />
            Back to Inventory
          </Link>
          <h1 className="text-2xl font-semibold text-gray-900">Inventory Transactions</h1>
        </div>
        <button
          onClick={handleExport}
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          <Download className="h-5 w-5 mr-2" />
          Export
        </button>
      </div>

      {/* Filters */}
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex items-center space-x-4">
          <Filter className="h-5 w-5 text-gray-400" />
          <div className="flex space-x-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Start Date</label>
              <input
                type="date"
                value={format(dateRange.start, 'yyyy-MM-dd')}
                onChange={(e) => setDateRange(prev => ({ ...prev, start: new Date(e.target.value) }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">End Date</label>
              <input
                type="date"
                value={format(dateRange.end, 'yyyy-MM-dd')}
                onChange={(e) => setDateRange(prev => ({ ...prev, end: new Date(e.target.value) }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Transactions List */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date & Time
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Item
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Quantity
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Unit Price
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Total
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Created By
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {transactions.map((transaction) => (
              <tr key={transaction.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {format(new Date(transaction.createdAt), 'PPp')}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {transaction.item?.name}
                  </div>
                  {transaction.notes && (
                    <div className="text-sm text-gray-500">{transaction.notes}</div>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    transaction.type === 'inward'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {transaction.type.toUpperCase()}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {transaction.quantity} {transaction.item?.unit}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {transaction.unitPrice ? formatCurrency(transaction.unitPrice) : '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {transaction.totalPrice ? formatCurrency(transaction.totalPrice) : '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {transaction.createdByName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div className="flex items-center space-x-4">
                    {transaction.type === 'inward' && (
                      <button
                        onClick={() => setSelectedTransaction(transaction)}
                        className="text-indigo-600 hover:text-indigo-900 inline-flex items-center gap-2"
                      >
                        <Edit2 className="h-4 w-4" />
                        <span>Edit</span>
                      </button>
                    )}
                    <button
                      onClick={() => handleRevoke(transaction.id)}
                      className="text-red-600 hover:text-red-900 inline-flex items-center gap-2"
                    >
                      <RotateCcw className="h-4 w-4" />
                      <span>Revoke</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {selectedTransaction && (
        <TransactionEditModal
          isOpen={!!selectedTransaction}
          onClose={() => setSelectedTransaction(null)}
          transaction={selectedTransaction}
          onSave={handleUpdateTransaction}
        />
      )}
    </div>
  );
}