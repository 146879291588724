import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  LayoutDashboard, 
  ChevronDown, 
  LogOut, 
  ClipboardList, 
  Receipt, 
  Package, 
  Settings,
  Menu as MenuIcon,
  Bell,
  DollarSign,
  UtensilsCrossed
} from 'lucide-react';
import { useOutletStore } from '../../store/useOutletStore';
import { useAuthStore } from '../../store/useAuthStore';
import { useRestaurantStore } from '../../store/useRestaurantStore';
import { cn } from '../../lib/utils';

export function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { outlets, currentOutlet, fetchOutlets, setCurrentOutlet } = useOutletStore(); 
  const { user, logout } = useAuthStore();
  const { restaurant, fetchRestaurant } = useRestaurantStore();
  const [showOutletMenu, setShowOutletMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    fetchOutlets();
    fetchRestaurant();
  }, [fetchOutlets, fetchRestaurant]);

  const isActive = (path: string) => location.pathname === path;
  const currentOutletData = outlets.find(o => o.id === currentOutlet);

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const handleOutletChange = (outletId: string) => {
    setCurrentOutlet(outletId);
    setShowOutletMenu(false);
  };

  const isAdmin = user?.role === 'admin';
  const isManager = user?.role === 'manager';
  const userInitial = user?.name?.charAt(0).toUpperCase() || '?';

  const navigationItems = [
    { path: '/dashboard', icon: LayoutDashboard, label: 'Dashboard', roles: ['admin', 'manager', 'staff'] },
    { path: '/pos', icon: ClipboardList, label: 'POS', roles: ['admin', 'manager', 'staff'] },
    { path: '/orders', icon: Receipt, label: 'Orders', roles: ['admin', 'manager', 'staff'] },
    { path: '/customer-orders', icon: Bell, label: 'Customer Orders', roles: ['admin', 'manager', 'staff'] },
    { path: '/inventory', icon: Package, label: 'Inventory', roles: ['admin', 'manager'] },
    { path: '/expenses', icon: DollarSign, label: 'Expenses', roles: ['admin', 'manager'] },
    { path: '/management', icon: MenuIcon, label: 'Menu', roles: ['admin', 'manager'] }
  ];

  const filteredNavItems = navigationItems.filter(item => 
    item.roles.includes(user?.role || '')
  );

  return (
    <header className="bg-white/95 backdrop-blur-sm border-b border-gray-200 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          {/* Logo and Outlet Selector */}
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              {restaurant?.logo ? (
                <img 
                  src={restaurant.logo} 
                  alt={restaurant.name} 
                  className="h-10 w-auto object-contain"
                />
              ) : (
                <div className="flex items-center justify-center w-10 h-10 bg-brand-red-600 rounded-full">
                  <UtensilsCrossed className="h-6 w-6 text-white transform -rotate-45" />
                </div>
              )}
            </Link>

            <div className="hidden md:ml-8 md:flex">
              <div className="relative">
                <button
                  onClick={() => setShowOutletMenu(!showOutletMenu)}
                  className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none"
                >
                  <span>{currentOutletData?.name || 'Select Outlet'}</span>
                  <ChevronDown className="ml-2 h-4 w-4" />
                </button>

                {showOutletMenu && (
                  <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                    <div className="py-1">
                      {outlets.filter(o => o.isActive).map((outlet) => (
                        <button
                          key={outlet.id}
                          onClick={() => handleOutletChange(outlet.id)}
                          className={cn(
                            'block w-full text-left px-4 py-2 text-sm',
                            currentOutlet === outlet.id
                              ? 'bg-primary-50 text-primary-700'
                              : 'text-gray-700 hover:bg-gray-50'
                          )}
                        >
                          {outlet.name}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-4">
            {filteredNavItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={cn(
                  'flex items-center px-3 py-2 text-sm font-medium rounded-md',
                  isActive(item.path)
                    ? 'text-brand-red-600 bg-brand-red-50'
                    : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
                )}
              >
                <item.icon className="h-5 w-5 mr-2" />
                {item.label}
              </Link>
            ))}
          </nav>

          {/* Mobile menu button */}
          <div className="flex items-center md:hidden">
            <button
              onClick={() => setShowMobileMenu(!showMobileMenu)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100"
            >
              <MenuIcon className="h-6 w-6" />
            </button>
          </div>

          {/* User Menu */}
          <div className="hidden md:flex items-center">
            <div className="relative">
              <button
                onClick={() => setShowUserMenu(!showUserMenu)}
                className="flex items-center space-x-3"
              >
                <div className="h-8 w-8 rounded-full bg-brand-red-600 text-white flex items-center justify-center text-sm font-medium">
                  {userInitial}
                </div>
                <div className="text-sm text-left">
                  <p className="font-medium text-gray-700">{user?.name}</p>
                  <p className="text-xs text-gray-500 capitalize">{user?.role}</p>
                </div>
              </button>

              {showUserMenu && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                  <div className="py-1">
                    {(isAdmin || isManager) && (
                      <Link
                        to="/settings"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-brand-red-50"
                      >
                        <Settings className="h-4 w-4 mr-2" />
                        Settings
                      </Link>
                    )}
                    <button
                      onClick={handleLogout}
                      className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      <LogOut className="h-4 w-4 mr-2 text-brand-red-600" />
                      Sign Out
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      {showMobileMenu && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {filteredNavItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={cn(
                  'flex items-center px-3 py-2 text-base font-medium rounded-md',
                  isActive(item.path)
                    ? 'text-brand-red-600 bg-brand-red-50'
                    : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
                )}
                onClick={() => setShowMobileMenu(false)}
              >
                <item.icon className="h-5 w-5 mr-2" />
                {item.label}
              </Link>
            ))}
            <button
              onClick={handleLogout}
              className="flex items-center w-full px-3 py-2 text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-md"
            >
              <LogOut className="h-5 w-5 mr-2" />
              Sign Out
            </button>
          </div>
        </div>
      )}
    </header>
  );
}