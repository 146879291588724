import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Bell, CheckCircle, XCircle, Clock } from 'lucide-react';
import { CustomerOrder } from '../../types/order';
import { formatCurrency, getDateRange } from '../../lib/utils';
import { supabase } from '../../lib/supabase-client';
import { DateRangeType } from '../../types';
import toast from 'react-hot-toast';

interface CustomerOrdersPanelProps {
  dateRange: DateRangeType;
  customStartDate: Date;
  customEndDate: Date;
  selectedStatus: string;
  searchTerm: string;
}

export function CustomerOrdersPanel({ 
  dateRange, 
  customStartDate, 
  customEndDate,
  selectedStatus,
  searchTerm
}: CustomerOrdersPanelProps) {
  const [orders, setOrders] = useState<CustomerOrder[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const { start, end } = getDateRange(
    dateRange,
    dateRange === 'custom' ? customStartDate : undefined,
    dateRange === 'custom' ? customEndDate : undefined
  );

  useEffect(() => {
    fetchOrders();

    const subscription = supabase
      .channel('customer_orders')
      .on(
        'postgres_changes',
        { event: 'INSERT', schema: 'public', table: 'customer_orders' },
        (payload) => {
          handleNewOrder(payload.new as CustomerOrder);
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [dateRange, customStartDate, customEndDate, selectedStatus]);

  const fetchOrders = async () => {
    try {
      let query = supabase
        .from('customer_orders')
        .select('*')
        .gte('created_at', start.toISOString())
        .lte('created_at', end.toISOString())
        .order('created_at', { ascending: false });

      if (selectedStatus !== 'all') {
        query = query.eq('status', selectedStatus);
      }
      
      let { data, error } = await query;
      
      if (error) throw error;

      // Filter by search term
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        data = data.filter(order => 
          order.customerName?.toLowerCase().includes(searchLower) ||
          order.customerPhone?.toLowerCase().includes(searchLower) ||
          order.customerEmail?.toLowerCase().includes(searchLower) ||
          order.customerAddress?.toLowerCase().includes(searchLower)
        );
      }

      setOrders(data || []);
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('Failed to fetch orders');
    }
  };

  const handleNewOrder = (order: CustomerOrder) => {
    const orderDate = new Date(order.created_at);
    if (orderDate >= start && orderDate <= end) {
      setOrders(prev => [order, ...prev]);
      setUnreadCount(prev => prev + 1);
      playNotificationSound();
      toast.success('New order received!', {
        icon: '🔔',
        duration: 5000
      });
    }
  };

  const playNotificationSound = () => {
    const audio = new Audio('/notification.mp3');
    audio.play().catch(console.error);
  };

  const updateOrderStatus = async (orderId: string, status: string) => {
    try {
      const { error } = await supabase
        .from('customer_orders')
        .update({ status })
        .eq('id', orderId);

      if (error) throw error;
      
      setOrders(prev => 
        prev.map(order => 
          order.id === orderId ? { ...order, status } : order
        )
      );
      
      toast.success(`Order status updated to ${status}`);
    } catch (error) {
      console.error('Error updating order:', error);
      toast.error('Failed to update order status');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-4 border-b flex justify-between items-center">
        <h2 className="text-lg font-semibold">
          Orders {selectedStatus !== 'all' && `(${selectedStatus})`}
        </h2>
        <div className="relative">
          <Bell className="h-6 w-6 text-gray-500" />
          {unreadCount > 0 && (
            <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
              {unreadCount}
            </span>
          )}
        </div>
      </div>

      <div className="divide-y">
        {orders.map(order => (
          <div key={order.id} className="p-4 hover:bg-gray-50">
            <div className="flex justify-between items-start mb-2">
              <div>
                <span className="font-medium">Order #{order.id}</span>
                <p className="text-sm text-gray-500">
                  {format(new Date(order.created_at), 'PPp')}
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <select
                  value={order.status}
                  onChange={(e) => updateOrderStatus(order.id, e.target.value)}
                  className="text-sm border rounded p-1"
                >
                  <option value="pending">Pending</option>
                  <option value="confirmed">Confirmed</option>
                  <option value="preparing">Preparing</option>
                  <option value="ready">Ready</option>
                  <option value="completed">Completed</option>
                  <option value="cancelled">Cancelled</option>
                </select>
                {order.status === 'completed' ? (
                  <CheckCircle className="h-5 w-5 text-green-500" />
                ) : order.status === 'cancelled' ? (
                  <XCircle className="h-5 w-5 text-red-500" />
                ) : (
                  <Clock className="h-5 w-5 text-yellow-500" />
                )}
              </div>
            </div>

            <div className="space-y-2">
              <div className="text-sm">
                <span className="font-medium">Customer: </span>
                {order.customerName || order.customerEmail} ({order.customerPhone})
              </div>
              <div className="text-sm">
                <span className="font-medium">Address: </span>
                {order.customerAddress}
              </div>
              <div className="mt-2">
                {order.items.map((item, index) => (
                  <div key={index} className="flex justify-between text-sm py-1">
                    <span>
                      {item.quantity}x {item.menuItem.name}
                    </span>
                    <span>{formatCurrency(item.totalAmount)}</span>
                  </div>
                ))}
              </div>
              <div className="border-t pt-2 mt-2">
                <div className="flex justify-between font-medium">
                  <span>Total</span>
                  <span>{formatCurrency(order.total)}</span>
                </div>
                <div className="text-sm text-gray-500">
                  Payment Status: {order.paymentStatus}
                </div>
              </div>
            </div>
          </div>
        ))}

        {orders.length === 0 && (
          <div className="p-8 text-center text-gray-500">
            No orders found for the selected criteria
          </div>
        )}
      </div>
    </div>
  );
}